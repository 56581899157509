export var model = {
    "user": {
        "email": "admin@bitnomial.com",
        "roles": [
            "Administrator",
            "BackOfficeManager"
        ],
        "interfaceConfig": {
            "showTutorial": false,
            "lastPasswordChange": "2021-02-08T19:59:52.374831Z"
        },
        "details": {
            "lastName": "User",
            "phoneNumber": "88888888888",
            "firstName": "Bitnomial",
            "approved": true,
            "photoId": "/entities/1/users/1/photo_id.jpg"
        },
        "id": 1,
        "organizationDetails": {
            "name": "Bitnomial FCM",
            "id": 1,
            "lei": "BTNLFCM",
            "approvalStatus": "Approved"
        }
    },
    "model": {
        "managedUsers": [
            {
                "email": "admin@bitnomial.com",
                "roles": [
                    "Administrator",
                    "BackOfficeManager"
                ],
                "interfaceConfig": {
                    "showTutorial": false,
                    "lastPasswordChange": "2021-02-08T19:59:52.374831Z"
                },
                "details": {
                    "lastName": "User",
                    "phoneNumber": "88888888888",
                    "firstName": "Bitnomial",
                    "approved": true,
                    "photoId": "/entities/1/users/1/photo_id.jpg"
                },
                "id": 1,
                "organizationDetails": {
                    "name": "Bitnomial FCM",
                    "id": 1,
                    "lei": "BTNLFCM",
                    "approvalStatus": "Approved"
                }
            }
        ]
    },
    "selectedTab": "settings"
};
