var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import useOutsideClick from '../../../utils/click-outside-hook';
import './profile-dropdown.scss';
var ProfileDropdown = function (_a) {
    var close = _a.close;
    var ref = useRef(null);
    useOutsideClick(ref, function () {
        close();
    });
    return _jsxs("div", __assign({ className: 'profile-dropdown', ref: ref }, { children: [_jsx("div", __assign({ className: 'btn', onClick: function () {
                    //TODO go to overview
                    close();
                    localStorage.setItem('selectedTab', 'accounts-overview');
                    location.reload();
                } }, { children: "Overview" }), void 0),
            _jsx("div", __assign({ className: 'btn', onClick: function () {
                    //TODO go to logout
                    close();
                } }, { children: "Logout" }), void 0)] }), void 0);
};
export default ProfileDropdown;
