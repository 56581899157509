var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
var Icon = function () { return (_jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("rect", { x: "3.5", y: "3.5", width: "17", height: "17", rx: "1.5", stroke: "#788795" }, void 0) }), void 0)); };
var CheckedIcon = function () { return (_jsxs("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("rect", { x: "3.5", y: "3.5", width: "17", height: "17", rx: "1.5", fill: "#689EDD", stroke: "#689EDD" }, void 0),
        _jsx("path", { d: "M8 13.1429L11 16L17 8", stroke: "white", strokeLinecap: "round", strokeLinejoin: "round" }, void 0)] }), void 0)); };
var IndeterminateIcon = function () { return (_jsxs("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("rect", { x: "3.5", y: "3.5", width: "17", height: "17", rx: "1.5", fill: "#689EDD", stroke: "#689EDD" }, void 0),
        _jsx("path", { d: "M7 12L17 12", stroke: "white", strokeLinecap: "round", strokeLinejoin: "round" }, void 0)] }), void 0)); };
var TableCheckbox = forwardRef(function (_a, ref) {
    var innerProps = __rest(_a, []);
    return (_jsx(Checkbox
    // @ts-ignore
    , __assign({ 
        // @ts-ignore
        ref: ref }, innerProps, { icon: // do not wrap into <img> for custom styles
        _jsx(Icon, {}, void 0), checkedIcon: _jsx(CheckedIcon, {}, void 0), disableRipple: true, indeterminateIcon: _jsx(IndeterminateIcon, {}, void 0) }), void 0));
});
var tablePropsForCheckbox = {
    selectableRowsComponent: TableCheckbox,
    selectableRowsComponentProps: {
        indeterminate: function (isIndeterminate) { return isIndeterminate; }
    },
};
export default tablePropsForCheckbox;
