var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import './expand-cell.scss';
export var ExpandCell = function (_a) {
    var name = _a.name, status = _a.status, onClick = _a.onClick, text = _a.text, expandedText = _a.expandedText, _b = _a.chevronSize, chevronSize = _b === void 0 ? 'small' : _b, className = _a.className;
    var _c = useState(false), isExpended = _c[0], setIsExpended = _c[1];
    useEffect(function () {
        if (typeof status === 'string') {
            setIsExpended(status === name);
        }
    }, [status]);
    var handleClick = function (e) {
        if (onClick) {
            onClick(e);
        }
        if (typeof status !== 'string') {
            setIsExpended(!isExpended);
        }
    };
    return (_jsxs("div", __assign({ onClick: handleClick, className: "flex align-center justify-center expand-cell " + (text ? 'with-text' : '') + " " + (isExpended ? 'expanded' : '') + " " + className, "data-tag": "allowRowEvents" }, { children: [_jsx("div", __assign({ className: 'flex', "data-tag": 'allowRowEvents' }, { children: _jsx("svg", __assign({ "data-tag": "allowRowEvents", width: "10", height: "5", viewBox: "0 0 10 5", fill: "none", xmlns: "http://www.w3.org/2000/svg", className: chevronSize + " " + (isExpended ? 'expanded' : '') }, { children: _jsx("path", { "data-tag": "allowRowEvents", d: "M1 0.666748L5 4.00008L9 0.666748", stroke: "#788795", strokeLinecap: "round" }, void 0) }), void 0) }), void 0),
            text && _jsx("p", __assign({ className: 'text', "data-tag": 'allowRowEvents' }, { children: isExpended ? expandedText : text }), void 0)] }), void 0));
};
