export var search = function (value, initialData, searchableColumns) {
    if (value === '') {
        return initialData;
    }
    var regex = new RegExp(value, 'gi');
    return initialData.filter(function (item, index) {
        for (var colIndex = 0; colIndex < searchableColumns.length; colIndex++) {
            var cellName = searchableColumns[colIndex];
            var cellNameParts = cellName.split('.');
            var field = item;
            for (var partIndex = 0; partIndex < cellNameParts.length; partIndex++) {
                field = field[cellNameParts[partIndex]];
            }
            field = field.toString();
            if (field && field.search(regex) !== -1) {
                return item;
            }
        }
    });
};
