var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import './search-input.scss';
export var Search = function (_a) {
    var _b = _a.initialValue, initialValue = _b === void 0 ? '' : _b, onChange = _a.onChange, _c = _a.placeholder, placeholder = _c === void 0 ? 'Search' : _c;
    var input = useRef(null);
    var _d = useState(''), value = _d[0], setValue = _d[1];
    var debounced = useDebouncedCallback(function (value) {
        updateValue(value);
    }, 300);
    var updateValue = function (value) {
        setValue(value);
        onChange(value);
        if (value === '') {
            // @ts-ignore
            input.current.value = '';
        }
    };
    if (initialValue && value === '') {
        setTimeout(function () {
            updateValue(initialValue);
            if (input.current) {
                // @ts-ignore
                input.current.value = initialValue;
            }
        }, 300);
    }
    return (_jsx("div", __assign({ className: 'search-container inline-flex align-center' }, { children: _jsxs("div", __assign({ className: 'relative' }, { children: [_jsx("span", { className: 'search-icon' }, void 0),
                _jsx("input", { ref: input, type: 'text', className: "search " + (value !== '' ? 'blue' : ''), placeholder: placeholder, onChange: function (e) { return debounced.callback(e.target.value); } }, void 0),
                value !== '' && _jsx("div", { className: 'close-icon flex align-center', onClick: updateValue.bind(null, '') }, void 0)] }), void 0) }), void 0));
};
