// TODO add correct values to keys. Should correspond values that comes from server in roles field

export const Roles = [
  {
    key: 'Administrator',
    value: 'Admin'
  },
  {
    key: 'trader',
    value: 'Trader'
  },
  {
    key: 'BackOffice',
    value: 'Back Office',
    subList: [
      {
        key: 'BackOfficeManager',
        value: 'Participant'
      },
      {
        key: 'BackOfficeBroker',
        value: 'Broker'
      }
    ]
  },
  {
    key: 'SettlementManager',
    value: 'Settlement Manager',
    subList: [
      {
        key: 'SettlementManagerParticipant',
        value: 'Participant'
      }
    ]
  }
]

export const filterRoles = [
  {
    key: 'admin',
    value: 'Admin'
  },
  {
    key: 'trader',
    value: 'Trader'
  },
  {
    key: 'BackOfficeParticipant',
    value: 'Participant'
  },
  {
    key: 'BackOfficeBroker',
    value: 'Broker'
  }
]

export const connectionsSelect = [
  {
    key: 'mixed',
    value: 'Mixed',
    disabled: true
  },
  {
    key: 'enable',
    value: 'Enable'
  },
  {
    key: 'disable',
    value: 'Disable'
  }
]