var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useField } from 'formik';
import Button, { buttonImages } from '../../../buttons/button';
import './photo-field.scss';
var uploadPhoto = function () {
    /* TODO upload photos to the server */
    return '/entities/1/users/1/myphotoid.jpg'; //the method must return the path to the file
};
var getFileFromPath = function (field) {
    var path = field.value.name || field.value;
    var parts = path.split('/');
    return parts[parts.length - 1];
};
var PhotoField = function (props) {
    var _a = useField({
        name: props.name,
        id: props.name,
        onChange: props.onChange,
    }), field = _a[0], meta = _a[1], helpers = _a[2];
    var footer;
    var inputElement;
    var selectFile = function () {
        if (inputElement) {
            inputElement.click();
        }
        else {
            helpers.setValue(uploadPhoto());
        }
    };
    if (field.value) {
        footer = (_jsxs("div", __assign({ className: 'photo-field-footer' }, { children: [_jsxs("div", __assign({ className: 'success' }, { children: [_jsx("img", { src: '/images/forms/success.svg', alt: 'success icon' }, void 0),
                        _jsx("span", { children: "Upload Successful" }, void 0)] }), void 0),
                _jsxs("div", __assign({ className: 'photo-control' }, { children: [_jsx("a", __assign({ className: 'file-name', target: '_blank', href: '#' }, { children: getFileFromPath(field) }), void 0),
                        _jsx("a", __assign({ className: 'remove', target: '_blank', href: '#', onClick: function (event) {
                                event.preventDefault();
                                helpers.setValue('');
                            } }, { children: "Remove" }), void 0)] }), void 0)] }), void 0));
    }
    else {
        footer = (_jsx("div", __assign({ className: 'photo-field-footer shift-bottom' }, { children: _jsx("span", __assign({ className: meta.error ? 'error' : '' }, { children: "No file uploaded (max 5MB)" }), void 0) }), void 0));
    }
    return (_jsxs("div", __assign({ className: 'photo-field' }, { children: [_jsxs("div", __assign({ className: "photo-field-header " + (!field.value ? 'small' : '') }, { children: [_jsxs("div", __assign({ className: 'text' }, { children: [_jsx("div", { children: props.text }, void 0),
                            _jsx("span", { children: "File type: pdf/png/jpg" }, void 0)] }), void 0),
                    _jsxs("div", __assign({ className: 'photo-field-buttons' }, { children: [props.showPdfDownload && _jsx("div", { children: _jsx(Button, __assign({ className: 'reset-focus download-pdf', type: 'button', form: 'rectangular', image: buttonImages.download, color: 'riverbed' }, { children: "Download PDF" }), void 0) }, void 0),
                            _jsx(Button, __assign({ type: 'button', form: 'rectangular', color: 'white-blue', onClick: selectFile }, { children: "UPLOAD File" }), void 0),
                            _jsx("input", { type: 'file', ref: function (input) { return inputElement = input; }, onChange: function (event) {
                                    // @ts-ignore
                                    helpers.setValue(event.currentTarget.files[0]);
                                }, id: 'file-upload', accept: 'image/png, image/jpeg, image/jpg, application/pdf' }, void 0)] }), void 0)] }), void 0),
            field.value ? _jsx("hr", {}, void 0) : _jsx("hr", { className: 'inactive' }, void 0), footer] }), void 0));
};
export default PhotoField;
