var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Field } from 'formik';
import '../form-field.scss';
var TextAreaField = function (props) {
    return _jsx(_Fragment, { children: _jsxs("div", __assign({ className: 'form-field text-area-field' }, { children: [_jsx("label", __assign({ htmlFor: props.name }, { children: props.label }), void 0),
                _jsx(Field, { className: props.error && props.touched ? 'errorField' : null, id: props.name, name: props.name, component: "textarea", placeholder: props.placeholder }, void 0),
                _jsx("div", __assign({ className: 'error' }, { children: props.error && props.touched ? props.error : null }), void 0)] }), void 0) }, void 0);
};
export default TextAreaField;
