var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import './arrow-icon.scss';
export var ArrowIcon = function () {
    return (_jsx("div", __assign({ className: 'arrow' }, { children: _jsx("svg", __assign({ viewBox: "0 0 9 5", width: "9px", height: "6px", className: "sort-icon", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M4.5 5L0.602885 0.5L8.39711 0.500001L4.5 5Z", fill: "#788795" }, void 0) }), void 0) }), void 0));
};
