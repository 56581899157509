var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import FormHeader, { Images } from '../../form-header';
import Button from '../../../buttons/button';
import { FormikProvider, useFormik } from 'formik';
import SelectField, { DefaultValue } from '../../fields/select-field';
import { ClipLoader } from 'react-spinners';
import './add-connection.scss';
var AddConnections = function (props) {
    var formik = useFormik({
        initialValues: {
            accountId: props.accountIds.length === 1 ? props.accountIds[0] : DefaultValue.value
        },
        onSubmit: function (values) {
            formik.setSubmitting(true);
            setTimeout(function () {
                formik.setSubmitting(false);
                props.refreshTable();
                setConnectionId('1'); //set
            }, 1000);
        },
    });
    var convertAccountIdsToValue = function () {
        var values = [];
        props.accountIds.forEach(function (value, index) {
            values.push({
                key: index.toString(),
                value: value.toString(),
            });
        });
        return values;
    };
    var _a = useState({
        key: props.accountIds.length === 1 ? '0' : '',
        value: props.accountIds.length === 1 ? props.accountIds[0] : '',
    }), accountId = _a[0], setAccountId = _a[1];
    var _b = useState(''), connectionId = _b[0], setConnectionId = _b[1];
    if (connectionId === '') {
        return _jsxs("div", __assign({ className: 'add-connection' }, { children: [_jsx(FormHeader, { title: 'Add Connection', withoutMarginBottom: true }, void 0),
                _jsx("p", { children: "Use the dropdown below to select the account in which you wish to make a connection. Then press \u201CCreate Connection.\u201D" }, void 0),
                _jsx(FormikProvider, __assign({ value: formik }, { children: _jsxs("form", __assign({ onSubmit: formik.handleSubmit }, { children: [_jsx(SelectField, { name: 'accountId', label: 'Account ID', values: convertAccountIdsToValue(), setValue: setAccountId, closeOnClickOutside: true, defaultValue: props.accountIds.length === 1 ?
                                    {
                                        value: props.accountIds[0],
                                        key: '0'
                                    } : DefaultValue }, void 0),
                            _jsxs("div", __assign({ className: 'flex-row flex-row-left' }, { children: [_jsx(Button, __assign({ type: 'submit', form: 'rounded', color: 'red', disabled: formik.values.accountId === DefaultValue.value }, { children: "Create Connection" }), void 0),
                                    _jsx("div", { className: 'distance-8' }, void 0),
                                    formik.isSubmitting ? _jsx(ClipLoader, { color: '#DD7168' }, void 0) : _jsx("div", {}, void 0)] }), void 0)] }), void 0) }), void 0)] }), void 0);
    }
    else {
        return _jsxs("div", __assign({ className: 'add-connection' }, { children: [_jsx(FormHeader, { image: Images.connection, title: 'Connection Created', withoutMarginBottom: true }, void 0),
                _jsx("p", { children: "A connection has been made for the account below." }, void 0),
                _jsx("table", { children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { children: "Connection ID:" }, void 0),
                                    _jsx("th", { children: connectionId }, void 0)] }, void 0),
                            _jsxs("tr", { children: [_jsx("td", { children: "Account ID:" }, void 0),
                                    _jsx("th", { children: accountId === null || accountId === void 0 ? void 0 : accountId.value }, void 0)] }, void 0)] }, void 0) }, void 0),
                _jsx(Button, __assign({ type: 'button', form: 'rounded', onClick: function () {
                        props.refreshTable();
                        setConnectionId(''); // reset and go back to first form
                        props.onClose();
                    }, color: 'red' }, { children: "Okay" }), void 0)] }), void 0);
    }
};
export default AddConnections;
