var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Form, FormikProvider, useFormik } from 'formik';
import FormHeader, { Images } from "../../form-header";
import '../../forms-style.scss';
import './password-change.scss';
import * as Yup from 'yup';
import PasswordField, { mediumRegex } from "../../fields/password-field";
import { ClipLoader } from 'react-spinners';
import Button from '../../../buttons/button';
import useFormController from '../../formController';
var PASSWORD_SAVED = 'Password Saved';
var validationSchema = Yup.object().shape({
    password: Yup.string().required('Enter password').matches(mediumRegex, 'Password is too weak'),
    confirm: Yup.string().required('Confirm password').oneOf([Yup.ref('password'), null], 'Passwords must match'),
});
var PasswordChange = function (props) {
    var formik = useFormik({
        initialValues: { password: '', confirm: '' },
        validationSchema: props.validationScheme || validationSchema,
        onSubmit: function (values) {
            formik.setSubmitting(true);
            setTimeout(function () {
                formik.setSubmitting(false);
                formController.success();
            }, 1000);
        },
    });
    var formController = useFormController({
        success: function () {
            props.changeWindow(null, PASSWORD_SAVED);
        },
    });
    var onPasswordChange = function (e) {
        formik.handleChange(e);
        if (typeof e !== 'string') {
            // @ts-ignore
            if (props.getPassword) {
                props.getPassword(e.target.value);
            }
        }
    };
    return _jsx(_Fragment, { children: _jsxs("div", __assign({ className: 'password-check adaptive-modal-window' }, { children: [props.custom && _jsx("h3", { children: "sign up" }, void 0),
                _jsx(FormHeader, { title: 'Change Password', image: Images.lock, description: 'Choose a strong password and don\u2019t reuse it for other accounts. Use at least 8 characters.' }, void 0),
                _jsx("div", __assign({ className: 'form-body' }, { children: _jsx(FormikProvider, __assign({ value: formik }, { children: _jsxs(Form, __assign({ noValidate: true, className: 'password-change-form', onSubmit: formik.handleSubmit }, { children: [_jsx(PasswordField, { name: 'password', label: 'New password', checkPasswordStrength: true, onChange: onPasswordChange }, void 0),
                                _jsx(PasswordField, { name: 'confirm', label: 'Confirm new password', onChange: formik.handleChange }, void 0),
                                _jsxs("div", __assign({ className: 'flex-row flex-row-left' }, { children: [_jsx(Button, __assign({ type: 'submit', form: 'rounded', color: 'red' }, { children: props.btnText || 'Change password' }), void 0),
                                        _jsx("div", { className: 'distance-8' }, void 0),
                                        formik.isSubmitting ? _jsx(ClipLoader, { color: '#DD7168' }, void 0) : _jsx("div", {}, void 0)] }), void 0)] }), void 0) }), void 0) }), void 0)] }), void 0) }, void 0);
};
export default PasswordChange;
