var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import ProfileDropdown from '../profile-dropdown';
import './heading.scss';
var Heading = function (props) {
    var _a = useState(false), showMenu = _a[0], setShowMenu = _a[1];
    var handleClick = function () {
        setShowMenu(!showMenu);
    };
    var switchToSignUp = function () {
        localStorage.setItem('selectedTab', 'sign-up');
        location.reload();
    };
    return (_jsxs("div", __assign({ className: "flex justify-between heading " + (props.dark ? 'dark' : '') }, { children: [_jsx("a", __assign({ href: '#' }, { children: _jsx("img", { src: '/images/logos/bitnomial_logo.svg', alt: 'bitnomial_logo' }, void 0) }), void 0),
            _jsxs("ul", __assign({ className: 'menu' }, { children: [_jsx("li", { children: _jsx("a", __assign({ href: '#' }, { children: "Products" }), void 0) }, void 0),
                    _jsx("li", { children: _jsx("a", __assign({ href: '#' }, { children: "About" }), void 0) }, void 0),
                    _jsx("li", { children: _jsx("a", __assign({ href: '#' }, { children: "Docs" }), void 0) }, void 0),
                    _jsx("li", { children: _jsx("a", __assign({ href: '#' }, { children: "Contact" }), void 0) }, void 0),
                    _jsx("li", { children: props.signUp ?
                            _jsx("a", __assign({ className: 'btn-sign', color: 'red' }, { children: "Sign In" }), void 0)
                            :
                                _jsx("a", __assign({ className: 'btn-sign', color: 'red', onClick: switchToSignUp }, { children: "Sign Up" }), void 0) }, void 0),
                    !props.signUp &&
                        _jsxs("li", __assign({ className: 'profile position-relative' }, { children: [_jsx("img", { onClick: handleClick, src: '/images/icons/profile_icon.svg', alt: 'profile' }, void 0),
                                showMenu && _jsx(ProfileDropdown, { close: function () { return setShowMenu(false); } }, void 0)] }), void 0)] }), void 0)] }), void 0));
};
export default Heading;
