var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useField } from 'formik';
import '../form-field.scss';
import './password-field.scss';
var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
export var mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
var PasswordField = function (props) {
    var _a = useState('password'), type = _a[0], setType = _a[1];
    var _b = useField({
        name: props.name,
        id: props.name,
        onChange: props.onChange,
        onFocus: props.onFocus,
    }), field = _b[0], meta = _b[1];
    var showHidePassword = function () {
        if (type === 'password') {
            setType('text');
        }
        else {
            setType('password');
        }
    };
    var passwordStrength = function () {
        var level = checkPasswordStrength();
        return _jsx("div", __assign({ className: 'password-strength' }, { children: _jsxs("span", { children: [field.value && field.value.length > 0 ? 'Password Strength: ' : '', _jsx("span", __assign({ className: level }, { children: level }), void 0)] }, void 0) }), void 0);
    };
    function checkPasswordStrength() {
        if (field.value) {
            if (strongRegex.test(field.value)) {
                return 'strong';
            }
            else if (mediumRegex.test(field.value)) {
                return 'medium';
            }
            else if (field.value.length > 0) {
                return 'weak';
            }
            else {
                return '';
            }
        }
    }
    var getPathToImg = function () {
        var result = '';
        if (type == 'password') {
            result = 'show';
        }
        else {
            result = 'hide';
        }
        return '/images/forms/password_' + result + '.svg';
    };
    var showError = function () {
        if (props.isPasswordWrong) {
            return 'Password is wrong';
        }
        else if (meta.error && meta.touched) {
            return meta.error;
        }
        else {
            return null;
        }
    };
    return _jsx(_Fragment, { children: _jsxs("div", __assign({ className: 'form-field' }, { children: [_jsx("label", __assign({ htmlFor: props.name }, { children: props.label }), void 0),
                _jsxs("div", __assign({ className: 'password-field' }, { children: [_jsx("input", __assign({ className: meta.error && meta.touched ? 'errorField' : '' }, field, { type: type, onFocus: props.onFocus, onChange: props.onChange }), void 0),
                        _jsx("img", { src: getPathToImg(), alt: 'show password', onClick: showHidePassword }, void 0)] }), void 0),
                _jsxs("div", __assign({ className: 'flex-row' }, { children: [_jsx("div", __assign({ className: 'error' }, { children: showError() }), void 0),
                        props.checkPasswordStrength ? passwordStrength() : _jsx("div", {}, void 0)] }), void 0)] }), void 0) }, void 0);
};
export default PasswordField;
