var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import SelectField from '../../forms/fields/select-field';
import { FormikProvider, useFormik } from 'formik';
import _ from 'lodash';
import './pagination.scss';
var Pagination = function (props) {
    var currentPage = props.currentPage, rowCount = props.rowCount, rowsPerPage = props.rowsPerPage, onChangePage = props.onChangePage;
    var pageAmmount = Math.ceil(rowCount / rowsPerPage);
    var pagSelectValues = [
        { key: '15', value: '15' },
        { key: '25', value: '25' },
        { key: '50', value: '50' },
        { key: '100', value: '100' },
        { key: '200', value: '200' },
    ];
    var formik = useFormik({
        initialValues: {},
        onSubmit: function () { }
    });
    var changePage = function (page) {
        if (page <= pageAmmount && page >= 1 && page !== currentPage && page) {
            onChangePage(page);
        }
    };
    var from = rowsPerPage * (currentPage - 1) + 1;
    var to = currentPage === pageAmmount ? rowCount : rowsPerPage * currentPage;
    var pagesToRender = [];
    var getPages = function () {
        var arr = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            arr[_i] = arguments[_i];
        }
        arr.filter(function (i) { return i; }).forEach(function (i) { return pagesToRender.push(_jsx("li", __assign({ onClick: function () { return changePage(typeof i === 'number' ? i : 0); }, className: currentPage === i ? 'active' : '' }, { children: i }), Date.now() - (Math.random() * 10000))); });
    };
    if (pageAmmount <= 6) {
        getPages.apply(void 0, _.fill(Array(pageAmmount), 0).map(function (i, idx) { return idx + 1; }));
    }
    else {
        if (currentPage > 1 && currentPage < pageAmmount - 3) {
            if (currentPage === 2) {
                getPages(1, 2, 3, '...', pageAmmount - 1, pageAmmount);
            }
            else {
                getPages(1, '...', currentPage, currentPage + 1, currentPage + 2, '...', pageAmmount);
            }
        }
        else if (currentPage >= pageAmmount - 3 && currentPage < pageAmmount - 1) {
            getPages(1, 2, '...', currentPage, currentPage === pageAmmount - 3 ? currentPage + 1 : 0, pageAmmount - 1, pageAmmount);
        }
        else {
            getPages(1, 2, '...', pageAmmount - 1, pageAmmount);
        }
    }
    return (_jsxs("div", __assign({ className: 'pagination flex justify-between' }, { children: [_jsxs("div", __assign({ className: 'res-per-page' }, { children: [_jsx("span", { children: "Results per page: " }, void 0),
                    _jsx(FormikProvider, __assign({ value: formik }, { children: _jsx("form", __assign({ onSubmit: formik.handleSubmit }, { children: _jsx(SelectField, { name: 'pagination', color: 'light', values: pagSelectValues, defaultValue: pagSelectValues[0], setValue: function (_a) {
                                    var value = _a.value;
                                    return props.onChangeRowsPerPage(+value);
                                }, closeOnClickOutside: true, withShadow: true }, void 0) }), void 0) }), void 0)] }), void 0),
            _jsxs("div", { children: [_jsxs("div", __assign({ className: 'pagination-count' }, { children: [_jsxs("span", { children: [from, "-", to] }, void 0),
                            _jsxs("span", { children: ["\u00A0of ", rowCount, " result", rowCount > 1 ? 's' : ''] }, void 0)] }), void 0),
                    _jsxs("div", __assign({ className: 'flex align-center' }, { children: [_jsx("div", __assign({ className: "pagination-arrow " + (currentPage === 1 ? 'disabled' : ''), onClick: function () { return changePage(currentPage - 1); } }, { children: _jsx("img", { src: '/images/icons/vector-left.png', alt: 'prev' }, void 0) }), void 0),
                            _jsx("div", __assign({ className: 'pagination-pages' }, { children: _jsx("ul", { children: pagesToRender }, void 0) }), void 0),
                            _jsx("div", __assign({ className: "pagination-arrow right " + (currentPage === pageAmmount ? 'disabled' : ''), onClick: function () { return changePage(currentPage + 1); } }, { children: _jsx("img", { src: '/images/icons/vector-left.png', alt: 'next' }, void 0) }), void 0)] }), void 0)] }, void 0)] }), void 0));
};
export default Pagination;
