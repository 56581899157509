var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import BackToLink from '../back-to-link';
var BusinessEntityHeader = function (props) {
    return _jsx(_Fragment, { children: _jsxs("div", __assign({ className: 'header-business-entity' }, { children: [_jsx(BackToLink, { href: 'settings', text: 'Settings', changeTab: props.changeTab }, void 0),
                _jsx("h1", __assign({ className: 'business-entity' }, { children: "Business Entity" }), void 0)] }), void 0) }, void 0);
};
export default BusinessEntityHeader;
