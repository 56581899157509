var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import BlockTitle from '../../../block-title';
import Tutorial from '../tutorial';
import { AppContext, TutorialContext } from '../../../../';
import { useContext, useState } from 'react';
import { Search } from '../../../forms/fields/search-input';
import DataTable from 'react-data-table-component';
import { ArrowIcon } from '../../../shared/arrow-icon/arrow-icon';
import Button from '../../../buttons/button';
import { ColHeader } from '../../../shared/col-header/col-header';
import SelectField from '../../../forms/fields/select-field';
import { FormikProvider, useFormik } from 'formik';
import ModalWindow, { ModalWindowStatus, useModalController } from '../../../forms/modal-window';
import InviteUser from '../../../forms/windows/invite-user/invite-user';
import { Roles } from '../../../../configs';
import { NoTableData } from '../../../shared/no-table-data/no-table-data';
import { SelectedCount } from '../../../shared/selected-count/selected-count';
import { search } from '../../../../utils/search';
import { emulateRequest } from '../../../../utils/helpers';
import tablePropsForCheckbox from '../../../table-checkbox';
import './managed-users.scss';
var ManagedUsers = function (props) {
    var initialData = props.managedUsers;
    var _a = useState(initialData), tableData = _a[0], setTableData = _a[1];
    var _b = useState({ key: 'admin', value: 'Admin' }), roleValue = _b[0], setRoleValue = _b[1];
    var _c = useState({ key: '', value: '' }), selectedRole = _c[0], setRole = _c[1];
    var _d = useState(false), toggledClearRows = _d[0], setToggledClearRows = _d[1];
    var _e = useState([]), selectedRows = _e[0], setSelectedRows = _e[1];
    var _f = useState(ModalWindowStatus.close), modalStatus = _f[0], setModalStatus = _f[1];
    var _g = useState(useModalController()), modalController = _g[0], setModalController = _g[1];
    var tutorialCtx = useContext(TutorialContext);
    var context = useContext(AppContext);
    var formik = useFormik({
        initialValues: {},
        onSubmit: function () { },
    });
    var searchableColumns = ['details.firstName', 'details.lastName', 'email'];
    var columns = [
        {
            name: 'User',
            sortable: true,
            selector: 'details.firstName',
            grow: 2,
            cell: function (row) { return _jsx("div", __assign({ "data-tag": "allowRowEvents", className: "w-full reset-font" }, { children: row.details.firstName + " " + row.details.lastName }), void 0); }
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: true,
            cell: function (row) { return _jsx("a", __assign({ href: "mailto:" + row.email, className: 'email' }, { children: row.email }), void 0); },
            grow: 3,
        },
        {
            name: _jsx(ColHeader, { title: "Role", tooltip: "<div>\n\t\t\t\t\t<b>Role</b>\n\t\t\t\t\t<p>The user\u2019s role within the entity. This affects the features available to them on the dashboard.</p>\n\t\t\t\t</div>" }, void 0),
            sortable: true,
            selector: 'roles[0]',
            grow: 2,
            cell: function (row) { return _jsx("div", __assign({ "data-tag": "allowRowEvents", className: "w-full reset-font" }, { children: _jsx(FormikProvider, __assign({ value: formik }, { children: _jsx("form", __assign({ onSubmit: formik.handleSubmit }, { children: _jsx(SelectField, { color: "light", name: 'accountId', values: Roles, defaultValue: roleValue, setValue: setRoleValue, closeOnClickOutside: true }, void 0) }), void 0) }), void 0) }), void 0); }
        },
        {
            name: _jsx(ColHeader, { title: "Accounts", tooltip: "<div>\n\t\t\t\t\t<b>Accounts</b>\n\t\t\t\t\t<p>Number of accounts associated with a user.</p>\n\t\t\t\t</div>" }, void 0),
            sortable: true,
            selector: 'details.firstName',
            grow: 4,
            right: true,
            // TODO replace with real data
            cell: function (row) { return _jsx("div", { children: "1" }, void 0); }
        }
    ];
    var onSearch = function (value) {
        setTableData(search(value, initialData, searchableColumns));
    };
    var onSetRole = function (value) {
        setRole(value);
        emulateRequest(context);
        // TODO multiple users role change
    };
    var onSelectRow = function (state) {
        setSelectedRows(state.selectedRows);
    };
    var inviteUser = function () {
        setModalStatus(ModalWindowStatus.open);
    };
    var handleClearRows = function () {
        setToggledClearRows(!toggledClearRows);
    };
    var SelectRoleView = function () {
        return (_jsx("div", __assign({ className: "filter" }, { children: _jsx(FormikProvider, __assign({ value: formik }, { children: _jsx("form", __assign({ onSubmit: formik.handleSubmit }, { children: _jsx(SelectField, { withShadow: true, color: "light", label: "Role", name: 'accountId', values: Roles, defaultValue: selectedRole, setValue: onSetRole, closeOnClickOutside: true }, void 0) }), void 0) }), void 0) }), void 0));
    };
    var NoDataComponent = function () { return (_jsx(NoTableData, { children: _jsxs("div", __assign({ className: "flex flex-column align-center" }, { children: [_jsx("p", __assign({ className: 'grey-text' }, { children: "Don\u2019t see anything? Try inviting users with their email." }), void 0),
                _jsx(Button, __assign({ type: "submit", color: "white", form: "rectangular", className: "no-data-btn", onClick: inviteUser }, { children: "Invite user" }), void 0)] }), void 0) }, void 0)); };
    return _jsx(_Fragment, { children: _jsxs("div", __assign({ className: 'managed-users' }, { children: [_jsx(BlockTitle, __assign({ title: 'managed users', tip: {
                        title: 'Managed Users',
                        text: 'Here you can find the users you manage and change their roles within your entity. Create a Business Entity to begin adding users.'
                    }, shift: true }, { children: tutorialCtx.step === 3 &&
                        _jsx(Tutorial, __assign({ title: 'Managed Users', increaseStep: tutorialCtx.increaseStep, decreaseStep: tutorialCtx.decreaseStep, x: tutorialCtx.inAbleToRender ?
                                tutorialCtx.getPosition('managed-users') : 0, positions: tutorialCtx.positions, step: tutorialCtx.step, left: true, rightActive: true }, { children: "Here you can find the users you manage and change their roles within your entity. Create a Business Entity to begin adding users." }), void 0) }), void 0),
                _jsxs("div", __assign({ className: 'table-section' }, { children: [_jsxs("div", __assign({ className: 'flex justify-between' }, { children: [_jsxs("div", __assign({ className: 'flex' }, { children: [_jsx(Search, { onChange: onSearch, placeholder: 'Search' }, void 0),
                                        selectedRows.length > 0 &&
                                            _jsxs("div", __assign({ className: 'flex align-center' }, { children: [_jsx(SelectRoleView, {}, void 0),
                                                    _jsx(SelectedCount, { count: selectedRows.length }, void 0)] }), void 0)] }), void 0),
                                _jsx(Button, __assign({ type: 'button', form: 'rectangular', color: 'red', onClick: inviteUser }, { children: "Invite user" }), void 0)] }), void 0),
                        _jsx("div", __assign({ className: 'table-wrap' }, { children: _jsx(DataTable, { columns: columns, data: tableData, selectableRows // add for checkbox selection
                                : true, onSelectedRowsChange: onSelectRow, selectableRowsHighlight: true, clearSelectedRows: toggledClearRows, persistTableHead: true, sortIcon: _jsx(ArrowIcon, {}, void 0), noDataComponent: _jsx(NoDataComponent, {}, void 0), defaultSortAsc: false, 
                                // @ts-ignore
                                selectableRowsComponent: tablePropsForCheckbox.selectableRowsComponent, selectableRowsComponentProps: tablePropsForCheckbox.selectableRowsComponentProps }, void 0) }), void 0)] }), void 0),
                _jsx(ModalWindow, __assign({ status: modalStatus, setStatus: function (status) { return setModalStatus(status); }, setModalController: setModalController }, { children: _jsx(InviteUser, { modalController: modalController }, void 0) }), void 0)] }), void 0) }, void 0);
};
export default ManagedUsers;
