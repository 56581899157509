var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { createContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import Header from './components/header';
import Footer from './components/footer';
import './scss/main.scss';
import './scss/index.scss';
import AccountsMain from './components/pages/accounts-main';
import { accountPage, accountsPage, connectionsPage, settingsPage } from './example-models';
import ConnectionsMain from './components/pages/connections-main';
import SettingsMain from './components/pages/settings-main';
import EntityRegistration from './components/pages/entity-registration';
import { createEntity } from './example-models/Onboarding';
import { BottomTooltip } from "./components/shared/bottom-tooltip/bottom-tooltip";
import AccountDetail from './components/pages/account-detail';
import SignUp from './components/pages/sign-up';
import { ClipLoader } from 'react-spinners';
import MobileOverview from './components/pages/mobile-overview';
import TutorialOverlay from './components/pages/settings-main/tutorial/tutorial-overlay';
export var TutorialContext = createContext('');
export var AppContext = createContext('');
var App = function (_a) {
    var props = _a.props;
    var _b = useState(0), step = _b[0], setStep = _b[1];
    var _c = useState(false), showSpinner = _c[0], setShowSpinner = _c[1];
    var _d = useState(false), inAbleToRender = _d[0], setInAbleToRender = _d[1];
    var _e = useState(false), skip = _e[0], setSkip = _e[1];
    var getPosition = function (id) {
        var _a;
        return (_a = document.getElementById(id)) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().x;
    };
    useEffect(function () {
        setInAbleToRender(true);
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
    }, []);
    var increaseStep = function () {
        if (step < 3) {
            setStep(step + 1);
        }
    };
    var decreaseStep = function () {
        if (step > 0) {
            setStep(step - 1);
        }
    };
    var skipTips = function () {
        localStorage.setItem('showTutorial', 'false');
        setSkip(true);
    };
    var positions = [375, 418, 660, 953];
    var renderMainPart = function () {
        switch (props.selectedTab) {
            case 'accounts-overview':
                return _jsx(AccountsMain, { model: props.model, user: props.user }, void 0);
            case 'connections':
                return _jsx(ConnectionsMain, { model: props.model, user: props.user, participants: accountsPage.model.participants }, void 0);
            case 'settings':
                return _jsx(SettingsMain, { model: props.model, user: props.user, participants: accountsPage.model.participants }, void 0);
            case 'entity-registration':
                return _jsx(EntityRegistration, { createEntity: createEntity }, void 0);
            case 'account-detail':
                return _jsx(AccountDetail, { model: props.model, user: props.user }, void 0);
            case 'sign-up':
                return _jsx(SignUp, {}, void 0);
        }
    };
    var switchLoader = function (value) {
        setShowSpinner(value);
    };
    var contextValue = {
        step: step, inAbleToRender: inAbleToRender, positions: positions,
        increaseStep: increaseStep, decreaseStep: decreaseStep, getPosition: getPosition,
        skip: skip, skipTips: skipTips,
    };
    var appContext = {
        switchLoader: switchLoader
    };
    var _f = useState(window.innerWidth), windowWidth = _f[0], setWindowWidth = _f[1];
    var isSignUp = props.selectedTab === 'sign-up';
    var isEntity = props.selectedTab === 'entity-registration';
    if (!isSignUp && windowWidth < 991) {
        return _jsx(_Fragment, { children: _jsxs(AppContext.Provider, __assign({ value: appContext }, { children: [_jsx(Header, { selectedTab: props.selectedTab, isMobileHeader: true }, void 0),
                    _jsx(MobileOverview, {}, void 0),
                    _jsx(Footer, {}, void 0)] }), void 0) }, void 0);
    }
    else {
        return _jsx(_Fragment, { children: _jsx(AppContext.Provider, __assign({ value: appContext }, { children: _jsxs(TutorialContext.Provider, __assign({ value: contextValue }, { children: [!isSignUp && _jsx(Header, { selectedTab: props.selectedTab, accountId: props.selectedTab == 'account-detail'
                                ? accountPage.model.account.accountId
                                : '', accountApprovalsCount: accountsPage.model.approvals ? accountsPage.model.approvals.length : 0, connectionApprovalsCount: connectionsPage.model.approvals ? connectionsPage.model.approvals.length : 0 }, void 0),
                        _jsxs("div", __assign({ className: 'body-container' }, { children: [(props.selectedTab === 'settings' && !localStorage.getItem('showTutorial'))
                                    && _jsx(TutorialOverlay, {}, void 0), renderMainPart(), (!isSignUp && !isEntity) && _jsx(BottomTooltip, {}, void 0),
                                _jsx(Footer, { signUp: isSignUp }, void 0)] }), void 0),
                        showSpinner &&
                            _jsx("div", __assign({ className: "loader flex justify-center align-center" }, { children: _jsx(ClipLoader, { color: '#DD7168' }, void 0) }), void 0)] }), void 0) }), void 0) }, void 0);
    }
};
function mockWindowState(_a) {
    var props = _a.props;
    if (!props) {
        props = {};
    }
    if (!props.selectedTab) {
        // default tab
        var savedTab = localStorage.getItem('selectedTab');
        if (savedTab) {
            props.selectedTab = savedTab;
        }
    }
    if (!props.user) {
        props.user = {};
    }
    if (!props.model) {
        switch (props.selectedTab) {
            case 'accounts-overview':
                props.model = accountsPage.model;
                props.user = accountsPage.user;
                break;
            case 'connections':
                props.model = connectionsPage.model;
                props.user = connectionsPage.user;
                break;
            case 'account-detail':
                props.model = accountPage.model;
                props.user = accountPage.user;
                break;
            case 'settings':
            default:
                props.model = settingsPage.model;
                props.user = settingsPage.user;
                break;
        }
    }
    return props;
}
ReactDOM.render(_jsx(React.StrictMode, { children: _jsx(App, { props: 
        // @ts-ignore
        mockWindowState(window) }, void 0) }, void 0), document.getElementById('root'));
