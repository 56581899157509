export var model = {
    "user": {
        "email": "admin@bitnomial.com",
        "roles": [
            "Administrator",
            "BackOfficeManager"
        ],
        "interfaceConfig": {
            "showTutorial": false,
            "lastPasswordChange": "2021-02-08T19:59:52.374831Z"
        },
        "details": {
            "lastName": "User",
            "phoneNumber": "88888888888",
            "firstName": "Bitnomial",
            "approved": true,
            "photoId": "/entities/1/users/1/photo_id.jpg"
        },
        "id": 1,
        "organizationDetails": {
            "name": "Bitnomial FCM",
            "id": 1,
            "lei": "BTNLFCM",
            "approvalStatus": "Approved"
        }
    },
    "model": {
        "accountIds": [
            1,
            47633668634,
            47633668650
        ],
        "connections": {
            "b17301a2a": {
                "organizationName": "Bitnomial Prop Trader",
                "accountIds": [
                    1
                ],
                "defaultAccountId": 1,
                "enabled": true,
                "authToken": "e23b66b334effc106a608ad811738948bc9deaf4625daa8d365f08ac2a6cdeea",
                "dropcopyAccountIds": [
                    47633668650
                ],
                "user": {
                    "email": "uat@bitnomial.com",
                    "roles": [
                        "Trader",
                        "SettlementManager"
                    ],
                    "interfaceConfig": {
                        "showTutorial": false,
                        "lastPasswordChange": "2021-02-08T19:59:52.374831Z"
                    },
                    "details": {
                        "lastName": "UAT User",
                        "phoneNumber": "88888888888",
                        "firstName": "Bitnomial",
                        "approved": true,
                        "photoId": "/entities/2/users/2/photo_id.jpg"
                    },
                    "id": 2,
                    "organizationDetails": {
                        "name": "Bitnomial Prop Trader",
                        "id": 2,
                        "lei": "BTNLT",
                        "approvalStatus": "Approved"
                    }
                },
                "id": "b17301a2a"
            },
            "b17301a1a": {
                "organizationName": "Bitnomial Prop Trader",
                "accountIds": [
                    1
                ],
                "defaultAccountId": 1,
                "enabled": true,
                "authToken": "6d0b113d03beded61429e9a27f7751ad452d75737cfc998b6117944db1b47b0d",
                "dropcopyAccountIds": [
                    47633668634
                ],
                "user": {
                    "email": "uat@bitnomial.com",
                    "roles": [
                        "Trader",
                        "SettlementManager"
                    ],
                    "interfaceConfig": {
                        "showTutorial": false,
                        "lastPasswordChange": "2021-02-08T19:59:52.374831Z"
                    },
                    "details": {
                        "lastName": "UAT User",
                        "phoneNumber": "88888888888",
                        "firstName": "Bitnomial",
                        "approved": true,
                        "photoId": "/entities/2/users/2/photo_id.jpg"
                    },
                    "id": 2,
                    "organizationDetails": {
                        "name": "Bitnomial Prop Trader",
                        "id": 2,
                        "lei": "BTNLT",
                        "approvalStatus": "Approved"
                    }
                },
                "id": "b17301a1a"
            }
        },
        "approvals": [
            {
                "connectionId": "1",
                "accountId": "abc",
                "clearingAccountId": 1
            }
        ],
        "clearingAccounts": [
            {
                "organizationName": "Bitnomial Prop Trader",
                "accountId": "BITNOMIALUATCLEAR",
                "id": 1
            }
        ]
    },
    "selectedTab": "connections"
};
