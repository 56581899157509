var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '../../buttons/button';
import './approve-cell.scss';
export var ApproveBtnGroup = function (_a) {
    var handleStatusChane = _a.handleStatusChane, _b = _a.inCell, inCell = _b === void 0 ? false : _b;
    return (_jsxs("div", __assign({ className: "btn-group " + (inCell ? 'hidden' : '') }, { children: [_jsx(Button, __assign({ type: 'button', color: 'white-blue', form: 'rectangular', onClick: handleStatusChane.bind(null, 'approve'), className: 'approve reset-focus' }, { children: "Approve" }), void 0),
            _jsx(Button, __assign({ type: 'button', color: 'white', form: 'rectangular', onClick: handleStatusChane.bind(null, 'decline'), className: 'decline reset-focus' }, { children: "Decline" }), void 0)] }), void 0));
};
export var ApproveCell = function (_a) {
    var onClick = _a.onClick, row = _a.row, onStatusChange = _a.onStatusChange, cellName = _a.cellName, currentStatus = _a.currentStatus;
    var field = cellName
        .split('.')
        .reduce(function (value, item) {
        return value[item];
    }, row);
    var handleStatusChane = function (status) {
        if (onStatusChange) {
            onStatusChange(row, status);
        }
    };
    var renderStatus = function (status) {
        // TODO change the values in cases to relevant
        switch (status) {
            case 'none':
                return (_jsx(ApproveBtnGroup, { handleStatusChane: handleStatusChane, inCell: true }, void 0));
            case 'approved':
                return (_jsx("p", __assign({ className: 'approved' }, { children: "Approved" }), void 0));
            case 'declined':
                return (_jsx("p", __assign({ className: 'declined' }, { children: "Declined" }), void 0));
            default:
                break;
        }
    };
    return (_jsxs("div", __assign({ className: 'flex justify-between align-center w-full approve-cell' }, { children: [onClick
                ? _jsx("a", __assign({ href: '#', className: 'email', onClick: onClick.bind(null, field) }, { children: field }), void 0)
                : (cellName === 'participant.name' ?
                    (_jsx("span", { children: field }, void 0)) :
                    (field)), renderStatus(currentStatus)] }), void 0));
};
