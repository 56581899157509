var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import './modal-window.scss';
export var ModalWindowStatus;
(function (ModalWindowStatus) {
    ModalWindowStatus[ModalWindowStatus["close"] = 0] = "close";
    ModalWindowStatus[ModalWindowStatus["open"] = 1] = "open";
})(ModalWindowStatus || (ModalWindowStatus = {}));
;
export var AnimationDirection;
(function (AnimationDirection) {
    AnimationDirection["top"] = "animation-direction-top";
    AnimationDirection["bottom"] = "animation-direction-bottom";
})(AnimationDirection || (AnimationDirection = {}));
;
export var ModalDialog;
(function (ModalDialog) {
    ModalDialog["small"] = "modal-dialog modal-dialog-small";
    ModalDialog["medium"] = "modal-dialog";
})(ModalDialog || (ModalDialog = {}));
;
export var useModalController = function (props) {
    var startClosing = function () {
        if (props) {
            props === null || props === void 0 ? void 0 : props.startClosing();
        }
    };
    var reset = function () {
        setShowBtnClose(true);
        setModalDialog(ModalDialog.medium);
    };
    var _a = useState(ModalDialog.medium), modalDialog = _a[0], setModalDialog = _a[1];
    var _b = useState((props && props.hideBtnClose) ? false : true), showBtnClose = _b[0], setShowBtnClose = _b[1];
    var _c = useState(), content = _c[0], setContent = _c[1];
    var result = {
        startClosing: startClosing,
        modalDialog: modalDialog,
        setModalDialog: setModalDialog,
        showBtnClose: showBtnClose,
        setShowBtnClose: setShowBtnClose,
        reset: reset,
        content: content,
        setContent: setContent,
    };
    return result;
};
var ModalWindow = function (props) {
    var modalController = useModalController({
        startClosing: function () { return setAnimation(AnimationDirection.bottom); },
        hideBtnClose: props.hideBtnClose || false
    });
    useEffect(function () {
        if (props.setModalController) {
            props.setModalController(modalController);
        }
    }, []);
    useEffect(function () { });
    var _a = useState(AnimationDirection.top), animation = _a[0], setAnimation = _a[1];
    var startClosing = function () {
        if (animation == AnimationDirection.top && props.status == ModalWindowStatus.open) {
            setAnimation(AnimationDirection.bottom);
        }
    };
    var close = function () {
        if (animation == AnimationDirection.bottom) {
            setAnimation(AnimationDirection.top);
            props.setStatus(ModalWindowStatus.close);
            modalController.reset();
            if (props.onClose) {
                props.onClose();
            }
        }
    };
    var startOutsideClosting = function () {
        startClosing();
    };
    if (props.status == ModalWindowStatus.close) {
        return null;
    }
    return _jsx("div", __assign({ className: 'modal-window', onClick: startOutsideClosting }, { children: _jsxs("div", __assign({ className: modalController.modalDialog + '  ' + animation, onAnimationEnd: close, onClick: function (e) { return e.stopPropagation(); } }, { children: [modalController.showBtnClose &&
                    _jsx("img", { className: 'close', src: '/images/icons/close.svg', alt: 'close', onClick: startClosing }, void 0),
                _jsx("div", __assign({ className: props.isNoScrollable ? 'modal-body noscrollable' : 'modal-body' }, { children: _jsx("div", __assign({ className: 'modal-content' }, { children: modalController.content ? modalController.content : props.children }), void 0) }), void 0)] }), void 0) }), void 0);
};
export default ModalWindow;
