var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import FormHeader from '../../form-header';
import TextField from '../../fields/text-field';
import Button from '../../../buttons/button';
import { ClipLoader } from 'react-spinners';
import { FormikProvider, useFormik } from 'formik';
import { ModalWindowStatus } from '../../../forms/modal-window';
import './new-delivery.scss';
var NewDelivery = function (props) {
    var _a, _b;
    var _c = useState(''), address = _c[0], setAddress = _c[1];
    var _d = useState(false), showCopiedMessage = _d[0], setShowCopiedMessage = _d[1];
    var _e = useState(props.showAreYouSure), showAYS = _e[0], setShowAYS = _e[1];
    var formik = useFormik({
        initialValues: {
            address: ''
        },
        onSubmit: function (values) {
            formik.setSubmitting(true);
            setTimeout(function () {
                formik.setSubmitting(false);
                setAddress(values.address);
            }, 1000);
        }
    });
    var copy = function (text) {
        var textArea = document.createElement("textarea");
        if (text) {
            textArea.value = text;
        }
        else {
            textArea.value = address;
        }
        textArea.style.position = "fixed";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            document.execCommand('copy');
            document.body.removeChild(textArea);
            setShowCopiedMessage(true);
            setTimeout(function () { return setShowCopiedMessage(false); }, 2000);
        }
        catch (err) {
        }
    };
    if (showAYS) {
        return _jsxs("div", __assign({ className: 'new-delivery' }, { children: [_jsx(FormHeader, { title: 'Are You Sure?', withoutMarginBottom: true }, void 0),
                _jsx("p", { children: "Are you sure you wish to generate a new delivery address? Doing so will override the addresses currently on file." }, void 0),
                _jsxs("div", __assign({ className: 'block' }, { children: [_jsx("span", { children: "Bitnomial:" }, void 0),
                        _jsx("br", {}, void 0),
                        _jsx("a", __assign({ onClick: function () { var _a; return copy((_a = props.addresses) === null || _a === void 0 ? void 0 : _a.bitnomial); } }, { children: (_a = props.addresses) === null || _a === void 0 ? void 0 : _a.bitnomial }), void 0)] }), void 0),
                _jsxs("div", __assign({ className: 'block' }, { children: [_jsx("span", { children: "Delivery:" }, void 0),
                        _jsx("br", {}, void 0),
                        _jsx("a", __assign({ onClick: function () { var _a; return copy((_a = props.addresses) === null || _a === void 0 ? void 0 : _a.delivery); } }, { children: (_b = props.addresses) === null || _b === void 0 ? void 0 : _b.delivery }), void 0)] }), void 0),
                _jsxs("div", __assign({ className: 'btn flex-row flex-row-left' }, { children: [_jsx(Button, __assign({ type: 'submit', form: 'rounded', color: 'red', onClick: function () { return setShowAYS(false); } }, { children: "New Delivery" }), void 0),
                        _jsx("div", { className: 'distance-16' }, void 0),
                        showCopiedMessage && _jsx("span", __assign({ className: 'new-delivery-copied' }, { children: "Copied to Clipboard" }), void 0)] }), void 0)] }), void 0);
    }
    else if (address.length > 0) {
        return _jsxs("div", __assign({ className: 'new-delivery' }, { children: [_jsx(FormHeader, { title: 'Delivery Permission Requested', withoutMarginBottom: true }, void 0),
                _jsx("p", { children: "Your delivery permission request has been sent to your broker for approval. Upon approval your addresses can be found in your Account Summary." }, void 0),
                _jsx(Button, __assign({ type: 'submit', form: 'rounded', color: 'red', onClick: function () {
                        props.setStatus(ModalWindowStatus.close);
                        // TODO next lines are for testing only, remove for release
                        if (address.includes('pending')) {
                            sessionStorage.setItem('sessionPermission', JSON.stringify('Pending Approval'));
                        }
                        else if (address.includes('denied')) {
                            sessionStorage.setItem('sessionPermission', JSON.stringify('Request Denied'));
                        }
                        else {
                            sessionStorage.setItem('sessionPermission', JSON.stringify('Approved'));
                        }
                    } }, { children: "Okay" }), void 0)] }), void 0);
    }
    else {
        return _jsxs("div", __assign({ className: 'new-delivery' }, { children: [_jsx(FormHeader, { title: 'Request Delivery Permission', withoutMarginBottom: true }, void 0),
                _jsx("p", { children: "Please enter the Take Address you want to use for deliveries. Then press \u201CRequest\u201D to request delivery permission from this account\u2019s broker." }, void 0),
                _jsx(FormikProvider, __assign({ value: formik }, { children: _jsxs("form", __assign({ onSubmit: formik.handleSubmit }, { children: [_jsx(TextField, { label: 'Take Address', name: 'address' }, void 0),
                            _jsxs("div", __assign({ className: 'flex-row flex-row-left' }, { children: [_jsx(Button, __assign({ type: 'submit', form: 'rounded', color: 'red', disabled: formik.values.address === '' }, { children: "Request" }), void 0),
                                    _jsx("div", { className: 'distance-8' }, void 0),
                                    formik.isSubmitting ? _jsx(ClipLoader, { color: '#DD7168' }, void 0) : _jsx("div", {}, void 0)] }), void 0)] }), void 0) }), void 0)] }), void 0);
    }
};
export default NewDelivery;
