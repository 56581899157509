var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import './mobile-overview.scss';
var MobileOverview = function () {
    return _jsx(_Fragment, { children: _jsx("div", __assign({ className: 'mobile-overview' }, { children: _jsxs("div", __assign({ className: 'container' }, { children: [_jsx("p", { children: "Our Web App is only available on Desktop. To continue please log in on another device." }, void 0),
                    _jsx("span", { children: "Have questions?" }, void 0), _jsx("br", {}, void 0),
                    _jsx("a", { children: "Contact us" }, void 0)] }), void 0) }), void 0) }, void 0);
};
export default MobileOverview;
