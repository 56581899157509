var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Form, Formik } from 'formik';
import FormHeader, { Images } from '../../form-header';
import ContainerFields from '../../container-fields';
import TextField from '../../fields/text-field';
import EmailField from '../../fields/email-field';
import PhoneField from '../../fields/phone-field';
import PhotoField from '../../fields/photo-field';
import { ClipLoader } from 'react-spinners';
import Button from '../../../buttons/button';
import { contactsValidationSchema } from '../../contacts-validation';
import './confirm-contact-info.scss';
import '../../forms-style.scss';
var ConfirmContactInfo = function (props) {
    var _a = useState(false), isSending = _a[0], setSending = _a[1];
    var isAnyErrorExist = function (errors) {
        if (errors.email ||
            errors.firstName ||
            errors.lastName ||
            errors.phoneNumber ||
            errors.photo) {
            return _jsx("span", __assign({ className: 'all-required' }, { children: "All fields required for registration" }), void 0);
        }
        else {
            return _jsx("div", {}, void 0);
        }
    };
    var send = function (values, submitFrom) {
        setSending(true);
        setTimeout(function () {
            setSending(false);
            success();
            submitFrom();
        }, 1000);
    };
    function success() {
        localStorage.setItem('selectedTab', 'entity-registration');
        location.reload();
    }
    return _jsx(_Fragment, { children: _jsxs("div", __assign({ className: 'confirm-contact-info adaptive-modal-window' }, { children: [_jsx(FormHeader, { title: 'Confirm Contact Info', image: Images.bitnomial }, void 0),
                _jsx("div", __assign({ className: 'form-body' }, { children: _jsx(Formik, __assign({ enableReinitialize: true, initialValues: props.values, validationSchema: contactsValidationSchema, onSubmit: function (values, actions) {
                            send(values, function () { return actions.setSubmitting(false); });
                        } }, { children: function (_a) {
                            var errors = _a.errors, touched = _a.touched;
                            return (_jsxs(Form, __assign({ noValidate: true }, { children: [_jsx(EmailField, { name: 'email' }, void 0),
                                    _jsx(ContainerFields, { firstField: _jsx(TextField, { name: 'firstName', label: 'First name' }, void 0), secondField: _jsx(TextField, { name: 'lastName', label: 'Last name' }, void 0) }, void 0),
                                    _jsx(PhoneField, { name: 'phoneNumber' }, void 0),
                                    _jsx(PhotoField, { name: 'photo', text: 'Photo of US or state government-issued ID' }, void 0),
                                    _jsxs("div", __assign({ className: 'flex-row' }, { children: [isAnyErrorExist(errors), _jsxs("div", __assign({ className: 'flex-row' }, { children: [isSending ? _jsx(ClipLoader, { color: '#DD7168' }, void 0) : _jsx("div", {}, void 0),
                                                    _jsx("div", { className: 'distance-16' }, void 0),
                                                    _jsx(Button, __assign({ type: 'submit', form: 'rounded', color: 'red' }, { children: "Continue" }), void 0)] }), void 0)] }), void 0)] }), void 0));
                        } }), void 0) }), void 0)] }), void 0) }, void 0);
};
export default ConfirmContactInfo;
