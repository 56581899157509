var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import './no-table-data.scss';
export var NoTableData = function (_a) {
    var text = _a.text, buttonText = _a.buttonText, buttonOnClick = _a.buttonOnClick, children = _a.children;
    return (_jsx("div", __assign({ className: 'no-table-data w-full flex justify-center' }, { children: children
            ? children
            : _jsx("div", { children: _jsx("p", { children: text }, void 0) }, void 0) }), void 0));
};
