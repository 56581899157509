var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import FormHeader, { Images } from '../../form-header';
import './join-entity.scss';
var JoinEntity = function () {
    return _jsx(_Fragment, { children: _jsx("div", __assign({ className: 'join-entity adaptive-modal-window' }, { children: _jsx(FormHeader, { title: 'Join an Entity', image: Images.bitnomial, description: 'Looking to join an entity? Contact your lorum ipsum di monti duela di sengui yen is bolor.' }, void 0) }), void 0) }, void 0);
};
export default JoinEntity;
