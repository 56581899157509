export var formatter = function (number) {
    var _a, _b, _c;
    var length = number.length;
    var MAX_LENGTH = 14;
    if (number.match(/[^\d\(\)\s-]/g)) {
        var matches_1 = number.match(/[^\d\(\)\s-]/g);
        return number
            .split('')
            .filter(function (num) { return matches_1 && !matches_1.some(function (s) { return s === num; }); }).join('');
    }
    else if (/^\(\d{4,4}/.test(number)) {
        return "(" + ((_a = number.slice(0, 6).match(/\d/g)) === null || _a === void 0 ? void 0 : _a.slice(0, -1).join('')) + ")" + number.slice(6);
    }
    else if (/.+\(/.test(number.slice(0, 2))) {
        return number.slice(1);
    }
    else if (/\d{4,4}-/.test(number.slice(6))) {
        return "" + number.slice(0, 6) + number.slice(6, 9) + number.slice(10);
    }
    else if (/\(\d{3,3}\)\d+[^\s]/.test(number)) {
        return number.slice(0, 5) + " " + number.slice(5);
    }
    else if (/\(\d{3,3}\)\d\s/.test(number)) {
        return "" + number.slice(0, 5) + number.slice(6);
    }
    if (!/\d/.test(number.slice(-1))) {
        if (/\d\)/.test(number.slice(-2)) && length < 5) {
            return "(" + ((_b = number.match(/\d/g)) === null || _b === void 0 ? void 0 : _b.join('')) + ")";
        }
        return number.slice(0, -1);
    }
    else if (length <= 5) {
        if (/^\(\d$/.test(number)) {
            return '';
        }
        if (/^\(\d+$/.test(number)) {
            return number.slice(0, -1) + ")";
        }
        return "(" + ((_c = number.match(/\d/g)) === null || _c === void 0 ? void 0 : _c.join('')) + ")";
    }
    else if (length === 6) {
        if (number.slice(-1) === ' ') {
            return number.slice(0, 5);
        }
        return number.slice(0, 5 - length) + " " + number.slice(5 - length);
    }
    else if (length <= 9 && length > 6) {
        return "" + number.slice(0, 6 - length) + number.slice(6 - length);
    }
    else if (length === 10) {
        if (number.slice(-1) === '-') {
            return number.slice(0, -1);
        }
        return number.slice(0, -1) + "-" + number.slice(-1);
    }
    else if (length > 10 && length <= 14) {
        return "" + number.slice(0, 10 - length) + number.slice(10 - length);
    }
    else if (length > MAX_LENGTH) {
        return number.slice(0, 14);
    }
    return '';
};
export var pasteFormatter = function (number) {
    var validNum = number
        .split('')
        .filter(function (num) { return parseInt(num) || parseInt(num) === 0; })
        .join('')
        .slice(-10);
    var length = validNum.length;
    if (length <= 3) {
        if (!validNum) {
            return '';
        }
        return "(" + validNum + ")";
    }
    else if (length <= 6 && length > 3) {
        return "(" + validNum.slice(0, 3) + ") " + validNum.slice(3 - length);
    }
    else if (length <= 10 && length > 6) {
        return "(" + validNum.slice(0, 3) + ") " + validNum.slice(3, 6) + "-" + validNum.slice(6 - length);
    }
    return validNum;
};
