var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import './cell.scss';
;
var Cell = function (props) {
    var value;
    var NA = 'N/A';
    if (props.link) {
        value = _jsx("a", __assign({ target: '_blank', className: 'value', href: props.link }, { children: props.value }), void 0);
    }
    else if (props.value != NA) {
        var className = 'value';
        if (props.isEmail) {
            value = _jsx("a", __assign({ target: '_blank', className: 'value text-link', href: 'mailto:' + props.value }, { children: props.value }), void 0);
        }
        else {
            if (props.value === 'Approved') {
                className = 'value green';
            }
            if (props.value === 'Pending') {
                className = 'value grey';
            }
            value = _jsx("p", __assign({ className: className }, { children: props.value }), void 0);
        }
    }
    else {
        value = _jsx("p", __assign({ className: 'value grey' }, { children: NA }), void 0);
    }
    return _jsx(_Fragment, { children: _jsxs("div", __assign({ className: 'cell' }, { children: [_jsx("p", __assign({ className: 'title' }, { children: props.title }), void 0), value] }), void 0) }, void 0);
};
export default Cell;
