var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import BlockTitle from '../../../block-title';
import Button from '../../../buttons/button';
import Column from '../../../column';
import { TutorialContext } from '../../../../';
import Tutorial from '../tutorial';
import { ConnectBtnGroup } from '../../../shared/connect-btn-group/connect-btn-group';
import './business-entity.scss';
var BusinessEntity = function (props) {
    var _a, _b, _c, _d, _e, _f, _g;
    var name;
    var lei;
    var approvalStatus;
    var values = {
        email: '',
        firstName: '',
        lastName: '',
        photo: '',
        phoneNumber: ''
    };
    try {
        name = (_a = props === null || props === void 0 ? void 0 : props.organizationDetails) === null || _a === void 0 ? void 0 : _a.name;
        lei = (_b = props === null || props === void 0 ? void 0 : props.organizationDetails) === null || _b === void 0 ? void 0 : _b.lei;
        approvalStatus = (_c = props === null || props === void 0 ? void 0 : props.organizationDetails) === null || _c === void 0 ? void 0 : _c.approvalStatus;
        values = {
            email: (props === null || props === void 0 ? void 0 : props.email) || '',
            firstName: ((_d = props === null || props === void 0 ? void 0 : props.details) === null || _d === void 0 ? void 0 : _d.firstName) || '',
            lastName: ((_e = props === null || props === void 0 ? void 0 : props.details) === null || _e === void 0 ? void 0 : _e.lastName) || '',
            photo: ((_f = props === null || props === void 0 ? void 0 : props.details) === null || _f === void 0 ? void 0 : _f.photoId) || '',
            phoneNumber: ((_g = props === null || props === void 0 ? void 0 : props.details) === null || _g === void 0 ? void 0 : _g.phoneNumber) || ''
        };
    }
    catch (error) {
        name = null;
        lei = null;
        approvalStatus = null;
    }
    var body;
    if (name || lei || approvalStatus) {
        body = _jsxs("div", { children: [_jsxs("div", __assign({ className: 'column-block' }, { children: [_jsx(Column, { upperCell: {
                                title: 'Name',
                                value: name || ''
                            } }, void 0),
                        _jsx(Column, { upperCell: {
                                title: 'LEI',
                                value: lei || ''
                            } }, void 0),
                        _jsx(Column, { upperCell: {
                                title: 'Approval Status',
                                value: approvalStatus || ''
                            } }, void 0)] }), void 0),
                _jsxs("span", __assign({ className: 'contact-us' }, { children: ["Questions? ", _jsx("a", __assign({ target: '_blank', href: '#' }, { children: "Contact Us" }), void 0)] }), void 0),
                _jsx(Button, __assign({ className: 'btn-edit-entity', onClick: function () {
                        localStorage.setItem('selectedTab', 'entity-registration');
                        location.reload();
                    }, type: 'button', form: 'rectangular', color: 'white' }, { children: "edit" }), void 0)] }, void 0);
    }
    else {
        body = _jsxs("div", __assign({ className: 'block' }, { children: [_jsx("span", { children: "Upgrade your account by joining or creating an entity" }, void 0),
                _jsx(ConnectBtnGroup, { props: props, btnForm: 'rounded' }, void 0)] }), void 0);
    }
    var tutorialCtx = useContext(TutorialContext);
    return _jsx(_Fragment, { children: _jsxs("div", __assign({ className: 'business-entity' }, { children: [_jsx(BlockTitle, __assign({ title: 'Business Entity', tip: {
                        title: 'Business Entity',
                        text: 'Here is your business entity info. Create or join one to start taking advantage of all Bitnomial has to offer, including: Accounts, Connections, and Managed User interactions.'
                    } }, { children: tutorialCtx.step === 1 &&
                        _jsx(Tutorial, __assign({ title: 'Business Entity', increaseStep: tutorialCtx.increaseStep, decreaseStep: tutorialCtx.decreaseStep, x: tutorialCtx.inAbleToRender ?
                                tutorialCtx.getPosition('business-entity') : 0, positions: tutorialCtx.positions, step: tutorialCtx.step, left: true }, { children: "Here is your business entity info. Create or join one to start taking advantage of all Bitnomial has to offer, including: Accounts, Connections, and Managed User interactions." }), void 0) }), void 0), body] }), void 0) }, void 0);
};
export default BusinessEntity;
