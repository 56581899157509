var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import Button from '../../buttons/button';
import ModalWindow, { ModalWindowStatus } from '../../forms/modal-window';
import ChangesSaved from '../../forms/windows/changes-saved';
import '../../../scss/table.scss';
import './edit-row.scss';
export var EditRow = function (_a) {
    var row = _a.row, onChange = _a.onChange;
    // Mocked data, expect to get data for this list from row
    // TODO replace with real data
    var rows = [
        {
            title: 'Bitcoin US Dollar Futures',
            shortTitle: 'BUS',
            maxPosition: 100,
            maxOrder: 100,
        },
        {
            title: 'Deci Bitcoin US Dollar Futures',
            shortTitle: 'BUI',
            maxPosition: 100,
            maxOrder: 100,
        },
        {
            title: 'Bitcoin US Dollar Options',
            shortTitle: 'BUSO',
            maxPosition: 100,
            maxOrder: 100,
        }
    ];
    var debounced = useDebouncedCallback(function (value, name) {
        onChange(row, name, value);
    }, 300);
    var _b = useState(false), edit = _b[0], setEdit = _b[1];
    var _c = useState(ModalWindowStatus.close), modal = _c[0], setModal = _c[1];
    var getContent = function (row, isBtns) {
        if (isBtns === void 0) { isBtns = false; }
        if (edit) {
            var editValue_1; // closured value
            if (isBtns) {
                return _jsxs("div", __assign({ className: 'edit-btns-box' }, { children: [_jsx(Button, __assign({ type: 'button', form: 'rectangular', color: 'space-grey', onClick: function () { return setModal(ModalWindowStatus.open); } }, { children: "save" }), void 0),
                        _jsx(Button, __assign({ type: 'button', form: 'rectangular', color: 'white', onClick: function () { return setEdit(false); } }, { children: "cancel" }), void 0)] }), void 0);
            }
            return _jsxs(_Fragment, { children: [_jsxs("p", __assign({ className: 'edit-values edit-values-input' }, { children: [_jsx("span", { children: "max position" }, void 0),
                            _jsx("input", { type: 'number', defaultValue: row.maxPosition, className: 'edit-cell-input', name: row.shortTitle, onChange: function (e) {
                                    debounced.callback(e.target.value, e.target.name);
                                }, onWheel: function () {
                                    // @ts-ignore
                                    document.activeElement.blur();
                                }, onFocus: function (e) {
                                    editValue_1 = e.target.value; // save previous value
                                    // reset value of focus
                                    e.target.value = '';
                                    e.target.parentNode.classList.add('focused');
                                }, onBlur: function (e) {
                                    if (e.target.value === '') {
                                        e.target.value = editValue_1;
                                    }
                                    e.target.parentNode.classList.remove('focused');
                                } }, void 0)] }), void 0),
                    _jsxs("p", __assign({ className: 'edit-values edit-values-input' }, { children: [_jsx("span", { children: "max order" }, void 0),
                            _jsx("input", { type: 'number', defaultValue: row.maxOrder, className: 'edit-cell-input', name: row.shortTitle, onChange: function (e) { return debounced.callback(e.target.value, e.target.name); }, onWheel: function () {
                                    // @ts-ignore
                                    document.activeElement.blur();
                                }, onFocus: function (e) {
                                    editValue_1 = e.target.value; // save previous value
                                    // reset value of focus
                                    e.target.value = '';
                                    e.target.parentNode.classList.add('focused');
                                }, onBlur: function (e) {
                                    if (e.target.value === '') {
                                        e.target.value = editValue_1;
                                    }
                                    e.target.parentNode.classList.remove('focused');
                                } }, void 0)] }), void 0)] }, void 0);
        }
        else {
            return _jsxs(_Fragment, { children: [_jsxs("p", __assign({ className: 'edit-values' }, { children: [_jsx("span", { children: "max position" }, void 0),
                            _jsx("span", { children: row.maxPosition }, void 0)] }), void 0),
                    _jsxs("p", __assign({ className: 'edit-values' }, { children: [_jsx("span", { children: "max order" }, void 0),
                            _jsx("span", { children: row.maxOrder }, void 0)] }), void 0)] }, void 0);
        }
    };
    var renderRow = function (row) {
        return (_jsxs("div", __assign({ className: 'flex single-row' }, { children: [_jsx("p", __assign({ className: 'title' }, { children: row.title }), void 0),
                _jsx("p", __assign({ className: 'short-title' }, { children: row.shortTitle }), void 0),
                _jsx("div", __assign({ className: 'w-full flex justify-end input value-box' }, { children: getContent(row) }), void 0)] }), row.shortTitle));
    };
    var closeModal = function () {
        setModal(ModalWindowStatus.close);
        setEdit(false);
    };
    return (_jsxs("div", { children: [_jsxs("div", __assign({ className: 'expanded-row-wrapper w-full' }, { children: [_jsx("div", __assign({ className: 'expanded-row edit-row flex flex-column' }, { children: _jsx("div", __assign({ className: 'expanded-row-container' }, { children: rows.map(function (row) { return (renderRow(row)); }) }), void 0) }), void 0),
                    edit ? getContent(rows[0], true) :
                        _jsxs(Button, __assign({ type: 'button', form: 'rectangular', color: 'riverbed', className: 'edit-amount-btn', onClick: function () { return setEdit(true); } }, { children: [_jsx("img", { src: "/images/icons/edit-icon.svg", alt: "edit" }, void 0), "edit amounts"] }), void 0)] }), void 0),
            Boolean(modal) &&
                _jsx(ModalWindow, __assign({ status: modal, setStatus: function (status) { return setModal(status); }, hideBtnClose: true }, { children: _jsx(ChangesSaved, { closeWindow: closeModal }, void 0) }), void 0),
            _jsx("div", __assign({ className: 'expanded-row-bottom-shadow' }, { children: _jsx("div", {}, void 0) }), void 0)] }, void 0));
};
