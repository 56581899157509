var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Search } from '../../forms/fields/search-input';
import { ArrowIcon } from '../../shared/arrow-icon/arrow-icon';
import { ExpandCell } from '../../shared/expand-cell/expand-cell';
import { EditRow } from '../edit-row/edit-row';
import { InfoRow } from '../info-row/info-row';
import { Switch } from '../../shared/switch/switch';
import { ColHeader } from '../../shared/col-header/col-header';
import ModalWindow, { ModalDialog, ModalWindowStatus, useModalController } from '../../forms/modal-window';
import AddConnections from '../../forms/windows/add-connection';
import ReactTooltip from 'react-tooltip';
import { NoTableData } from '../../shared/no-table-data/no-table-data';
import { ConnectBtnGroup } from '../../shared/connect-btn-group/connect-btn-group';
import Button from '../../buttons/button';
import MissingAccountDialog from '../../forms/windows/missing-account-dialog';
import AreYouSureDialog from '../../forms/windows/are-you-sure-dialog';
import AddAccount from '../../forms/windows/add-account';
import { CancelBtn } from '../../shared/cancel-button/cancel-button';
import { SelectedCount } from '../../shared/selected-count/selected-count';
import { search } from '../../../utils/search';
import { emulateRequest } from '../../../utils/helpers';
import { AppContext } from '../../../index';
import { OrderCell } from '../../shared/order-cell/order-cell';
import { FormikProvider, useFormik } from 'formik';
import SelectField from '../../forms/fields/select-field';
import { connectionsSelect } from '../../../configs';
import tablePropsForCheckbox from '../../table-checkbox';
import { conditionalRowStyles } from '../../account/accounts-tab';
import Pagination from '../../account/pagination';
import '../../../scss/table.scss';
export var DerivativeTab = function (_a) {
    var data = _a.data, user = _a.user, accountIds = _a.accountIds, participants = _a.participants;
    var initialData = Object.values(data).map(function (item, index) {
        return __assign(__assign({}, item), { isExpand: false, status: '', index: index.toString() });
    });
    var _b = useState(initialData), tableData = _b[0], setTableData = _b[1];
    var _c = useState(false), toggledClearRows = _c[0], setToggledClearRows = _c[1];
    var _d = useState([]), selectedRows = _d[0], setSelectedRows = _d[1];
    // add account modal
    var _e = useState(ModalWindowStatus.close), modalStatusAC = _e[0], setModalStatusAC = _e[1];
    var _f = useState(useModalController()), modalControllerAC = _f[0], setModalControllerAC = _f[1];
    // are you sure modal Enabled status
    var _g = useState(ModalWindowStatus.close), modalStatusAUS = _g[0], setModalStatusAUS = _g[1];
    var _h = useState(useModalController()), modalControllerAUS = _h[0], setModalControllerAUS = _h[1];
    var _j = useState({ id: null }), switchChangeRow = _j[0], setSwitchChangeRow = _j[1];
    // are you sure cancel modal
    var _k = useState(ModalWindowStatus.close), modalCancel = _k[0], setModalCancel = _k[1];
    var _l = useState(useModalController()), modalCancelController = _l[0], setModalCancelController = _l[1];
    var _m = useState(), cancelRow = _m[0], setCancelRow = _m[1];
    var _o = useState(false), multiDisabling = _o[0], setMultiDisabling = _o[1];
    var _p = useState(connectionsSelect[0]), selectedConnectionState = _p[0], setConnectionState = _p[1];
    var formik = useFormik({
        initialValues: {},
        onSubmit: function () { }
    });
    var context = useContext(AppContext);
    // TODO need to be changed to determine if user created account
    var hasAccountAccess = localStorage.getItem('hasAccountAccess') === 'ture';
    useEffect(function () {
        ReactTooltip.rebuild();
    }, []);
    var searchableColumns = ['id', 'defaultAccountId'];
    var columns = [
        {
            name: _jsx(ColHeader, { title: 'Connection ID', tooltip: '<div>\n\t\t\t\t\t<b>Connection ID</b>\n\t\t\t\t\t<p>Identifier with accompanying authorization token used to trade on the exchange.</p>\n\t\t\t\t</div>' }, void 0),
            selector: 'id',
            sortable: true,
            width: '20%',
            cell: function (row) {
                return (_jsx("div", __assign({ className: 'flex align-center h-full w-full' }, { children: _jsx("p", __assign({ onClick: handleRowClick.bind(null, 'info', row.id) }, { children: row.id }), void 0) }), void 0));
            }
        },
        {
            name: _jsx(ColHeader, { title: 'Account ID', tooltip: '<div>\n\t\t\t\t\t<b>Account ID</b>\n\t\t\t\t\t<p>Identifier of brokered Account.</p>\n\t\t\t\t</div>' }, void 0),
            selector: 'defaultAccountId',
            sortable: true,
            grow: 3,
            cell: function (row) { return _jsx("div", __assign({ className: 'flex align-center h-full w-full' }, { children: _jsx("a", __assign({ href: '#', className: 'email', onClick: goToAccount.bind(null, row.defaultAccountId) }, { children: row.defaultAccountId }), void 0) }), void 0); },
        },
        {
            name: _jsx(ColHeader, { title: 'Risk Limits', tooltip: '<div>\n\t\t\t\t\t<b>Risk Limits</b>\n\t\t\t\t\t<p>The maximum quantity that can be requested on an open order.</p>\n\t\t\t\t</div>' }, void 0),
            grow: 1,
            right: true,
            cell: function (row) {
                return (_jsx("div", __assign({ className: 'flex align-center h-full w-full justify-end' }, { children: _jsx(ExpandCell, { name: 'edit', onClick: handleRowClick.bind(null, 'edit', row.id), status: row.status ? row.status : '', text: 'Edit', expandedText: 'Close' }, void 0) }), void 0));
            }
        },
        {
            name: _jsx(ColHeader, { title: 'Orders', tooltip: '<div>\n\t\t\t\t\t<b>Orders</b>\n\t\t\t\t\t<p>Number of active open orders on the exchange.</p>\n\t\t\t\t</div>' }, void 0),
            sortable: true,
            grow: 1,
            // TODO change selector and cellName to corresponding value
            selector: 'orderCount',
            right: true,
            cell: function (row) {
                row.orderCount = row.orderCount || 0; // TODO mocked data
                return _jsx("div", __assign({ className: 'flex align-center h-full w-full justify-end order-cell padding-right-20' }, { children: _jsx(OrderCell, { row: row, handleOrderCancel: handleOrderCancel.bind(null, row), cellName: 'orderCount', className: 'margin-right-15' }, void 0) }), void 0);
            }
        },
        {
            name: _jsx(ColHeader, { title: 'Enabled', tooltip: "<div>\n\t\t\t\t\t<b>Enabled (Connection)</b>\n\t\t\t\t\t<p>Participant's ability to use a connection for order entry.</p>\n\t\t\t\t</div>" }, void 0),
            selector: 'user.details.approved',
            sortable: true,
            grow: 1,
            right: true,
            cell: function (row) {
                return (_jsx("div", __assign({ className: 'flex align-center h-full w-full justify-end padding-right-20' }, { children: _jsx(Switch, { value: row.user.details.approved, onChange: handleSwitchChange, row: row }, void 0) }), void 0));
            }
        },
        {
            name: _jsx("div", __assign({ className: 'flex align-center' }, { children: _jsx("img", { src: '/images/icons/Info.svg', alt: 'info' }, void 0) }), void 0),
            center: true,
            width: '5%',
            cell: function (row) {
                return (_jsx("div", __assign({ className: 'flex align-center h-full w-full' }, { children: _jsx(ExpandCell, { name: 'info', onClick: handleRowClick.bind(null, 'info', row.id), status: row.status ? row.status : '', chevronSize: 'big' }, void 0) }), void 0));
            }
        }
    ];
    var expandedState = tableData.map(function (item) {
        return {
            id: item.id,
            status: '',
            isExpand: false,
        };
    });
    useEffect(function () {
        ReactTooltip.rebuild();
    }, []);
    var updateConnectionsSelect = function () {
        var enabledCount = 0;
        var disableCount = 0;
        selectedRows.forEach(function (row) {
            if (row['enabled']) {
                enabledCount++;
            }
            else {
                disableCount++;
            }
        });
        if (enabledCount > 0 && disableCount > 0) {
            // mixed
            setConnectionState(connectionsSelect[0]);
        }
        else if (enabledCount > 0) {
            // enabled
            setConnectionState(connectionsSelect[1]);
        }
        else {
            // disabled
            setConnectionState(connectionsSelect[1]);
        }
    };
    var handleSwitchChange = function (row, value) {
        setTableData(tableData.map(function (item) {
            if (item.id === row.id) {
                if (item.user.details) {
                    item.user = __assign(__assign({}, item.user), { details: __assign(__assign({}, item.user.details), { approved: value }) });
                }
            }
            return item;
        }));
        if (!value) {
            setMultiDisabling(false);
            setSwitchChangeRow(row); // note: this is async action
            modalControllerAUS.setModalDialog(ModalDialog.small);
            modalControllerAUS.setShowBtnClose(false);
            setModalStatusAUS(ModalWindowStatus.open);
        }
    };
    var onConfirmChange = function () {
        setModalStatusAUS(ModalWindowStatus.close);
        var enabled;
        // Multiple row editing
        if (selectedRows.length > 0) {
            emulateRequest(context);
            enabled = selectedConnectionState.key;
            // TODO handle request for multiple rows
        }
        else {
            enabled = 'enabled';
            // TODO handle request for single row. Row data is in 'switchChangeRow' variable
        }
    };
    var returnPrevStateOnDecline = function () {
        setTableData(tableData.map(function (item) {
            if (item.id === switchChangeRow.id) {
                if (item.user.details) {
                    if (item.user.details) {
                        item.user = __assign(__assign({}, item.user), { details: __assign(__assign({}, item.user.details), { approved: true }) });
                    }
                }
            }
            return item;
        }));
    };
    var onDeclineChange = function () {
        if (selectedRows.length > 0) {
            // TODO request here
            updateConnectionsSelect();
            returnPrevStateOnDecline();
        }
        else {
            returnPrevStateOnDecline();
        }
        // TODO refresh the table
        setModalStatusAUS(ModalWindowStatus.close);
        ;
    };
    var handleOrderCancel = function (_a) {
        var row = _a.row;
        if (row) {
            setCancelRow(row);
        }
        modalCancelController.setModalDialog(ModalDialog.small);
        modalCancelController.setShowBtnClose(false);
        setModalCancel(ModalWindowStatus.open);
    };
    var onConfirmCancel = function () {
        setModalCancel(ModalWindowStatus.close);
        if (selectedRows.length) {
            // TODO handle multiple orders cancel
        }
        else {
            // TODO handle single order cancel. Row data stored in 'cancelRow' variable
        }
        emulateRequest(context);
    };
    var onDeclineCancel = function () {
        setModalCancel(ModalWindowStatus.close);
    };
    var handleChange = function (state) {
        setSelectedRows(state.selectedRows);
        updateConnectionsSelect();
    };
    // If need to clear selected rows outside the table
    var handleClearRows = function () {
        setToggledClearRows(!toggledClearRows);
    };
    var goToAccount = function (accountId) {
        localStorage.setItem('selectedTab', 'account-detail');
        localStorage.setItem('accountId', accountId);
        location.reload();
    };
    var _q = useState(false), rowExpandedStatus = _q[0], setRowExpandedStatus = _q[1];
    var handleRowClick = function (status, id) {
        var row = tableData.find(function (item) { return item.id === id; });
        var rowExpand = false;
        if (row) {
            if (row.status && row.status !== status) {
                row.status = status;
                rowExpand = true;
            }
            else if (row.status) {
                row.status = '';
                rowExpand = false;
            }
            else {
                row.status = status;
                rowExpand = true;
            }
            if (row) {
                row.isExpand = rowExpand;
            }
            setRowExpandedStatus(!rowExpandedStatus);
        }
    };
    var onSearch = function (value) {
        localStorage.setItem('selectedAccountId', ''); // clear shared search value from navigation history
        setTableData(search(value, initialData, searchableColumns));
    };
    var onSetConnection = function (value) {
        if (value.key === 'disable') {
            setMultiDisabling(true);
            modalControllerAUS.setModalDialog(ModalDialog.small);
            modalControllerAUS.setShowBtnClose(false);
            setModalStatusAUS(ModalWindowStatus.open);
            setConnectionState(value);
        }
        else {
            // TODO make a request
        }
    };
    var ExpandableComponent = function (_a) {
        var data = _a.data;
        var currentExpandableRow = expandedState.find(function (item) { return item.id === data.id; });
        if (currentExpandableRow && data.status === 'edit') {
            return _jsx(EditRow, { row: data, onChange: handleRowChange }, void 0);
        }
        else if (currentExpandableRow && data.status === 'info') {
            return _jsx(InfoRow, { row: data }, void 0);
        }
        return _jsx(InfoRow, { row: data }, void 0);
    };
    var handleRowChange = function (row, name, value) {
        // TODO handle row editing
    };
    var handleCreateAccount = function () {
        modalControllerAC.setModalDialog(ModalDialog.small);
        modalControllerAC.setShowBtnClose(false);
        modalControllerAC.setContent(_jsx(MissingAccountDialog, { modalController: modalControllerAC, yesClick: function () {
                modalControllerAC.reset();
                modalControllerAC.setContent(_jsx(AddAccount, { accountIds: accountIds.map(String), participants: participants, modalController: modalControllerAC, success: function (accountId) { return modalControllerAC.setContent(_jsx(AddConnections, { refreshTable: function () {
                            //TODO refresh table
                        }, onClose: function () {
                            setModalStatusAC(ModalWindowStatus.close);
                        }, accountIds: [accountId], modalController: modalControllerAC }, void 0)); } }, void 0));
            }, noClick: function () { } }, void 0));
        setModalStatusAC(ModalWindowStatus.open);
    };
    var handleAddConnection = function () {
        if (initialData.length === 0) {
            handleCreateAccount();
        }
        else {
            setModalStatusAC(ModalWindowStatus.open);
        }
    };
    var ConnectionStatusView = function () {
        return (_jsx("div", __assign({ className: 'filter' }, { children: _jsx(FormikProvider, __assign({ value: formik }, { children: _jsx("form", __assign({ onSubmit: formik.handleSubmit }, { children: _jsx(SelectField, { withShadow: true, color: 'light', label: 'Connection', name: 'accountId', values: connectionsSelect, defaultValue: selectedConnectionState, setValue: onSetConnection, closeOnClickOutside: true }, void 0) }), void 0) }), void 0) }), void 0));
    };
    var NoDataComponent = function () { return (_jsx(NoTableData, { children: hasAccountAccess
            ? _jsxs("div", __assign({ className: 'flex flex-column align-center' }, { children: [_jsx("p", __assign({ className: 'grey-text' }, { children: "It looks like you don\u2019t have any accounts." }), void 0),
                    _jsx("p", __assign({ className: 'grey-text' }, { children: "Try creating one to start making connections." }), void 0),
                    _jsx(Button, __assign({ type: 'submit', color: 'white', form: 'rectangular', className: 'no-data-btn', onClick: handleCreateAccount }, { children: "Add Connection" }), void 0)] }), void 0)
            : (initialData.length > 0 ?
                _jsxs("div", __assign({ className: 'flex flex-column align-center' }, { children: [_jsx("p", __assign({ className: 'grey-text' }, { children: "No connections with that name" }), void 0),
                        _jsx(Button, __assign({ type: 'submit', color: 'white', form: 'rectangular', className: 'no-data-btn', onClick: handleAddConnection }, { children: "Add Connection" }), void 0)] }), void 0) :
                _jsxs("div", { children: [_jsx("p", { children: "Create or join an entity to see Accounts" }, void 0),
                        _jsx(ConnectBtnGroup, { props: user }, void 0)] }, void 0)) }, void 0)); };
    var initialSearchValue = localStorage.getItem('selectedAccountId') || '';
    return (_jsxs("div", { children: [_jsxs("div", __assign({ className: "flex justify-between scrollable" }, { children: [_jsxs("div", __assign({ className: 'flex align-center' }, { children: [_jsx(Search, { onChange: onSearch, placeholder: 'Search', initialValue: initialSearchValue }, void 0),
                            selectedRows.length > 0 &&
                                _jsxs("div", __assign({ className: 'flex align-center edit-container' }, { children: [_jsx(ConnectionStatusView, {}, void 0),
                                        _jsx(CancelBtn, { active: true, onClick: handleOrderCancel.bind(null, {}) }, void 0),
                                        _jsx(SelectedCount, { count: selectedRows.length }, void 0)] }), void 0)] }), void 0),
                    _jsx(Button, __assign({ type: 'submit', color: 'red', form: 'rectangular', className: 'no-data-btn', onClick: handleAddConnection }, { children: "Add connection" }), void 0)] }), void 0),
            _jsx("div", __assign({ className: 'table-wrap' }, { children: _jsx(DataTable, { columns: columns, data: tableData, selectableRows // add for checkbox selection
                    : true, onSelectedRowsChange: handleChange, selectableRowsHighlight: true, clearSelectedRows: toggledClearRows, sortIcon: _jsx(ArrowIcon, {}, void 0), expandableRows: true, expandableRowsComponent: _jsx(ExpandableComponent, {}, void 0), expandableRowExpanded: function (row) { return (row.isExpand); }, persistTableHead: true, conditionalRowStyles: conditionalRowStyles, noDataComponent: _jsx(NoDataComponent, {}, void 0), defaultSortAsc: false, pagination: true, paginationComponent: Pagination, paginationPerPage: 15, paginationRowsPerPageOptions: [15, 25, 50, 100, 200], 
                    // @ts-ignore
                    selectableRowsComponent: tablePropsForCheckbox.selectableRowsComponent, selectableRowsComponentProps: tablePropsForCheckbox.selectableRowsComponentProps }, void 0) }), void 0),
            _jsx(ModalWindow, __assign({ status: modalStatusAC, setStatus: function (status) { return setModalStatusAC(status); }, isNoScrollable: true, setModalController: setModalControllerAC }, { children: _jsx(AddConnections, { accountIds: accountIds.map(String), modalController: modalControllerAC, onClose: function () {
                        setModalStatusAC(ModalWindowStatus.close);
                    }, refreshTable: function () {
                        //TODO refresh table
                    } }, void 0) }), void 0),
            _jsx(ModalWindow, __assign({ status: modalStatusAUS, setStatus: function (status) { return setModalStatusAUS(status); }, isNoScrollable: true, setModalController: setModalControllerAUS }, { children: _jsx(AreYouSureDialog, { yesClick: onConfirmChange, noClick: onDeclineChange, type: 'derivatives', amountOfSelectedItems: multiDisabling ? selectedRows.length : 1 }, void 0) }), void 0),
            _jsx(ModalWindow, __assign({ status: modalCancel, setStatus: function (status) { return setModalCancel(status); }, isNoScrollable: true, setModalController: setModalCancelController }, { children: _jsx(AreYouSureDialog, { amountOfSelectedItems: selectedRows.length, yesClick: onConfirmCancel, noClick: onDeclineCancel }, void 0) }), void 0)] }, void 0));
};
