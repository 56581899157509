var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import ReactSwitch from 'react-switch';
export var Switch = function (_a) {
    var row = _a.row, value = _a.value, onChange = _a.onChange;
    var _b = useState(value), switchState = _b[0], setSwitchState = _b[1];
    useEffect(function () {
        setSwitchState(value);
    }, [value]);
    var handleChange = function () {
        var newSwitchValue = !switchState;
        setSwitchState(newSwitchValue);
        onChange(row, newSwitchValue);
    };
    return (_jsx("div", __assign({ className: 'flex' }, { children: _jsx(ReactSwitch, { onChange: handleChange, checked: switchState, uncheckedIcon: false, checkedIcon: false, height: 24, width: 44, handleDiameter: 20, offColor: '#D0D0D0', onColor: '#DD7168' }, void 0) }), void 0));
};
