var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import BlockTitle from '../../../block-title';
import Button from '../../../buttons/button';
import ContactsModalWindow from '../../../forms/contacts-modal-window';
import Column from '../../../column';
import Tutorial from '../tutorial';
import { TutorialContext } from '../../../../';
import { ModalWindowStatus } from '../../../forms/modal-window';
import { pasteFormatter } from '../../../forms/fields/phone-field/formatters';
import { camelCaseToDifferentWords } from '../../../../utils/helpers';
import './contact-information.scss';
var ContactInformation = function (props) {
    var _a, _b, _c, _d, _e, _f, _g;
    var lastName;
    var firstName;
    var email;
    var phoneNumber;
    var lastPasswordChange;
    var photoId;
    var roles;
    var NA = 'N/A';
    var values = {
        email: '',
        firstName: '',
        lastName: '',
        photo: '',
        phoneNumber: '',
        lastPasswordChange: ''
    };
    try {
        lastName = (_a = props === null || props === void 0 ? void 0 : props.details) === null || _a === void 0 ? void 0 : _a.lastName;
        firstName = (_b = props === null || props === void 0 ? void 0 : props.details) === null || _b === void 0 ? void 0 : _b.firstName;
        email = props === null || props === void 0 ? void 0 : props.email;
        lastPasswordChange = (_c = props === null || props === void 0 ? void 0 : props.interfaceConfig) === null || _c === void 0 ? void 0 : _c.lastPasswordChange;
        phoneNumber = pasteFormatter(((_d = props === null || props === void 0 ? void 0 : props.details) === null || _d === void 0 ? void 0 : _d.phoneNumber) || '');
        photoId = (_e = props === null || props === void 0 ? void 0 : props.details) === null || _e === void 0 ? void 0 : _e.photoId;
        if (((_f = props === null || props === void 0 ? void 0 : props.roles) === null || _f === void 0 ? void 0 : _f.length) > 1) {
            roles = props === null || props === void 0 ? void 0 : props.roles[0];
            props === null || props === void 0 ? void 0 : props.roles.forEach(function (item, index) {
                item = camelCaseToDifferentWords(item);
                if (index > 0) {
                    roles = roles + ', ' + item;
                }
            });
        }
        else {
            roles = null;
        }
        values = {
            email: email,
            firstName: firstName || '',
            lastName: lastName || '',
            photo: photoId || '',
            phoneNumber: ((_g = props === null || props === void 0 ? void 0 : props.details) === null || _g === void 0 ? void 0 : _g.phoneNumber) || '',
            lastPasswordChange: lastPasswordChange || ''
        };
    }
    catch (err) {
        lastName = null;
        firstName = null;
        email = null;
        phoneNumber = null;
        lastPasswordChange = null;
        photoId = null;
        roles = null;
    }
    var _h = useState(ModalWindowStatus.close), modalStatus = _h[0], setModalStatus = _h[1];
    var openEditContactInfo = function () {
        setModalStatus(ModalWindowStatus.open);
    };
    var tutorialCtx = useContext(TutorialContext);
    return _jsx(_Fragment, { children: _jsxs("div", __assign({ className: 'contact-information' }, { children: [_jsx(BlockTitle, __assign({ title: 'Contact Information', tip: {
                        title: 'Contact Information',
                        text: 'Here is your contact information. You will need to fill this out in full before joining or creating a business entity.'
                    } }, { children: tutorialCtx.step === 2 &&
                        _jsx(Tutorial, __assign({ title: 'Contact Information', increaseStep: tutorialCtx.increaseStep, decreaseStep: tutorialCtx.decreaseStep, x: tutorialCtx.inAbleToRender ?
                                tutorialCtx.getPosition('contact-information') : 0, positions: tutorialCtx.positions, step: tutorialCtx.step, left: true }, { children: "Here is your contact information. You will need to fill this out in full before joining or creating a business entity." }), void 0) }), void 0),
                _jsxs("div", __assign({ className: 'column-block' }, { children: [_jsx(Column, { upperCell: {
                                title: 'Last Name',
                                value: lastName || NA
                            }, lowerCell: {
                                title: 'Phone',
                                value: phoneNumber || NA
                            } }, void 0),
                        _jsx(Column, { upperCell: {
                                title: 'First Name',
                                value: firstName || NA
                            }, lowerCell: {
                                title: 'Government ID',
                                value: photoId ? 'View' : null,
                                link: photoId || ''
                            } }, void 0),
                        _jsx(Column, { upperCell: {
                                title: 'Email',
                                value: email || NA,
                                isEmail: true
                            }, lowerCell: {
                                title: 'Role',
                                value: roles || NA
                            } }, void 0)] }), void 0),
                _jsx(Button, __assign({ onClick: openEditContactInfo, type: 'button', form: 'rectangular', color: 'white' }, { children: "Edit" }), void 0),
                _jsx(ContactsModalWindow, { status: modalStatus, setStatus: function (status) { return setModalStatus(status); }, values: values }, void 0)] }), void 0) }, void 0);
};
export default ContactInformation;
