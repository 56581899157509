var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import './footer.scss';
var Footer = function (props) {
    return _jsx(_Fragment, { children: _jsx("div", __assign({ className: 'footer' }, { children: _jsxs("div", __assign({ className: 'container h-full flex flex-column justify-between' }, { children: [_jsxs("div", __assign({ className: 'flex justify-between top-section' }, { children: [_jsx("div", __assign({ className: 'logo' }, { children: _jsx("img", { src: 'images/logos/btnl_logo.svg', alt: 'btnl_logo' }, void 0) }), void 0),
                            _jsxs("div", __assign({ className: 'links' }, { children: [_jsxs("div", __assign({ className: 'column' }, { children: [_jsx("p", { children: "Company" }, void 0),
                                            _jsx("div", { children: _jsx("a", __assign({ href: '#' }, { children: "About" }), void 0) }, void 0),
                                            _jsx("div", { children: _jsx("a", __assign({ href: '#' }, { children: "Help" }), void 0) }, void 0),
                                            _jsx("div", { children: _jsx("a", __assign({ href: '#' }, { children: "Press" }), void 0) }, void 0)] }), void 0),
                                    _jsxs("div", __assign({ className: 'column' }, { children: [_jsx("p", { children: "Products" }, void 0),
                                            _jsx("div", __assign({ className: props.signUp ? 'long-link' : '' }, { children: _jsx("a", __assign({ href: '#' }, { children: "Bitcoin Futures" }), void 0) }), void 0),
                                            _jsx("div", __assign({ className: props.signUp ? 'long-link' : '' }, { children: _jsx("a", __assign({ href: '#' }, { children: "Bitcoin Options" }), void 0) }), void 0)] }), void 0),
                                    _jsxs("div", __assign({ className: 'column' }, { children: [_jsx("p", { children: "Exchange" }, void 0),
                                            _jsx("div", { children: _jsx("a", __assign({ href: '#' }, { children: "Docs" }), void 0) }, void 0),
                                            _jsx("div", { children: _jsx("a", __assign({ href: '#' }, { children: "Trade" }), void 0) }, void 0),
                                            _jsx("div", { children: _jsx("a", __assign({ href: '#' }, { children: "Broker" }), void 0) }, void 0),
                                            _jsx("div", { children: _jsx("a", __assign({ href: '#' }, { children: "Data" }), void 0) }, void 0),
                                            _jsx("div", { children: _jsx("a", __assign({ href: '#' }, { children: "Regulation" }), void 0) }, void 0)] }), void 0),
                                    _jsxs("div", __assign({ className: 'socials' }, { children: [_jsx("a", __assign({ href: '#' }, { children: _jsx("img", { src: 'images/icons/github_logo.svg', alt: 'github' }, void 0) }), void 0),
                                            _jsx("a", __assign({ href: '#' }, { children: _jsx("img", { src: 'images/icons/twitter_logo.svg', alt: 'twitter' }, void 0) }), void 0)] }), void 0)] }), void 0)] }), void 0),
                    _jsxs("div", __assign({ className: 'text' }, { children: [_jsx("p", { children: "Bitnomial, Inc., a Delaware corporation headquartered in Chicago, develops and operates exchange and settlement infrastructure." }, void 0),
                            _jsx("br", {}, void 0),
                            _jsx("p", { children: "Exchange, LLC, a Delaware company and Bitnomial, Inc. affiliate, is pending and subject to CFTC approval to operate an exchange as a Designated Contract Market (DCM)." }, void 0),
                            _jsx("br", {}, void 0),
                            _jsx("p", { children: "Bitnomial, Inc. and affiliates do not operate a trading desk and are therefore an independent and neutral market operator that does not compete with our clients." }, void 0),
                            _jsx("br", {}, void 0),
                            _jsx("p", { children: "\u00A9 2020 Bitnomial, Inc. All rights reserved." }, void 0)] }), void 0)] }), void 0) }), void 0) }, void 0);
};
export default Footer;
