var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import FormHeader from '../../form-header';
import Button from '../../../buttons/button';
import { FormikProvider, useFormik } from 'formik';
import SelectField, { DefaultValue } from '../../fields/select-field';
import { ClipLoader } from 'react-spinners';
import TextField from '../../fields/text-field';
import './add-account.scss';
var AddAccount = function (props) {
    var formik = useFormik({
        initialValues: {
            accountId: '',
            participant: DefaultValue.value,
        },
        onSubmit: function (values) {
            setShowValidationMsg(false);
            formik.setSubmitting(true);
            setTimeout(function () {
                formik.setSubmitting(false);
                if (checkAccountIdUnique()) {
                    participant.key; //TODO getting selected participant id
                    var newAccountId = values.accountId; //TODO getting accountId
                    if (props.success) {
                        props.success(newAccountId);
                        localStorage.setItem('accountId', newAccountId);
                    }
                    else {
                        localStorage.setItem('selectedTab', 'account-detail');
                        location.reload();
                    }
                }
                else {
                    setShowValidationMsg(true);
                }
            }, 1000);
        },
    });
    var convertParticipantsToString = function () {
        var values = [];
        props.participants.forEach(function (value, index) {
            values.push({
                value: value.name,
                key: value.id.toString(),
            });
        });
        return values;
    };
    var _a = useState({
        key: '',
        value: '',
    }), participant = _a[0], setParticipant = _a[1];
    var _b = useState(false), showValidationMsg = _b[0], setShowValidationMsg = _b[1];
    var checkChanges = function () {
        return formik.values.accountId === '' || formik.values.participant === DefaultValue.value;
    };
    function checkAccountIdUnique() {
        return !props.accountIds.includes(formik.values.accountId);
    }
    return _jsxs("div", __assign({ className: 'add-account' }, { children: [_jsx(FormHeader, { title: 'Add Account', withoutMarginBottom: true }, void 0),
            _jsx("p", { children: "Enter the ID and select a Participant Business Entity to assign to the new account." }, void 0),
            _jsx(FormikProvider, __assign({ value: formik }, { children: _jsxs("form", __assign({ onSubmit: formik.handleSubmit }, { children: [_jsx(TextField, { name: 'accountId', label: 'Account ID' }, void 0),
                        _jsx(SelectField, { label: 'Participant Business Entity', name: 'participant', values: convertParticipantsToString(), setValue: setParticipant, closeOnClickOutside: true, defaultValue: DefaultValue }, void 0),
                        _jsxs("div", __assign({ className: 'flex-row flex-row-left' }, { children: [_jsx(Button, __assign({ type: 'submit', form: 'rounded', color: 'red', disabled: checkChanges() }, { children: "Create Account" }), void 0),
                                _jsx("div", { className: 'distance-8' }, void 0),
                                formik.isSubmitting ? _jsx(ClipLoader, { color: '#DD7168' }, void 0) : _jsx("div", {}, void 0),
                                showValidationMsg && _jsx("div", __assign({ className: 'error' }, { children: "Account already exists" }), void 0)] }), void 0)] }), void 0) }), void 0)] }), void 0);
};
export default AddAccount;
