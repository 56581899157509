var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import './form-header.scss';
export var Images;
(function (Images) {
    Images["bitnomial"] = "/images/forms/confirm-contact-info.svg";
    Images["profile"] = "/images/forms/profile.svg";
    Images["lock"] = "/images/forms/lock.svg";
    Images["connection"] = "/images/forms/connection.svg";
    Images["settings"] = "/images/icons/settings-symbol.svg";
})(Images || (Images = {}));
;
var FormHeader = function (props) {
    return _jsx(_Fragment, { children: _jsxs("div", __assign({ className: 'form-header ' + (props.withoutMarginBottom ? 'margin-bottom-0' : '') }, { children: [_jsxs("div", __assign({ className: 'title' }, { children: [props.image && _jsx("img", { src: props.image, alt: 'logo' }, void 0),
                        _jsx("span", { children: props.title }, void 0)] }), void 0),
                props.description ? _jsx("p", { children: props.description }, void 0) : _jsx("div", {}, void 0)] }), void 0) }, void 0);
};
export default FormHeader;
