var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FormHeader, { Images } from '../../form-header';
import Button from '../../../buttons/button';
import './entity-approved.scss';
var EntityApprovedDialog = function (props) {
    return _jsxs("div", __assign({ className: 'entity-approved' }, { children: [_jsx(FormHeader, { title: 'Entity Approved', image: Images.bitnomial }, void 0),
            _jsx("p", { children: "Congratulations! Your entity was approved. You can now start taking advantage of all Bitnomial has to offer. But first you need to create an account. Would you like to add one now?" }, void 0),
            _jsxs("div", __assign({ className: 'flex-row flex-row-left' }, { children: [_jsx(Button, __assign({ type: 'button', form: 'rounded', color: 'red', onClick: props.yesClick }, { children: "Add account" }), void 0),
                    _jsx("div", { className: 'distance-8' }, void 0),
                    _jsx(Button, __assign({ type: 'button', form: 'rounded', color: 'white', onClick: props.noClick }, { children: "No" }), void 0)] }), void 0)] }), void 0);
};
export default EntityApprovedDialog;
