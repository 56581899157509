var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import './btn-password.scss';
// helper function
var isToday = function (someDate) {
    var today = new Date();
    return someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear();
};
var BtnPassword = function (props) {
    var date = new Date(props.lastPasswordChange || '');
    var formattedDate = date.toLocaleDateString();
    var isJustNow;
    if (date.getTime() === date.getTime()) { // if valid date
        isJustNow = isToday(date);
    }
    else {
        isJustNow = false;
        formattedDate = 'N/A';
    }
    return _jsx(_Fragment, { children: _jsxs("div", { children: [_jsx("label", { children: "Password" }, void 0),
                _jsx("div", { children: _jsxs("button", __assign({ className: 'btn-password', onClick: props.onClick }, { children: ["\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022", _jsx("img", { src: '/images/forms/arrow-right.svg', alt: '>' }, void 0)] }), void 0) }, void 0),
                _jsxs("label", __assign({ className: 'password-last-change' }, { children: ["Last changed: ", _jsx("span", __assign({ className: isJustNow ? 'last-change-now' : '' }, { children: isJustNow ? 'Just now' : formattedDate }), void 0)] }), void 0)] }, void 0) }, void 0);
};
export default BtnPassword;
