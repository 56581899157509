var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import FormHeader, { Images } from '../../form-header';
import { FormikProvider, useFormik } from 'formik';
import EmailField from '../../fields/email-field';
import Button from '../../../buttons/button';
import { ClipLoader } from 'react-spinners';
import useFormController from '../../formController';
import * as Yup from 'yup';
import './password-recovery.scss';
var PasswordRecovery = function () {
    var formik = useFormik({
        initialValues: { email: '' },
        validationSchema: Yup.object().shape({
            email: Yup.string().email('Invalid email').required('Enter email'),
        }),
        onSubmit: function (values) {
            formik.setSubmitting(true);
            setTimeout(function () {
                formik.setSubmitting(false);
                formController.success();
            }, 1000);
        }
    });
    var formController = useFormController({
        success: function () {
            setBodyVisible(true);
            setDescription('If account exists, an email will be sent with further instructions.');
        },
    });
    var _a = useState('Enter a trusted email address to which we can send a reset link.'), description = _a[0], setDescription = _a[1];
    var _b = useState(false), bodyVisible = _b[0], setBodyVisible = _b[1];
    return _jsx(_Fragment, { children: _jsxs("div", __assign({ className: 'password-recovery adaptive-modal-window' }, { children: [_jsx(FormHeader, { title: 'Recover Password', image: Images.lock, description: description }, void 0),
                _jsx("div", __assign({ className: 'form-body', hidden: bodyVisible }, { children: _jsx(FormikProvider, __assign({ value: formik }, { children: _jsxs("form", __assign({ className: 'password-recovery-form', noValidate: true, onSubmit: formik.handleSubmit }, { children: [_jsx(EmailField, { name: 'email', onChange: formik.handleChange }, void 0),
                                _jsxs("div", __assign({ className: 'flex-row flex-row-left' }, { children: [_jsx(Button, __assign({ type: 'submit', form: 'rounded', color: 'red' }, { children: "Change password" }), void 0),
                                        _jsx("div", { className: 'distance-8' }, void 0),
                                        formik.isSubmitting ? _jsx(ClipLoader, { color: '#DD7168' }, void 0) : _jsx("div", {}, void 0)] }), void 0)] }), void 0) }), void 0) }), void 0)] }), void 0) }, void 0);
};
export default PasswordRecovery;
