var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Button from '../../../buttons/button';
import { ClipLoader } from 'react-spinners';
var DiscardChangeDialog = function (props) {
    var _a = useState(false), isSubmitting = _a[0], setSubmitting = _a[1];
    var content;
    if (isSubmitting) {
        content = _jsx("div", __assign({ className: 'flex-row' }, { children: _jsx(ClipLoader, { color: '#DD7168' }, void 0) }), void 0);
    }
    else {
        content = _jsxs("div", __assign({ className: 'flex-row' }, { children: [_jsx(Button, __assign({ onClick: function () {
                        props.discardAndExitClick(setSubmitting);
                    }, type: 'button', form: 'rounded', color: 'red' }, { children: "Discard and Exit" }), void 0),
                _jsx("div", { className: 'distance-8' }, void 0),
                _jsx(Button, __assign({ onClick: function () {
                        props.noClick();
                    }, type: 'button', form: 'rounded', color: 'white' }, { children: "No" }), void 0)] }), void 0);
    }
    return _jsxs("div", __assign({ className: 'save-changes' }, { children: [_jsx("span", { children: "Discard changes?" }, void 0),
            _jsx("p", { children: "Are you sure you wish to leave this page? All your changes will be discarded." }, void 0), content] }), void 0);
};
export default DiscardChangeDialog;
