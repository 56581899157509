var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import Tooltip from '../tooltip';
import './block-title.scss';
var BlockTitle = function (props) {
    var _a = useState(false), showTooltip = _a[0], setShowTooltip = _a[1];
    return _jsx(_Fragment, { children: _jsxs("div", __assign({ className: 'block-title' }, { children: [_jsxs("div", { children: [_jsx("span", { children: props.title }, void 0),
                        _jsxs("div", __assign({ className: 'position-relative' }, { children: [showTooltip &&
                                    _jsx(Tooltip, { title: props.tip.title, text: props.tip.text, side: 'left' }, void 0),
                                _jsx("img", { onMouseOver: function () { setShowTooltip(true); }, onMouseOut: function () { setShowTooltip(false); }, src: '/images/icons/tooltip_small.svg', alt: 'tooltip', "data-for": props.shift ? 'tooltip-general-sh' : 'tooltip-general', "data-tip": props.tip, "data-iscapture": 'true' }, void 0), props.children] }), void 0)] }, void 0),
                _jsx("hr", {}, void 0)] }), void 0) }, void 0);
};
export default BlockTitle;
