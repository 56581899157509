// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../../public/images/icons/arriw-left-white.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".back-to-link {\n  margin-left: -9px;\n}\n.back-to-link > a {\n  margin-bottom: 48px;\n  display: flex;\n  align-items: center;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 12px;\n  line-height: 14px;\n  letter-spacing: 0.8px;\n  text-decoration: none;\n}\n.back-to-link > a div {\n  width: 24px;\n  height: 24px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.back-to-link > a img {\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}", "",{"version":3,"sources":["webpack://./src/components/header/back-to-link/backToLink.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;AACI;EACI,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,qBAAA;EACA,qBAAA;AACR;AACQ;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACZ;AAEQ;EACI,gDAAA;AAAZ","sourcesContent":[".back-to-link {\n    margin-left: -9px;\n\n    > a {\n        margin-bottom: 48px;\n        display: flex;\n        align-items: center;\n        font-style: normal;\n        font-weight: normal;\n        font-size: 12px;\n        line-height: 14px;\n        letter-spacing: 0.8px;\n        text-decoration: none;\n\n        div {\n            width: 24px;\n            height: 24px;\n            display: flex;\n            justify-content: center;\n            align-items: center;\n        }\n\n        img {\n            content: url('../../../../public/images/icons/arriw-left-white.svg');\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
