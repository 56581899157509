var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import './../../../components/container-tabs/container-tabs.scss';
import { AccountsTab } from '../../account/accounts-tab';
import { ApprovalsTab } from '../../account/approvals-tab';
import Button, { buttonImages } from '../../buttons/button';
import './accounts-main.scss';
var AccountsMain = function (_a) {
    var model = _a.model, user = _a.user;
    return _jsx(_Fragment, { children: _jsx("div", __assign({ className: "accounts-main scrollable-section" }, { children: _jsx("div", __assign({ className: 'container' }, { children: _jsxs(Tabs, { children: [_jsxs("div", __assign({ className: 'tab-box' }, { children: [_jsxs(TabList, { children: [_jsx(Tab, { children: "Accounts" }, void 0),
                                        _jsxs(Tab, __assign({ className: 'relative react-tabs__tab' }, { children: ["Approvals", _jsx("span", { className: 'with-approvals' }, void 0)] }), void 0)] }, void 0),
                                _jsx(Button, __assign({ className: 'downloads', type: 'button', form: 'rectangular', color: 'riverbed', image: buttonImages.download }, { children: "Download Fills" }), void 0)] }), void 0),
                        _jsx(TabPanel, { children: _jsx("div", __assign({ className: 'tab-view' }, { children: _jsx(AccountsTab, { data: model.accounts, user: user, participants: model.participants }, void 0) }), void 0) }, void 0),
                        _jsx(TabPanel, { children: _jsx("div", __assign({ className: 'tab-view' }, { children: _jsx(ApprovalsTab, { data: model.approvals ? model.approvals : [], user: user, participants: model.participants }, void 0) }), void 0) }, void 0)] }, void 0) }), void 0) }), void 0) }, void 0);
};
export default AccountsMain;
