var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useField } from 'formik';
import PhotoField from '../photo-field';
import './option-field.scss';
var values;
(function (values) {
    values["clear"] = "ClearingFirm";
    values["trading"] = "Broker";
})(values || (values = {}));
;
var OptionField = function (props) {
    var _a = useField({
        name: props.name,
        id: props.name,
    }), field = _a[0], meta = _a[1], helpers = _a[2];
    var _b = useState(''), anim = _b[0], setAnim = _b[1];
    var _c = useState(field.value == values.clear), isClear = _c[0], setClear = _c[1];
    var _d = useState(field.value == values.trading), isTrading = _d[0], setTrading = _d[1];
    var _e = useState(props.showOptionalInfo), isVisibleOptionInfo = _e[0], setVisibilityOptionInfo = _e[1];
    var _f = useState(props.showOptionalInfo), isVisibleContainerInfo = _f[0], setVisibilityContainerInfo = _f[1];
    // const [isClear,setClear] = useState(false);
    // const [isTrading,setTrading] = useState(false);
    // const [isVisibleOptionInfo,setVisibilityOptionInfo] = useState(false);
    // const [isVisibleContainerInfo,setVisibilityContainerInfo] = useState(false);
    var moveImg = function (direction) {
        if (isVisibleOptionInfo) {
            setAnim('position-' + direction + ' ' + 'move-' + direction);
        }
        else {
            setAnim('position-' + direction);
        }
    };
    return _jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: 'option-field' }, { children: [_jsx("div", { children: _jsxs("label", __assign({ className: 'radio' }, { children: [_jsx("input", { type: 'radio', name: props.name, value: values.clear, defaultChecked: field.value == values.clear, onClick: function (e) {
                                        setTrading(false);
                                        setClear(true);
                                        helpers.setValue(values.clear);
                                        moveImg('left');
                                        setVisibilityContainerInfo(true);
                                    } }, void 0),
                                _jsx("span", { children: "Clearing Firm" }, void 0)] }), void 0) }, void 0),
                    _jsx("div", { children: _jsxs("label", __assign({ className: 'radio' }, { children: [_jsx("input", { type: 'radio', name: props.name, value: values.trading, defaultChecked: field.value == values.trading, onClick: function (e) {
                                        setClear(false);
                                        setTrading(true);
                                        helpers.setValue(values.trading);
                                        moveImg('right');
                                        setVisibilityContainerInfo(true);
                                    } }, void 0),
                                _jsx("span", { children: "Trading Participant" }, void 0)] }), void 0) }, void 0),
                    _jsx("div", __assign({ className: 'error' }, { children: meta.error ? meta.error : null }), void 0)] }), void 0),
            isVisibleContainerInfo && _jsx("div", __assign({ className: 'container-info', onAnimationEnd: function () {
                    setVisibilityOptionInfo(true);
                } }, { children: isVisibleOptionInfo && _jsxs("div", __assign({ className: 'option-info-block' }, { children: [_jsx("img", { className: anim, src: '/images/icons/tooltip_arrow_up.svg', alt: 'tooltip_arrow_up' }, void 0),
                        _jsx("div", __assign({ className: 'option-info-clear', style: isClear ? { display: 'block' } : { display: 'none' } }, { children: _jsx(PhotoField, { name: 'option_information', text: _jsxs("div", { children: ["Download the ", _jsx("strong", { children: "Clearing Firm " }, void 0), "PDF. After filling out the information please upload the completed PDF here."] }, void 0), showPdfDownload: true }, void 0) }), void 0),
                        _jsx("div", __assign({ className: 'option-info-trading', style: isTrading ? { display: 'block' } : { display: 'none' } }, { children: _jsx(PhotoField, { name: 'option_information', text: _jsxs("div", { children: ["Download the", _jsx("strong", { children: " Trading Participant " }, void 0), "PDF. After filling out the information please upload the completed PDF here."] }, void 0), showPdfDownload: true }, void 0) }), void 0)] }), void 0) }), void 0)] }, void 0);
};
export default OptionField;
