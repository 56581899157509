var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import { TutorialContext } from '../../../index';
import Tutorial from '../../pages/settings-main/tutorial';
var OverviewHeader = function (props) {
    var tutorialCtx = useContext(TutorialContext);
    return _jsxs(_Fragment, { children: [_jsx("h1", __assign({ className: 'header-title' }, { children: "Overview" }), void 0),
            _jsxs("ul", __assign({ className: 'tabs' }, { children: [_jsx("li", __assign({ className: props.selectedTab === 'accounts-overview' ? 'highlighted' : '' }, { children: _jsxs("a", __assign({ onClick: function (event) { return props.changeTab(event, 'accounts-overview'); }, href: '#' }, { children: ["Accounts", props.accountApprovalsCount && _jsx("div", __assign({ className: 'approvals-count inline-flex justify-center align-center' }, { children: props.accountApprovalsCount }), void 0)] }), void 0) }), void 0),
                    _jsx("li", __assign({ className: props.selectedTab === 'connections' ? 'highlighted' : '' }, { children: _jsxs("a", __assign({ onClick: function (event) { return props.changeTab(event, 'connections'); }, href: '#' }, { children: ["Connections", props.connectionApprovalsCount && _jsx("div", __assign({ className: 'approvals-count inline-flex justify-center align-center' }, { children: props.connectionApprovalsCount }), void 0)] }), void 0) }), void 0),
                    _jsxs("li", __assign({ className: 'tootip-container ' + (props.selectedTab === 'settings' ? 'highlighted' : '') }, { children: [_jsx("a", __assign({ onClick: function (event) { return props.changeTab(event, 'settings'); }, href: '#' }, { children: "Settings" }), void 0),
                            tutorialCtx.step === 0 &&
                                _jsx(Tutorial, __assign({ title: 'Welcome to Bitnominal!', increaseStep: tutorialCtx.increaseStep, decreaseStep: tutorialCtx.decreaseStep, x: tutorialCtx.inAbleToRender ?
                                        tutorialCtx.getPosition('settings-in-header') : 0, positions: tutorialCtx.positions, step: tutorialCtx.step, leftActive: true, first: true }, { children: "Here you will find your Settings, including: business entity information, contact information, and a list of users you manage." }), void 0)] }), void 0)] }), void 0)] }, void 0);
};
export default OverviewHeader;
