var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import FormHeader from '../../form-header';
import { FormikProvider, useFormik } from 'formik';
import useFormController from '../../formController';
import * as Yup from 'yup';
import EmailField from '../../fields/email-field';
import { ClipLoader } from 'react-spinners';
import Button from '../../../buttons/button';
import { ModalDialog } from '../../modal-window';
import './invite-user.scss';
var InviteUser = function (props) {
    var _a = useState(false), sent = _a[0], setSent = _a[1];
    var formik = useFormik({
        initialValues: { email: '' },
        validationSchema: Yup.object().shape({
            email: Yup.string().email('Invalid email').required('Enter email')
        }),
        onSubmit: function (values) {
            formik.setSubmitting(true);
            setTimeout(function () {
                formik.setSubmitting(false);
                formController.success();
            }, 1000);
        },
    });
    var formController = useFormController({
        success: function () {
            props.modalController.setShowBtnClose(false);
            props.modalController.setModalDialog(ModalDialog.small);
            setSent(true);
            setTimeout(function () {
                props.modalController.startClosing();
            }, 1500);
        },
    });
    if (sent) {
        return _jsxs("div", __assign({ className: 'invite-user flex-row' }, { children: [_jsx("img", { src: '/images/forms/invite.svg', alt: 'Invite Sent' }, void 0),
                _jsx("span", { children: "Invite Sent" }, void 0)] }), void 0);
    }
    else {
        return _jsxs("div", __assign({ className: 'invite-user' }, { children: [_jsx(FormHeader, { title: 'Invite User', withoutMarginBottom: true }, void 0),
                _jsx("p", { children: "Please enter the email address of the person you wish to invite. After submitting this invitation they will receive instructions on how to complete the sign-up process." }, void 0),
                _jsx(FormikProvider, __assign({ value: formik }, { children: _jsxs("form", __assign({ onSubmit: formik.handleSubmit }, { children: [_jsx(EmailField, { name: 'email' }, void 0),
                            _jsxs("div", __assign({ className: 'flex-row flex-row-left' }, { children: [_jsx(Button, __assign({ type: 'submit', form: 'rounded', color: 'red', disabled: formik.values.email === '' }, { children: "Send Invite" }), void 0),
                                    _jsx("div", { className: 'distance-8' }, void 0),
                                    formik.isSubmitting ? _jsx(ClipLoader, { color: '#DD7168' }, void 0) : _jsx("div", {}, void 0)] }), void 0)] }), void 0) }), void 0)] }), void 0);
    }
};
export default InviteUser;
