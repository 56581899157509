var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './backToLink.scss';
var BackToLink = function (props) {
    return (_jsx("div", __assign({ className: 'back-to-link' }, { children: _jsxs("a", __assign({ href: '#', onClick: function (e) { return props.changeTab(e, props.href); } }, { children: [_jsx("div", { children: _jsx("img", { src: '/images/icons/arriw-left-white.svg', alt: 'back to settings' }, void 0) }, void 0), "Back to ", props.text] }), void 0) }), void 0));
};
export default BackToLink;
