var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import DataTable from 'react-data-table-component';
import { ArrowIcon } from '../../shared/arrow-icon/arrow-icon';
import { ColHeader } from '../../shared/col-header/col-header';
import { NoTableData } from '../../shared/no-table-data/no-table-data';
import { ExpandCell } from '../../shared/expand-cell/expand-cell';
import Button from '../../buttons/button';
import SummaryPanel from './summary-panel';
import { Search } from '../../forms/fields/search-input';
import { search } from '../../../utils/search';
import { formatNumber } from '../../../utils/helpers';
import './account-detail.scss';
import '../../../scss/table.scss';
var ExpandableComponent = function (_a) {
    var data = _a.data;
    var handleClick = function (event) {
        // TODO add click action
    };
    return _jsxs("div", { children: [_jsx("div", __assign({ className: 'expanded-row-wrapper w-full' }, { children: _jsx("div", __assign({ className: 'expanded-row edit-row flex flex-column' }, { children: _jsxs("div", __assign({ className: 'expanded-row-container flex' }, { children: [_jsxs("div", __assign({ className: 'content flex' }, { children: [_jsxs("div", __assign({ className: 'address flex flex-column' }, { children: [_jsx("h4", { children: "Bitnomial Address" }, void 0),
                                            _jsx("a", __assign({ href: '#' }, { children: data.contents.bitnomialAddress }), void 0)] }), void 0),
                                    data.contents.returnAddress && _jsxs("div", __assign({ className: 'address flex flex-column' }, { children: [_jsx("h4", { children: "Return Address" }, void 0),
                                            _jsx("a", __assign({ href: '#' }, { children: data.contents.returnAddress }), void 0)] }), void 0)] }), void 0),
                            _jsx("div", __assign({ className: 'button flex justify-end w-full' }, { children: _jsx(Button, __assign({ type: 'button', form: 'rectangular', color: 'white', onClick: handleClick, className: 'reset-focus' }, { children: "See details" }), void 0) }), void 0)] }), void 0) }), void 0) }), void 0),
            _jsx("div", __assign({ className: 'expanded-row-bottom-shadow' }, { children: _jsx("div", {}, void 0) }), void 0)] }, void 0);
};
var AccountDetail = function (_a) {
    var _b;
    var model = _a.model, user = _a.user;
    // Mock data for table
    var mockedData = [
        __assign(__assign({}, model.deliveries[0]), { contents: __assign(__assign({}, model.deliveries[0].contents), { action: 'Locked', status: 'Pending', bitnomialAddress: '098asdf0a0FG9H87GF6D5SA6789AS987SD230d9g903kkf9990302jf0w093jr0823jjf08931678kjdf12345kgmds0987hfnvos0000222117600y765tf432111sss760098098poi98jh763' }) }),
        __assign(__assign({}, model.deliveries[0]), { contents: __assign(__assign({}, model.deliveries[0].contents), { action: 'Delivered', status: 'Pending', bitnomialAddress: '2jf0w093jr0823jjf0893168asdf0a0FG9H87GF6D5SA6789AS987SD230d9g903kkf99903078kjdf12345kgmds0987hfnvos0000222117600y765tf432ss760098098poi98jh763111s' }) }),
        __assign(__assign({}, model.deliveries[1]), { contents: __assign(__assign({}, model.deliveries[1].contents), { action: 'Released', status: 'Complete', bitnomialAddress: '098asdf0a0FG9H87GF6D5SA6789AS987SD', returnAddress: '098asdf0a0FG9H87GF6D5SA6789AS987SD230d9g903kkf9990302jf0w093jr0823jjf08931678kjdf12345kgmds0987hfnvos0000222117600y765tf432111sss760098098poi98jh763' }) }),
    ];
    // Convert data to bring data to the same format for table rendering
    // TODO uncomment next line to work with real data
    // const convertedData = model.deliveries
    var convertedData = mockedData
        .map(function (item, index) {
        // @ts-ignore
        if (item.contents.received) {
            // @ts-ignore
            item.contents.amount = item.contents.received;
        }
        // @ts-ignore
        if (item.contents.delivered) {
            // @ts-ignore
            item.contents.amount = item.contents.delivered;
        }
        return __assign(__assign({}, item), { isExpand: false, index: index.toString() });
    });
    var bitnomialAddress = ((_b = model === null || model === void 0 ? void 0 : model.deliveryAddresses) === null || _b === void 0 ? void 0 : _b.takeDeliveryAddress) || null;
    var initialData = convertedData;
    var _c = useState(initialData), tableData = _c[0], setTableData = _c[1];
    // TODO all selectors in this section mocked. Need to be changed
    var searchableColumns = ['contents.action', 'contents.amount', 'contents.status'];
    var columns = [
        {
            name: _jsx(ColHeader, { title: 'Action', tooltip: "<div>\n\t\t\t\t\t<b>Action</b>\n\t\t\t\t\t<p><span class='bold'>Locked:</span> Bitcoin submitted and locked for physical delivery.</p>\n\t\t\t\t\t<p><span class='bold'>Released:</span> Excess locked bitcoin sent to return address.</p>\n\t\t\t\t\t<p><span class='bold'>Delivered:</span> Bitcoin sent to recipient during final settlement.</p>\n\t\t\t\t</div>" }, void 0),
            selector: 'contents.action',
            sortable: true,
            grow: 1.5,
            cell: function (row) { return _jsx("div", __assign({ className: 'flex align-center h-full w-full', "data-tag": 'allowRowEvents', onClick: function () { return handleRowClick(row.index); } }, { children: row.contents.action }), void 0); }
        },
        {
            name: _jsx(ColHeader, { title: 'Amount', tooltip: '<div>\n\t\t\t\t\t<b>Amount</b>\n\t\t\t\t\t<p>Number of bitcoin associated with an action.</p>\n\t\t\t\t</div>' }, void 0),
            selector: 'contents.amount',
            sortable: true,
            right: true,
            grow: 4.5,
            cell: function (row) { return (_jsxs("div", __assign({ className: 'flex flex-column justify-center align-end h-full w-full border-right border-grey amount-cell' }, { children: [_jsxs("p", __assign({ className: 'amount number' }, { children: [formatNumber(row.contents.amount), " BTC"] }), void 0),
                    row.contents.returned && _jsxs("p", __assign({ className: 'returned' }, { children: ["Returned: ", _jsx("span", __assign({ className: 'text-green' }, { children: formatNumber(row.contents.returned) }), void 0)] }), void 0)] }), void 0)); }
        },
        {
            name: _jsx(ColHeader, { title: 'Status', tooltip: '<div>\n\t\t\t\t\t<b>Status</b>\n\t\t\t\t\t<p>Short explaination Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut a nunc eget neque congue euismod eget vitae quam. Nullam suscipit ligula nec ornare varius.</p>\n\t\t\t\t</div>' }, void 0),
            selector: 'contents.status',
            sortable: true,
            right: true,
            grow: 1.5,
            cell: function (row) {
                var _a;
                var isExpand = (_a = tableData.find(function (item) { return item.index == row.index; })) === null || _a === void 0 ? void 0 : _a.isExpand;
                return _jsxs("div", __assign({ className: 'flex align-center h-full w-full justify-end', onClick: function () { return handleRowClick(row.index); } }, { children: [_jsxs("div", __assign({ className: 'flex-row' }, { children: [_jsx("img", { src: "images/icons/ellipse_" + getColor(row.contents.status) + ".svg", alt: '.' }, void 0),
                                _jsx("div", { className: 'distance-8' }, void 0),
                                _jsx("p", __assign({ className: "text-" + getColor(row.contents.status) }, { children: row.contents.status }), void 0)] }), void 0),
                        _jsx(ExpandCell, { name: 'open', chevronSize: 'big', className: 'chevron', status: isExpand ? 'open' : '' }, void 0)] }), void 0);
            }
        }
    ];
    var _d = useState(false), rowExpandedStatus = _d[0], setRowExpandedStatus = _d[1];
    var handleRowClick = function (id) {
        var row = tableData.find(function (item) { return item.index == id; });
        if (row) {
            row.isExpand = !row.isExpand;
            setRowExpandedStatus(!rowExpandedStatus);
        }
    };
    var getColor = function (text) {
        // TODO case should be changed depending on what will be returned for status section
        switch (text) {
            case 'Pending':
                return 'blue';
            case 'Complete':
                return 'green';
            default:
                return '';
        }
    };
    var onSearch = function (value) {
        setTableData(search(value, initialData, searchableColumns));
    };
    var goToConnections = function (event) {
        event.preventDefault();
        localStorage.setItem('selectedTab', 'connections');
        localStorage.setItem('selectedAccountId', model.account.accountId);
        location.reload();
    };
    return _jsx(_Fragment, { children: _jsx("div", __assign({ className: 'account-detail' }, { children: _jsxs("div", __assign({ className: 'container position-relative' }, { children: [_jsx(SummaryPanel, { firmName: 'Lorum Ipsum Business' //TODO set Clearing Firm name value
                        , participant: model.account.participant, permissions: model.account.deliveryPermission, bitnomialAddress: bitnomialAddress ? bitnomialAddress : null }, void 0),
                    _jsxs("div", __assign({ className: 'delivery-details flex justify-between' }, { children: [_jsxs("div", __assign({ className: 'flex' }, { children: [_jsxs("div", __assign({ className: 'info' }, { children: [_jsx("p", __assign({ className: 'bolder-grey-text' }, { children: "Locked for delivery" }), void 0),
                                            _jsxs("p", __assign({ className: 'locked' }, { children: [model.account.deliveryDetails && formatNumber(model.account.deliveryDetails.locked), " BTC"] }), void 0)] }), void 0),
                                    _jsxs("div", __assign({ className: 'info' }, { children: [_jsx("p", __assign({ className: 'bolder-grey-text' }, { children: "Pending for delivery" }), void 0),
                                            _jsxs("p", __assign({ className: 'pending' }, { children: [model.account.deliveryDetails && formatNumber(model.account.deliveryDetails.pending), " BTC"] }), void 0)] }), void 0)] }), void 0),
                            _jsxs("div", __assign({ className: 'flex align-center' }, { children: [_jsx(Search, { onChange: onSearch, placeholder: 'Search' }, void 0),
                                    _jsx(Button, __assign({ type: 'button', form: 'rectangular', color: 'red', onClick: goToConnections }, { children: "View connections" }), void 0)] }), void 0)] }), void 0),
                    _jsx("div", __assign({ className: 'table-wrap' }, { children: _jsx(DataTable, { columns: columns, data: tableData, sortIcon: _jsx(ArrowIcon, {}, void 0), expandableRows: true, expandableRowsComponent: _jsx(ExpandableComponent, {}, void 0), expandableRowExpanded: function (row) { return (row.isExpand); }, persistTableHead: true, noDataComponent: _jsx(NoTableData, { text: 'No actions available' }, void 0), defaultSortAsc: false }, void 0) }), void 0)] }), void 0) }), void 0) }, void 0);
};
export default AccountDetail;
