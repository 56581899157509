var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var buttonImages;
(function (buttonImages) {
    buttonImages["back_to_top"] = "back_to_top.svg";
    buttonImages["download"] = "download.svg";
    buttonImages["copy"] = "copy.svg";
})(buttonImages || (buttonImages = {}));
var Button = function (props) {
    return _jsx("button", __assign({ className: 'btn-font btn-' + props.form + ' btn-' + props.color + ' ' + props.className, onClick: props.onClick ? props.onClick : function () { }, type: props.type, disabled: props.disabled }, { children: _jsxs("div", __assign({ className: 'flex-row' }, { children: [props.image ? _jsx("img", { src: '/images/buttons/' + props.image, alt: 'back to top' }, void 0) : null, props.children] }), void 0) }), void 0);
};
export default Button;
