var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import FormHeader, { Images } from '../../form-header';
import * as Yup from 'yup';
import PasswordField from '../../fields/password-field';
import { ClipLoader } from "react-spinners";
import Button from '../../../buttons/button';
import useFormController from '../../formController';
import PasswordChange from '../password-change';
import PasswordRecovery from '../password-recovery';
import '../../forms-style.scss';
import './password-check.scss';
var validationSchema = Yup.object().shape({
    password: Yup.string().required('Enter password'),
});
var PasswordCheck = function (props) {
    var _a = useState(false), isPasswordWrong = _a[0], setPasswordWrong = _a[1];
    var formik = useFormik({
        initialValues: { password: '' },
        validationSchema: validationSchema,
        onSubmit: function (values) {
            formik.setSubmitting(true);
            setTimeout(function () {
                formik.setSubmitting(false);
                formController.success();
            }, 1000);
        },
    });
    var formController = useFormController({
        success: function () {
            setPasswordWrong(false);
            props.changeWindow(_jsx(PasswordChange, { changeWindow: props.changeWindow }, void 0));
        },
        fail: function () {
            setPasswordWrong(true);
        },
    });
    return _jsx(_Fragment, { children: _jsxs("div", __assign({ className: 'password-check adaptive-modal-window' }, { children: [_jsx(FormHeader, { title: 'password', image: Images.lock, description: 'Login below to change your password settings.' }, void 0),
                _jsx("div", __assign({ className: 'form-body' }, { children: _jsx(FormikProvider, __assign({ value: formik }, { children: _jsxs("form", __assign({ noValidate: true, className: 'password-check-form', onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: [_jsx(PasswordField, { name: 'password', label: 'Current Password', onChange: formik.handleChange, onFocus: function () {
                                        setPasswordWrong(false);
                                    }, isPasswordWrong: isPasswordWrong }, void 0),
                                _jsx("a", __assign({ onClick: function () { return props.changeWindow(_jsx(PasswordRecovery, {}, void 0)); }, target: '_blank' }, { children: "Forgot password?" }), void 0),
                                _jsxs("div", __assign({ className: 'flex-row flex-row-right password-check-btn' }, { children: [formik.isSubmitting ? _jsx(ClipLoader, { color: '#DD7168' }, void 0) : _jsx("div", {}, void 0),
                                        _jsx("div", { className: 'distance-8' }, void 0),
                                        _jsx(Button, __assign({ type: 'submit', form: 'rounded', color: 'red' }, { children: "Next" }), void 0)] }), void 0)] }), void 0) }), void 0) }), void 0)] }), void 0) }, void 0);
};
export default PasswordCheck;
