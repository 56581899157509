var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import BusinessEntity from './business-entity';
import ContactInformation from './contact-information';
import ManagedUsers from './managed-users';
import ModalWindow, { ModalWindowStatus, useModalController } from '../../forms/modal-window';
import RegistrationSubmitted from '../../forms/windows/registration-submitted';
import EntityApprovedDialog from '../../forms/windows/entity-approved-dialog';
import AddAccount from '../../forms/windows/add-account';
import './settings-main.scss';
var SettingsMain = function (_a) {
    var model = _a.model, user = _a.user, participants = _a.participants;
    var _b = useState(ModalWindowStatus.open), modalStatus = _b[0], setModalStatus = _b[1];
    var _c = useState(ModalWindowStatus.close), modalEntityApprovedStatus = _c[0], setEntityApprovedStatus = _c[1];
    var _d = useState(useModalController()), modalController = _d[0], setModalController = _d[1];
    var check = function () {
        if (localStorage.getItem('message') == 'registration-submitted') {
            return _jsx(ModalWindow, __assign({ status: modalStatus, setStatus: setModalStatus, onClose: function () {
                    localStorage.removeItem('message');
                } }, { children: _jsx(RegistrationSubmitted, {}, void 0) }), void 0);
        }
    };
    function showEntityApprovedWindow() {
        setEntityApprovedStatus(ModalWindowStatus.open);
    }
    return _jsx(_Fragment, { children: _jsx("div", __assign({ className: 'settings-main' }, { children: _jsxs("div", __assign({ className: 'container' }, { children: [_jsx(BusinessEntity, __assign({}, user), void 0),
                    _jsx(ContactInformation, __assign({}, user), void 0),
                    _jsx(ManagedUsers, __assign({}, model), void 0), check(), _jsx(ModalWindow, __assign({ status: modalEntityApprovedStatus, setStatus: setEntityApprovedStatus, setModalController: setModalController, isNoScrollable: true }, { children: _jsx(EntityApprovedDialog, { yesClick: function () {
                                modalController.setContent(_jsx(AddAccount, { accountIds: [], participants: participants, modalController: modalController }, void 0));
                            }, noClick: modalController.startClosing }, void 0) }), void 0)] }), void 0) }), void 0) }, void 0);
};
export default SettingsMain;
