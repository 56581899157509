var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import ReactTooltip from 'react-tooltip';
import { Search } from '../../forms/fields/search-input';
import { ArrowIcon } from '../../shared/arrow-icon/arrow-icon';
import { ColHeader } from '../../shared/col-header/col-header';
import { NoTableData } from '../../shared/no-table-data/no-table-data';
import { ConnectBtnGroup } from '../../shared/connect-btn-group/connect-btn-group';
import Button from '../../buttons/button';
import ModalWindow, { ModalDialog, ModalWindowStatus, useModalController } from '../../forms/modal-window';
import AddAccount from '../../forms/windows/add-account';
import { CancelBtn } from '../../shared/cancel-button/cancel-button';
import { search } from '../../../utils/search';
import { SelectedCount } from '../../shared/selected-count/selected-count';
import { AppContext } from '../../../index';
import { camelCaseToDifferentWords, emulateRequest } from '../../../utils/helpers';
import { OrderCell } from '../../shared/order-cell/order-cell';
import tablePropsForCheckbox from '../../table-checkbox';
import AreYouSureDialog from '../../forms/windows/are-you-sure-dialog';
import Pagination from '../pagination';
import '../../../scss/table.scss';
export var conditionalRowStyles = [
    // TODO change 'orderStatus' field to relevant values
    {
        when: function (row) {
            return row.orderStatus !== 'canceled';
        },
        style: function (row) {
            return ({
                '&:hover .order-cell .orders-btn': {
                    opacity: 1,
                    zIndex: 2
                },
                '&:hover .order-cell .orders-text': {
                    opacity: 0,
                }
            });
        },
    }
];
export var AccountsTab = function (_a) {
    var data = _a.data, user = _a.user, participants = _a.participants;
    var mockedData = [
        {
            "deliveryDetails": null,
            "accountId": "BITNOMIALUATCLEAR 2",
            "deliveryPermission": camelCaseToDifferentWords("NoDelivery"),
            "participant": "Bitnomial Prop Trader",
            "id": 1,
            "orderCount": 3,
        },
        {
            "deliveryDetails": null,
            "accountId": "BITNOMIALUATCLEAR",
            "deliveryPermission": camelCaseToDifferentWords("NoDelivery"),
            "participant": "Bitnomial Prop Trader",
            "id": 2,
            "orderCount": 0
        },
        {
            "deliveryDetails": null,
            "accountId": "BITNOMIALUATCLEAR",
            "deliveryPermission": camelCaseToDifferentWords("NoDelivery"),
            "participant": "Bitnomial Prop Trader",
            "id": 3,
            "orderCount": 0,
            "orderStatus": 'canceled'
        }
    ];
    var initialData = mockedData;
    // TODO uncomment for real data
    // const initialData = data
    var _b = useState(initialData), tableData = _b[0], setTableData = _b[1];
    var _c = useState(false), toggledClearRows = _c[0], setToggledClearRows = _c[1];
    var _d = useState([]), selectedRows = _d[0], setSelectedRows = _d[1];
    var _e = useState(ModalWindowStatus.close), modalStatus = _e[0], setModalStatus = _e[1];
    var _f = useState(useModalController()), modalController = _f[0], setModalController = _f[1];
    // are you sure cancel modal
    var _g = useState(ModalWindowStatus.close), modalCancel = _g[0], setModalCancel = _g[1];
    var _h = useState(useModalController()), modalCancelController = _h[0], setModalCancelController = _h[1];
    var _j = useState(), cancelRow = _j[0], setCancelRow = _j[1];
    var context = useContext(AppContext);
    // TODO need to be changed to determine if user created account
    var hasAccountAccess = localStorage.getItem('hasAccountAccess') === 'ture';
    useEffect(function () {
        ReactTooltip.rebuild();
    }, []);
    var searchableColumns = ['accountId', 'participant'];
    var columns = [
        {
            name: _jsx(ColHeader, { title: 'Account ID', tooltip: '<div>\n\t\t\t\t\t<b>Account ID</b>\n\t\t\t\t\t<p>Identifier of brokered Account.</p>\n\t\t\t\t</div>' }, void 0),
            selector: 'accountId',
            sortable: true,
            width: '20%',
            cell: function (row) { return _jsx("div", __assign({ className: 'flex align-center h-full w-full' }, { children: _jsx("a", __assign({ href: '#', className: 'email', onClick: goToAccount.bind(null, row.accountId) }, { children: row.accountId }), void 0) }), void 0); },
        },
        {
            name: _jsx(ColHeader, { title: 'Participant', tooltip: '<div>\n\t\t\t\t\t<b>Participant</b>\n\t\t\t\t\t<p>Organization associated with brokered Account.</p>\n\t\t\t\t</div>' }, void 0),
            selector: 'participant',
            sortable: true,
            grow: 2
        },
        {
            name: _jsx(ColHeader, { title: 'Delivery Status', tooltip: '<div>\n\t\t\t\t\t<b>Delivery Status</b>\n\t\t\t\t\t<p>Short explaination Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut a nunc eget neque congue euismod eget vitae quam. Nullam suscipit ligula nec ornare varius.</p>\n\t\t\t\t</div>' }, void 0),
            // TODO change to corresponding field
            selector: 'deliveryPermission',
            sortable: true,
            grow: 3,
            right: true,
            cell: function (row) { return _jsx("div", __assign({ className: 'w-full' }, { children: _jsx("p", __assign({ className: 'pull-right' }, { children: row.deliveryPermission }), void 0) }), void 0); }
        },
        {
            name: _jsx(ColHeader, { title: 'Orders', tooltip: '<div>\n\t\t\t\t\t<b>Orders</b>\n\t\t\t\t\t<p>Number of active open orders on the exchange.</p>\n\t\t\t\t</div>' }, void 0),
            // TODO change to corresponding filed
            selector: 'orderCount',
            sortable: true,
            grow: 1,
            right: true,
            cell: function (row) { return _jsx("div", __assign({ className: 'flex align-center h-full w-full justify-end order-cell' }, { children: _jsx(OrderCell, { row: row, handleOrderCancel: handleOrderCancel.bind(null, row), cellName: 'orderCount' }, void 0) }), void 0); }
        }
    ];
    var handleOnChange = function (value) {
        // console.log('value', value)
    };
    var handleChange = function (state) {
        // You can use setState or dispatch with something like Redux so we can use the retrieved data
        setSelectedRows(state.selectedRows);
    };
    var handleCreateAccount = function () {
        setModalStatus(ModalWindowStatus.open);
    };
    var convertAccountsToString = function () {
        var values = [];
        data.forEach(function (value) {
            values.push(value.accountId);
        });
        return values;
    };
    var handleDisabled = function (row) {
        // TODO change the statement to return true value if approval required
        return row.orderStatus === 'canceled';
    };
    var goToAccount = function (accountId) {
        localStorage.setItem('selectedTab', 'account-detail');
        localStorage.setItem('accountId', accountId);
        location.reload();
    };
    var onSearch = function (value) {
        setTableData(search(value, initialData, searchableColumns));
    };
    var handleOrderCancel = function (row) {
        if (row && Object.keys(row).length > 0) {
            setCancelRow(row);
            // TODO request here and update the table
        }
        else {
            modalCancelController.setModalDialog(ModalDialog.small);
            modalCancelController.setShowBtnClose(false);
            setModalCancel(ModalWindowStatus.open);
        }
    };
    var onConfirmCancel = function () {
        setModalCancel(ModalWindowStatus.close);
        if (selectedRows.length) {
            // TODO handle multiple orders cancel
        }
        else {
            // TODO handle single order cancel. Row data stored in 'cancelRow' variable
        }
        emulateRequest(context);
    };
    var onDeclineCancel = function () {
        setModalCancel(ModalWindowStatus.close);
    };
    var NoDataComponent = function () { return (_jsx(NoTableData, { children: hasAccountAccess
            ? _jsxs("div", __assign({ className: 'flex flex-column align-center' }, { children: [_jsx("p", __assign({ className: 'grey-text' }, { children: "Don\u2019t see anything? Try creating an account." }), void 0),
                    _jsx(Button, __assign({ type: 'submit', color: 'white', form: 'rectangular', className: 'no-data-btn', onClick: handleCreateAccount }, { children: "Add Account" }), void 0)] }), void 0)
            : (initialData.length > 0 ?
                _jsxs("div", __assign({ className: 'flex flex-column align-center' }, { children: [_jsx("p", __assign({ className: 'grey-text' }, { children: "No accounts with that name" }), void 0),
                        _jsx(Button, __assign({ type: 'submit', color: 'white', form: 'rectangular', className: 'no-data-btn', onClick: handleCreateAccount }, { children: "Add Account" }), void 0)] }), void 0) :
                _jsxs("div", { children: [_jsx("p", { children: "Create or join an entity to see Accounts" }, void 0),
                        _jsx(ConnectBtnGroup, { props: user }, void 0)] }, void 0)) }, void 0)); };
    return (_jsxs("div", { children: [_jsxs("div", __assign({ className: "flex justify-between scrollable" }, { children: [_jsxs("div", __assign({ className: 'flex align-center' }, { children: [_jsx(Search, { onChange: onSearch, placeholder: 'Search' }, void 0),
                            selectedRows.length > 0 &&
                                _jsxs("div", __assign({ className: 'flex align-center next-to-search-container' }, { children: [_jsx(CancelBtn, { active: true, onClick: handleOrderCancel.bind(null, {}) }, void 0),
                                        _jsx(SelectedCount, { count: selectedRows.length }, void 0)] }), void 0)] }), void 0),
                    _jsx(Button, __assign({ type: 'submit', color: 'red', form: 'rectangular', onClick: handleCreateAccount }, { children: "Add Account" }), void 0)] }), void 0),
            _jsx("div", __assign({ className: 'table-wrap' }, { children: _jsx(DataTable, { columns: columns, data: tableData, selectableRows // add for checkbox selection
                    : true, onSelectedRowsChange: handleChange, clearSelectedRows: toggledClearRows, selectableRowDisabled: handleDisabled, sortIcon: _jsx(ArrowIcon, {}, void 0), selectableRowsHighlight: true, persistTableHead: true, noDataComponent: _jsx(NoDataComponent, {}, void 0), conditionalRowStyles: conditionalRowStyles, defaultSortAsc: false, pagination: true, paginationComponent: Pagination, paginationPerPage: 15, paginationRowsPerPageOptions: [15, 25, 50, 100, 200], 
                    // @ts-ignore
                    selectableRowsComponent: tablePropsForCheckbox.selectableRowsComponent, selectableRowsComponentProps: tablePropsForCheckbox.selectableRowsComponentProps }, void 0) }), void 0),
            _jsx(ModalWindow, __assign({ status: modalStatus, setStatus: function (status) { return setModalStatus(status); }, isNoScrollable: true, setModalController: setModalController }, { children: _jsx(AddAccount, { accountIds: convertAccountsToString(), modalController: modalController, participants: participants }, void 0) }), void 0),
            _jsx(ModalWindow, __assign({ status: modalCancel, setStatus: function (status) { return setModalCancel(status); }, isNoScrollable: true, setModalController: setModalCancelController }, { children: _jsx(AreYouSureDialog, { amountOfSelectedItems: selectedRows.length, yesClick: onConfirmCancel, noClick: onDeclineCancel }, void 0) }), void 0)] }, void 0));
};
