var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import PhotoField from '../../forms/fields/photo-field';
import OptionField from '../../forms/fields/option-field';
import { Form, Formik } from 'formik';
import TextAreaField from '../../forms/fields/text-area-field';
import TextField from '../../forms/fields/text-field';
import * as Yup from 'yup';
import Button, { buttonImages } from '../../buttons/button';
import { ClipLoader } from 'react-spinners';
import './entity-registration.scss';
var validationSchema = Yup.object().shape({
    organizational_documents: Yup.string().required('Enter first name'),
    list_owners: Yup.string().required('Field empty'),
    affiliate_organization_chart: Yup.string().required('Enter first name'),
    corporate_organization_chart: Yup.string().required('Enter first name'),
    disciplinary_information: Yup.string().required('Field empty'),
    name_FCM_or_MGEX: Yup.string().required('Field empty'),
    financial_information: Yup.string().required('Enter first name'),
    role: Yup.string().required('No role selected'),
});
export var Block = function (props) {
    return _jsxs("div", __assign({ className: 'entity-registration-block' }, { children: [props.children, _jsx("span", __assign({ className: "number " + (props.isError ? 'number-error' : '') }, { children: props.number }), void 0),
            !props.withoutHR ? _jsx("hr", { className: props.hrMarginTop ? 'margin-top' : '' }, void 0) : null] }), void 0);
};
var EntityRegistration = function (_a) {
    var createEntity = _a.createEntity;
    var _b = useState(false), isSending = _b[0], setSending = _b[1];
    var entityData = createEntity;
    var isEditing = Object.keys(entityData.contents).length !== 0; // TODO check is editing, maybe with real data there should be another way
    var send = function (values, submitFrom) {
        setSending(true);
        setTimeout(function () {
            setSending(false);
            success();
            submitFrom();
        }, 1000);
    };
    function success() {
        localStorage.setItem('selectedTab', 'settings');
        window.scrollTo({
            top: 0,
            behavior: 'auto'
        });
        localStorage.setItem('message', 'registration-submitted');
        location.reload();
    }
    var timeoutId;
    var checkDataChanges = function (initialValues, newValues) {
        return Object.values(newValues)
            .some(function (value, index) { return value !== Object.values(initialValues)[index]; });
    };
    return _jsx(_Fragment, { children: _jsxs("div", __assign({ className: 'entity-registration' }, { children: [_jsxs("div", __assign({ className: 'entity-registration-title container' }, { children: [_jsx("span", { children: "Entity Registration" }, void 0),
                        _jsx("p", { children: "Use the form below to register your Business Entity." }, void 0)] }), void 0),
                _jsx("div", __assign({ className: 'entity-registration-body' }, { children: _jsx("div", __assign({ className: 'container' }, { children: _jsx("div", __assign({ className: 'entity-registration-form' }, { children: _jsx(Formik, __assign({ initialValues: {
                                    organizational_documents: entityData.contents.orgDocuments,
                                    list_owners: entityData.contents.beneficialOwners,
                                    affiliate_organization_chart: entityData.contents.affiliateOrgChart,
                                    corporate_organization_chart: entityData.contents.officerOrgChart,
                                    disciplinary_information: entityData.contents.disclosures,
                                    name_FCM_or_MGEX: entityData.contents.clearingMember,
                                    financial_information: entityData.contents.finances,
                                    role: entityData.contents.customerType,
                                    option_information: entityData.contents.agreement
                                }, validationSchema: validationSchema, onSubmit: function (values, actions) {
                                    window.scrollTo({
                                        top: 0,
                                        behavior: 'smooth'
                                    });
                                    send(values, function () { return actions.setSubmitting(false); });
                                } }, { children: function (_a) {
                                    var errors = _a.errors, touched = _a.touched, values = _a.values, initialValues = _a.initialValues;
                                    // start of debounce
                                    // we need this value for custom check of data on "back" button
                                    clearTimeout(timeoutId);
                                    timeoutId = window.setTimeout(function () {
                                        sessionStorage.setItem('isEntityRegistrationChanged', JSON.stringify(Object.keys(touched).length === 0));
                                    }, 300);
                                    // finish of debounce
                                    return (_jsxs(Form, { children: [_jsx(Block, __assign({ number: 1, isError: !!errors['organizational_documents'] }, { children: _jsx(PhotoField, { name: 'organizational_documents', text: 'Entity organizational documents (Certificate of Incorporation, Partnership Agreement, LLC Operating Agreement, or equivalent documents) ' }, void 0) }), void 0),
                                            _jsxs(Block, __assign({ number: 2, isError: !!errors['list_owners'], hrMarginTop: true }, { children: [_jsxs("p", { children: ["List of Entity beneficial owners (", '>', "10% ownership)"] }, void 0),
                                                    _jsx(TextAreaField, { name: 'list_owners', error: errors.list_owners, touched: touched.list_owners, label: '', placeholder: 'String input' }, void 0)] }), void 0),
                                            _jsx(Block, __assign({ number: 3, isError: !!errors['affiliate_organization_chart'] }, { children: _jsx(PhotoField, { name: 'affiliate_organization_chart', text: 'Affiliate entity organization chart' }, void 0) }), void 0),
                                            _jsx(Block, __assign({ number: 4, isError: !!errors['corporate_organization_chart'] }, { children: _jsx(PhotoField, { name: 'corporate_organization_chart', text: 'Corporate officer organization chart' }, void 0) }), void 0),
                                            _jsxs(Block, __assign({ number: 5, isError: !!errors['disciplinary_information'], hrMarginTop: true }, { children: [_jsx("p", { children: "Disciplinary information for criminal and regulatory disclosures of key employees or Entity and affiliates " }, void 0),
                                                    _jsx(TextAreaField, { name: 'disciplinary_information', error: errors.disciplinary_information, touched: touched.disciplinary_information, label: '', placeholder: 'Disciplinary information' }, void 0)] }), void 0),
                                            _jsxs(Block, __assign({ number: 6, isError: !!errors['name_FCM_or_MGEX'], hrMarginTop: true }, { children: [_jsx("p", { children: "Name of FCM or MGEX Clearing Member where Participant Exchange trades will be executed" }, void 0),
                                                    _jsx(TextField, { name: 'name_FCM_or_MGEX', label: '', background_color: 'white', placeholder: 'Name of clearing member' }, void 0)] }), void 0),
                                            _jsx(Block, __assign({ number: 7, isError: !!errors['financial_information'] }, { children: _jsx(PhotoField, { name: 'financial_information', text: 'Entity financial information' }, void 0) }), void 0),
                                            _jsxs(Block, __assign({ number: 8, withoutHR: true }, { children: [_jsx("p", { children: "What role best describes your entity?" }, void 0),
                                                    _jsx(OptionField, { name: 'role', showOptionalInfo: !!entityData.contents.agreement }, void 0)] }), void 0),
                                            _jsxs("div", __assign({ className: 'entity-registration-footer flex-row' }, { children: [_jsxs("div", __assign({ className: 'flex-row' }, { children: [_jsx(Button, __assign({ type: 'submit', form: 'rounded', color: 'red', disabled: !checkDataChanges(initialValues, values) }, { children: isEditing ? 'Save Changes' : 'Register' }), void 0),
                                                            _jsx("div", { className: 'distance-16' }, void 0),
                                                            isSending ? _jsx(ClipLoader, { color: '#DD7168' }, void 0) : _jsx("div", {}, void 0)] }), void 0),
                                                    _jsx(Button, __assign({ onClick: function () {
                                                            window.scrollTo({
                                                                top: 0,
                                                                behavior: 'smooth'
                                                            });
                                                        }, className: 'reset-focus', type: 'button', form: 'rectangular', color: 'riverbed', image: buttonImages.back_to_top }, { children: "Back to top" }), void 0)] }), void 0)] }, void 0));
                                } }), void 0) }), void 0) }), void 0) }), void 0)] }), void 0) }, void 0);
};
export default EntityRegistration;
