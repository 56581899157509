export var model = {
    "user": {
        "email": "admin@bitnomial.com",
        "roles": [
            "Administrator",
            "BackOfficeManager"
        ],
        "interfaceConfig": {
            "showTutorial": true,
            "lastPasswordChange": "2021-02-16T16:34:09.429693Z"
        },
        "details": {
            "lastName": "User",
            "phoneNumber": "88888888888",
            "firstName": "Bitnomial",
            "approved": true,
            "photoId": "/entities/1/users/1/photo_id.jpg"
        },
        "id": 1,
        "organizationDetails": {
            "name": "Bitnomial FCM",
            "id": 1,
            "lei": "BTNLFCM",
            "approvalStatus": "Approved"
        }
    },
    "model": {
        "deliveryAddresses": {
            "makeDeliveryAddress": "bcrt1q4l5wdq44hguh00r4sjuwcd4wvgczpv67z0ckdc",
            "takeDeliveryAddress": "bcrt1qe7k8jltj3gldxcfhykheppd7pylhgz379na0wt"
        },
        "maxOrderSize": {
            "RUB01": 100,
            "BUSOH1C6500": 100,
            "BUSOH1P7500": 100,
            "BUSOG8P10500": 100,
            "BUSOG8C10500": 100,
            "BUSG8": 100,
            "RBU30": 100,
            "RBU01": 100,
            "BUSOH1P7000": 100,
            "BUSH1": 100,
            "BUSH1-BUSM1": 100,
            "BUSM1": 100,
            "BUSOH1C7500": 100,
            "RUB30": 100,
            "BUSOH1C7000": 100,
            "BUSOH1P6500": 100
        },
        "account": {
            "smpAccounts": [2, 3],
            "maxOrderOverrides": {
                "BUSH1": 100
            },
            "deliveryDetails": {
                "pending": "100000000",
                "locked": "300000000"
            },
            "accountId": "BITNOMIALUATCLEAR",
            "deliveryPermission": "Deliverable",
            "participant": "Bitnomial Prop Trader",
            "id": 1
        },
        "deliveries": [
            {
                "tag": "DeliveryAsShort",
                "contents": {
                    "received": "100000000",
                    "shortTxid": "c245218c016e610b1bd8d9f0558170c224a3710bb7a4a7f649fbc4b23922973d"
                }
            },
            {
                "tag": "DeliveryAsLong",
                "contents": {
                    "delivered": "200000000",
                    "returned": "50000000",
                    "longTxid": "7935b8184fb9aa646a1991f3433a070a5109622e5e4436122ed389179c6a347f"
                }
            }
        ]
    },
    "selectedTab": "account-detail"
};
