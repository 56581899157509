var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FormHeader, { Images } from '../../form-header';
import Button from '../../../buttons/button';
import './change-address.scss';
var ChangeAddress = function (props) {
    return _jsxs("div", __assign({ className: 'change-address' }, { children: [_jsx(FormHeader, { image: Images.settings, title: 'Change Address', withoutMarginBottom: true }, void 0),
            _jsx("p", { children: "Want to change your Take Address? Contact us below to edit your address information." }, void 0),
            _jsx(Button, __assign({ type: 'submit', form: 'rounded', color: 'red' }, { children: "Contact Us" }), void 0)] }), void 0);
};
export default ChangeAddress;
