var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Heading from '../../header/heading';
import PasswordChange from '../../forms/windows/password-change';
import { mediumRegex } from "../../forms/fields/password-field";
import * as Yup from 'yup';
import './sign-up.scss';
var SignUp = function () {
    var changeWindow = function () {
        localStorage.setItem('selectedTab', 'settings');
        location.reload();
    };
    var _a = useState(''), value = _a[0], setValue = _a[1];
    var getScheme = function (value) {
        var val = value ? 'Re-enter your password to confirm' : '';
        return Yup.object().shape({
            password: Yup.string().required('Field empty').matches(mediumRegex, 'Password is too weak'),
            confirm: Yup.string().required(val).oneOf([Yup.ref('password'), null], val),
        });
    };
    return (_jsx("div", __assign({ className: 'sign-up' }, { children: _jsxs("div", __assign({ className: 'container' }, { children: [_jsx("header", __assign({ className: 'header-overlay' }, { children: _jsx(Heading, { signUp: true, dark: true }, void 0) }), void 0),
                _jsxs("div", __assign({ className: 'section' }, { children: [_jsx("span", { children: "Sign Up" }, void 0),
                        _jsx("div", __assign({ className: 'img-box' }, { children: _jsx("img", { src: '/images/icons/arrow_right.svg', alt: 'arrow right' }, void 0) }), void 0),
                        _jsx("span", __assign({ className: 'bold' }, { children: "Password" }), void 0)] }), void 0),
                _jsx("div", __assign({ className: 'form-box' }, { children: _jsx("div", __assign({ className: 'inner' }, { children: _jsx(PasswordChange, { changeWindow: changeWindow, btnText: 'create profile', validationScheme: getScheme(value), getPassword: setValue, custom: true }, void 0) }), void 0) }), void 0)] }), void 0) }), void 0));
};
export default SignUp;
