export var model = {
    "user": {
        "email": "admin@bitnomial.com",
        "roles": [
            "Administrator",
            "BackOfficeManager"
        ],
        "interfaceConfig": {
            "showTutorial": false,
            "lastPasswordChange": "2021-02-08T19:59:52.374831Z"
        },
        "details": {
            "lastName": "User",
            "phoneNumber": "88888888888",
            "firstName": "Bitnomial",
            "approved": true,
            "photoId": "/entities/1/users/1/photo_id.jpg"
        },
        "id": 1,
        "organizationDetails": {
            "name": "Bitnomial FCM",
            "id": 1,
            "lei": "BTNLFCM",
            "approvalStatus": "Approved"
        }
    },
    "model": {
        "maxOrderSize": {
            "RUB01": 100,
            "BUSOH1C6500": 100,
            "BUSOH1P7500": 100,
            "BUSOG8P10500": 100,
            "BUSOG8C10500": 100,
            "BUSG8": 100,
            "RBU30": 100,
            "RBU01": 100,
            "BUSOH1P7000": 100,
            "BUSH1": 100,
            "BUSH1-BUSM1": 100,
            "BUSM1": 100,
            "BUSOH1C7500": 100,
            "RUB30": 100,
            "BUSOH1C7000": 100,
            "BUSOH1P6500": 100
        },
        "accounts": [
            {
                "smpAccounts": [2, 3],
                "maxOrderOverrides": {
                    "BUSH1": 100
                },
                "deliveryDetails": null,
                "accountId": "BITNOMIALUATCLEAR",
                "deliveryPermission": "NoDelivery",
                "participant": "Bitnomial Prop Trader",
                "id": 1
            }
        ],
        "participants": [
            {
                "name": "Bitnomial Prop Trader",
                "id": 2
            },
            {
                "name": "FCM A",
                "id": 3
            },
            {
                "name": "FCM B",
                "id": 4
            },
            {
                "name": "Prop Trader A",
                "id": 5
            },
            {
                "name": "Prop Trader B",
                "id": 6
            }
        ],
        "approvals": [
            {
                "account": "BITNOMIALUATCLEAR",
                "accountId": 1,
                "participant": {
                    "name": "Bitnomial Prop Trader",
                    "id": 2
                }
            }
        ]
    },
    "selectedTab": "accounts-overview"
};
