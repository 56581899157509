var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import ReactTooltip from 'react-tooltip';
import './bottom-tooltip.scss';
export var BottomTooltip = function (_a) {
    var children = _a.children;
    return _jsx(ReactTooltip, __assign({ id: 'bottom', className: 'bottom-tooltip', place: 'bottom', type: 'light', effect: 'solid', html: !!children }, { children: children && children }), void 0);
};
