var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import Button from '../../../buttons/button';
import { TutorialContext } from '../../../../';
import './tutorial.scss';
var Tutorial = function (props) {
    var _a = useState(false), leftActive = _a[0], setLeftActive = _a[1];
    var _b = useState(false), rightActive = _b[0], setRightActive = _b[1];
    var _c = useContext(TutorialContext), skip = _c.skip, skipTips = _c.skipTips;
    var slideLeft = function () {
        props.decreaseStep();
        if (props.step > 0) {
            if (props.step === 1) {
                window.scrollTo(0, 0);
            }
            {
                window.scrollTo(0, props.positions[props.step - 1] - 75);
            }
        }
    };
    var slideRight = function () {
        props.increaseStep();
        if (props.step < 3) { // TODO maximum amount of steps
            window.scrollTo(0, props.positions[props.step + 1] - 75);
        }
    };
    if (localStorage.getItem('showTutorial') ||
        skip ||
        localStorage.getItem('selectedTab') !== 'settings') {
        return null;
    }
    return (_jsxs("div", __assign({ className: "tooltipCustom settings-tooltip " + (props.first ? 'first' : '') + " " + (props.left ? 'left' : '') + " step-" + props.step }, { children: [_jsx("h5", { children: props.title }, void 0),
            _jsx("p", { children: props.children }, void 0),
            _jsxs("div", __assign({ className: 'tools' }, { children: [_jsxs("div", __assign({ className: 'arrow-box' }, { children: [_jsx("div", __assign({ onMouseOver: function () { return setLeftActive(true); }, onMouseLeave: function () { return setLeftActive(false); }, onClick: slideLeft }, { children: _jsx("img", { src: '/images/icons/left.svg', alt: 'previous', className: "\n                                left " + ((leftActive || props.leftActive) ? 'active' : '') + "\n                            " }, void 0) }), void 0),
                            _jsx("div", __assign({ onMouseOver: function () { return setRightActive(true); }, onMouseLeave: function () { return setRightActive(false); }, onClick: slideRight }, { children: _jsx("img", { src: '/images/icons/right.svg', alt: 'next', className: "right " + ((rightActive || props.rightActive) ? 'active' : '') }, void 0) }), void 0)] }), void 0),
                    _jsx(Button, __assign({ type: 'button', form: 'rectangular', color: 'white', onClick: skipTips }, { children: props.step < 3 ? 'Skip Tips' : 'Close' }), void 0)] }), void 0)] }), void 0));
};
export default Tutorial;
