var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CancelBtn } from '../cancel-button/cancel-button';
import './order-cell.scss';
export var OrderCell = function (_a) {
    var row = _a.row, handleOrderCancel = _a.handleOrderCancel, cellName = _a.cellName, className = _a.className;
    var formatNumber = function (number) {
        var stringNumber = String(number);
        return stringNumber.length === 1 ? "0" + stringNumber : stringNumber;
    };
    // TODO change equality to handle if order already canceled to show 'Canceled' text
    // TODO replace 'orderStatus' and 'orderCount' with corresponding fileds
    if (row.orderStatus !== 'canceled') {
        return _jsxs("div", { children: [_jsx("p", __assign({ className: "orders-text" }, { children: formatNumber(row[cellName]) }), void 0),
                _jsx("div", __assign({ className: "orders-btn flex align-center" }, { children: _jsx(CancelBtn, { active: row[cellName] > 0, disabled: row[cellName] === 0, onClick: handleOrderCancel.bind(null, row), className: className }, void 0) }), void 0)] }, void 0);
    }
    else {
        return _jsx("div", __assign({ className: "w-full h-full orders-canceled flex justify-end align-center" }, { children: _jsx("p", { children: "Canceled" }, void 0) }), void 0);
    }
};
