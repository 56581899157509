var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useField } from 'formik';
import { formatter, pasteFormatter } from './formatters';
import '../form-field.scss';
import './phone-field.scss';
var PhoneField = function (props) {
    var _a = useField({
        name: props.name,
        onChange: props.onChange,
    }), field = _a[0], meta = _a[1], helpers = _a[2];
    useEffect(function () {
        helpers.setValue(pasteFormatter(field.value).trim());
    }, []);
    var printNum = function (e) {
        helpers.setValue(formatter(e.target.value));
    };
    var pasteNum = function (e) {
        e.preventDefault();
        helpers.setValue(pasteFormatter(e.clipboardData.getData('text/plain')).trim());
    };
    return _jsx(_Fragment, { children: _jsxs("div", __assign({ className: 'form-field' }, { children: [_jsx("label", __assign({ htmlFor: props.name }, { children: "Phone Number" }), void 0),
                _jsx("input", __assign({}, field, { onChange: printNum, onPaste: pasteNum, placeholder: '(000) 000-0000' }), void 0),
                _jsx("div", __assign({ className: 'error' }, { children: meta.error && meta.touched ? meta.error : '' }), void 0)] }), void 0) }, void 0);
};
export default PhoneField;
