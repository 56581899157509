var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { Search } from '../../forms/fields/search-input';
import DataTable from 'react-data-table-component';
import { ArrowIcon } from '../../shared/arrow-icon/arrow-icon';
import { ColHeader } from '../../shared/col-header/col-header';
import ReactTooltip from 'react-tooltip';
import { NoTableData } from '../../shared/no-table-data/no-table-data';
import { ConnectBtnGroup } from '../../shared/connect-btn-group/connect-btn-group';
import Button from '../../buttons/button';
import { ApproveBtnGroup, ApproveCell } from '../../shared/approve-cell/approve-cell';
import ModalWindow, { ModalWindowStatus, useModalController } from '../../forms/modal-window';
import AddAccount from '../../forms/windows/add-account';
import { SelectedCount } from '../../shared/selected-count/selected-count';
import { search } from '../../../utils/search';
import { AppContext } from '../../../index';
import tablePropsForCheckbox from '../../table-checkbox';
import Pagination from '../pagination';
var conditionalRowStyles = [
    // TODO change status field and values to relevant values
    {
        when: function (row) {
            return row.participant.status !== 'none';
        },
        style: function (row) {
            return ({
                backgroundColor: row.participant.status === 'approved'
                    ? 'rgba(78, 181, 156, 0.08)'
                    : 'rgba(224, 86, 86, 0.08)',
            });
        },
    },
    {
        when: function (row) {
            return row.participant.status === 'none';
        },
        style: function (row) {
            return ({
                backgroundColor: 'transparent',
                '&:hover .approve-cell .btn-group': {
                    zIndex: 2,
                    opacity: 1
                }
            });
        },
    },
];
export var ApprovalsTab = function (_a) {
    var data = _a.data, user = _a.user, participants = _a.participants;
    // Mock data
    var mockedData = [
        {
            "account": "BITNOMIALUATCLEAR",
            "accountId": 1,
            "participant": {
                "name": "Bitnomial Prop Trader",
                "id": 2,
                "status": 'none'
            }
        },
        {
            "account": "CLEAR",
            "accountId": 3,
            "participant": {
                "name": "Bitnomial Prop Trader",
                "id": 4,
                "status": 'approved'
            }
        },
        {
            "account": "BITCLEAR",
            "accountId": 4,
            "participant": {
                "name": "Bitnomial Prop Trader",
                "id": 5,
                "status": 'declined'
            }
        }
    ];
    var initialData = mockedData;
    // TODO uncomment for real data
    // const initialData = data
    var _b = useState(initialData), tableData = _b[0], setTableData = _b[1];
    // TODO need to be changed to determine if user created account
    var _c = useState(false), toggledClearRows = _c[0], setToggledClearRows = _c[1];
    var _d = useState([]), selectedRows = _d[0], setSelectedRows = _d[1];
    var _e = useState(ModalWindowStatus.close), modalStatus = _e[0], setModalStatus = _e[1];
    var _f = useState(useModalController()), modalController = _f[0], setModalController = _f[1];
    var context = useContext(AppContext);
    var hasAccountAccess = localStorage.getItem('hasAccountAccess') === 'ture';
    useEffect(function () {
        ReactTooltip.rebuild();
    }, []);
    var searchableColumns = ['account', 'participant.name'];
    var columns = [
        {
            name: _jsx(ColHeader, { title: 'Account ID', tooltip: '<div>\n\t\t\t\t\t<b>Account ID</b>\n\t\t\t\t\t<p>Identifier of brokered Account.</p>\n\t\t\t\t</div>' }, void 0),
            selector: 'account',
            sortable: true,
            width: '20%',
            cell: function (row) { return _jsx("div", __assign({ className: 'w-full' }, { children: row.account }), void 0); }
        },
        {
            name: _jsx(ColHeader, { title: 'Participant', tooltip: '<div>\n\t\t\t\t\t<b>Participant</b>\n\t\t\t\t\t<p>Organization associated with brokered Account.</p>\n\t\t\t\t</div>' }, void 0),
            selector: 'participant.name',
            sortable: true,
            grow: 3,
            cell: function (row) { return (_jsx(ApproveCell
            // TODO change this field name to relevant
            , { 
                // TODO change this field name to relevant
                currentStatus: row.participant.status, onStatusChange: handleStatusChange, row: row, cellName: 'participant.name' }, void 0)); }
        }
    ];
    var onSearch = function (value) {
        setTableData(search(value, initialData, searchableColumns));
    };
    var handleChange = function (state) {
        // You can use setState or dispatch with something like Redux so we can use the retrieved data
        setSelectedRows(state.selectedRows);
    };
    var handleClearRows = function () {
        setToggledClearRows(!toggledClearRows);
    };
    var handleMultipleStatusChange = function (status) {
        if (selectedRows.length > 0) {
            selectedRows.forEach(function (row) {
                handleStatusChange(row, status);
            });
        }
    };
    var handleStatusChange = function (row, status) {
        // TODO handle Approve or Decline
    };
    var handleCreateAccount = function () {
        setModalStatus(ModalWindowStatus.open);
    };
    var handleDisabled = function (row) {
        // TODO change the statement to return true value if approval required
        return row.participant.status !== 'none';
    };
    var NoDataComponent = function () {
        if (user && user.organizationDetails && user.organizationDetails.lei) {
            return (_jsx(NoTableData, { children: hasAccountAccess
                    ? _jsx("div", __assign({ className: 'flex flex-column align-center' }, { children: _jsx("p", __assign({ className: 'grey-text' }, { children: "No pending approvals" }), void 0) }), void 0)
                    : _jsxs("div", __assign({ className: 'flex flex-column align-center' }, { children: [_jsx("p", __assign({ className: 'grey-text' }, { children: "No accounts with that name" }), void 0),
                            _jsx(Button, __assign({ type: 'submit', color: 'white', form: 'rectangular', className: 'no-data-btn', onClick: handleCreateAccount }, { children: "Add Account" }), void 0)] }), void 0) }, void 0));
        }
        else {
            return (_jsx(NoTableData, { children: hasAccountAccess
                    ? _jsx("div", __assign({ className: 'flex flex-column align-center' }, { children: _jsx("p", __assign({ className: 'grey-text' }, { children: "No pending approvals" }), void 0) }), void 0)
                    : _jsxs("div", { children: [_jsx("p", __assign({ className: 'grey-text' }, { children: "Create or join an entity to see Approvals" }), void 0),
                            _jsx(ConnectBtnGroup, { props: user }, void 0)] }, void 0) }, void 0));
        }
    };
    var convertAccountsToString = function () {
        var values = [];
        data.forEach(function (value) {
            values.push(value.accountId.toString());
        });
        return values;
    };
    return (_jsxs("div", { children: [_jsxs("div", __assign({ className: "flex justify-between scrollable" }, { children: [_jsxs("div", __assign({ className: 'flex align-center' }, { children: [_jsx(Search, { onChange: onSearch, placeholder: 'Search' }, void 0),
                            selectedRows.length > 0 &&
                                _jsxs("div", __assign({ className: 'flex align-center next-to-search-container' }, { children: [_jsx(ApproveBtnGroup, { handleStatusChane: handleMultipleStatusChange }, void 0),
                                        _jsx(SelectedCount, { count: selectedRows.length }, void 0)] }), void 0)] }), void 0),
                    _jsx(Button, __assign({ type: 'submit', color: 'red', form: 'rectangular', onClick: handleCreateAccount }, { children: "Add Account" }), void 0)] }), void 0),
            _jsx("div", __assign({ className: 'table-wrap' }, { children: _jsx(DataTable, { keyField: 'accountId', columns: columns, data: tableData, selectableRows // add for checkbox selection
                    : true, onSelectedRowsChange: handleChange, clearSelectedRows: toggledClearRows, selectableRowDisabled: handleDisabled, selectableRowsHighlight: true, sortIcon: _jsx(ArrowIcon, {}, void 0), persistTableHead: true, noDataComponent: _jsx(NoDataComponent, {}, void 0), conditionalRowStyles: conditionalRowStyles, defaultSortAsc: false, pagination: true, paginationComponent: Pagination, paginationPerPage: 15, paginationRowsPerPageOptions: [15, 25, 50, 100, 200], 
                    // @ts-ignore
                    selectableRowsComponent: tablePropsForCheckbox.selectableRowsComponent, selectableRowsComponentProps: tablePropsForCheckbox.selectableRowsComponentProps }, void 0) }), void 0),
            _jsx(ModalWindow, __assign({ status: modalStatus, setStatus: function (status) { return setModalStatus(status); }, isNoScrollable: true, setModalController: setModalController }, { children: _jsx(AddAccount, { accountIds: convertAccountsToString(), modalController: modalController, participants: participants }, void 0) }), void 0)] }, void 0));
};
