var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './summary-panel.scss';
import Column from '../../../column';
import Button from '../../../buttons/button';
import { useState } from 'react';
import ModalWindow, { ModalWindowStatus } from '../../../forms/modal-window';
import NewDelivery from '../../../forms/windows/new-delivery';
import ChangeAddress from '../../../forms/windows/change-address';
import { ColHeader } from '../../../shared/col-header/col-header';
export default function SummaryPanel(props) {
    var _a = useState(false), showCopiedMessage = _a[0], setShowCopiedMessage = _a[1];
    var _b = useState(ModalWindowStatus.close), modalStatus = _b[0], setModalStatus = _b[1];
    var _c = useState(ModalWindowStatus.close), modalContactUsStatus = _c[0], setModalContactUsStatus = _c[1];
    var defaultShowBitnomialAddress = true;
    if (JSON.parse(sessionStorage.getItem('hideBitnomialAddress') || '""') === true) {
        defaultShowBitnomialAddress = false;
    }
    var _d = useState(defaultShowBitnomialAddress), showBitnomialAddress = _d[0], setShowBitnomialAddress = _d[1];
    var bitnomialAddress = props.bitnomialAddress;
    var permission = props.permissions;
    var textColor = 'black'; // TODO add there condition depends on permission
    var showButton = true; // TODO add there condition depends on permission
    // TODO this whole "if-else" is for testing, remove for production
    var sessionPermission = JSON.parse(sessionStorage.getItem('sessionPermission') || '""');
    if (sessionPermission === '') {
        permission = 'Not Approved';
        bitnomialAddress = '';
        textColor = 'grey';
    }
    else if (sessionPermission === 'Pending Approval') {
        permission = 'Pending Approval';
        bitnomialAddress = '';
        textColor = 'red';
        showButton = false;
    }
    else if (sessionPermission === 'Approved') {
        permission = 'Approved';
        textColor = 'green';
        showButton = false;
    }
    else if (sessionPermission === 'Request Denied') {
        permission = 'Request Denied';
        bitnomialAddress = '';
        textColor = 'red';
        showButton = false;
    }
    // TODO end of test lines
    function copy() {
        var textArea = document.createElement("textarea");
        textArea.value = bitnomialAddress ? bitnomialAddress : '';
        textArea.style.position = "fixed";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            document.execCommand('copy');
            document.body.removeChild(textArea);
            setShowCopiedMessage(true);
            setTimeout(function () { return setShowCopiedMessage(false); }, 500);
        }
        catch (err) {
        }
    }
    return _jsxs("div", __assign({ className: 'summary-panel' }, { children: [_jsxs("div", __assign({ className: 'summary-panel-main' }, { children: [_jsx("span", { children: "Account Summary" }, void 0),
                    showButton ?
                        _jsx(Button, __assign({ type: 'button', form: 'rectangular', color: 'red', disabled: !!bitnomialAddress, onClick: function () {
                                return setModalStatus(ModalWindowStatus.open);
                            } }, { children: "Request Delivery Permission" }), void 0) :
                        _jsxs("span", __assign({ className: 'contact-us' }, { children: ["Questions? ", _jsx("a", __assign({ target: '_blank', href: '#' }, { children: "Contact Us" }), void 0)] }), void 0),
                    _jsx("div", __assign({ className: 'flex-row' }, { children: _jsxs("div", __assign({ className: 'column-block' }, { children: [_jsx("div", { children: _jsx(Column, { upperCell: {
                                            title: 'Clearing Firm',
                                            value: props.firmName,
                                        }, withoutMargin: true }, void 0) }, void 0),
                                _jsx("div", { children: _jsx(Column, { upperCell: {
                                            title: 'Participant',
                                            value: props.participant
                                        }, withoutMargin: true }, void 0) }, void 0),
                                _jsx("div", __assign({ className: "colorful " + textColor }, { children: _jsxs("div", __assign({ className: 'cell' }, { children: [_jsx(ColHeader, { title: 'Delivery Permission', tooltip: '<div>\n                                    <b>Delivery Permission</b>\n                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>\n                                </div>', className: 'title' }, void 0),
                                            _jsx("div", __assign({ className: 'value' }, { children: permission }), void 0)] }), void 0) }), void 0)] }), void 0) }), void 0)] }), void 0),
            bitnomialAddress &&
                _jsxs("div", __assign({ className: 'bitnomial-address-section' }, { children: [_jsxs(Button, __assign({ type: 'button', form: 'rectangular', color: 'riverbed', className: 'reset-focus', onClick: function () {
                                setShowBitnomialAddress(!showBitnomialAddress);
                                sessionStorage.setItem('hideBitnomialAddress', JSON.stringify(showBitnomialAddress));
                            } }, { children: [showBitnomialAddress ? 'Hide' : 'Show', " Addresses", _jsx("svg", __assign({ "data-tag": "allowRowEvents", width: "10", height: "5", viewBox: "0 0 10 5", fill: "none", xmlns: "http://www.w3.org/2000/svg", className: "small " + (showBitnomialAddress ? 'expanded' : '') }, { children: _jsx("path", { "data-tag": "allowRowEvents", d: "M1 0.666748L5 4.00008L9 0.666748", stroke: "#689EDD", strokeLinecap: "round" }, void 0) }), void 0)] }), void 0),
                        showBitnomialAddress && _jsx("hr", {}, void 0),
                        _jsxs("div", __assign({ className: "summary-panel-address flex-row " + (showBitnomialAddress ? 'full-height' : 'no-height') }, { children: [_jsxs("div", __assign({ className: 'flex-column bitnomial-address' }, { children: [_jsx("div", __assign({ className: 'mini-title' }, { children: _jsx(ColHeader, { title: 'Make Address', tooltip: '<div>\n                                    <b>Make Address</b>\n                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>\n                                </div>' }, void 0) }), void 0),
                                        _jsx("a", __assign({ target: '_blank', onClick: copy }, { children: bitnomialAddress }), void 0)] }), void 0),
                                _jsxs("div", __assign({ className: 'flex-column bitnomial-address' }, { children: [_jsxs("div", __assign({ className: 'mini-title' }, { children: [_jsx(ColHeader, { title: 'Take Address', tooltip: '<div>\n                                    <b>Take Address</b>\n                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>\n                                </div>' }, void 0),
                                                _jsxs(Button, __assign({ type: 'button', form: 'rectangular', color: 'riverbed', className: 'reset-focus', onClick: function () { return setModalContactUsStatus(ModalWindowStatus.open); } }, { children: [_jsx("img", { src: '/images/icons/edit-icon.svg', alt: 'edit' }, void 0), "Change"] }), void 0)] }), void 0),
                                        _jsx("a", __assign({ target: '_blank', onClick: copy }, { children: bitnomialAddress }), void 0)] }), void 0),
                                showCopiedMessage &&
                                    _jsxs("div", __assign({ className: 'copied-message flex-row' }, { children: [_jsx("img", { src: '/images/icons/copy_green.svg', alt: 'copy_icon' }, void 0),
                                            _jsx("span", { children: "Copied to Clipboard" }, void 0)] }), void 0)] }), void 0)] }), void 0),
            _jsx(ModalWindow, __assign({ status: modalStatus, setStatus: function (status) { return setModalStatus(status); }, isNoScrollable: true }, { children: _jsx(NewDelivery, { showAreYouSure: !!bitnomialAddress, setStatus: function (status) { return setModalStatus(status); }, addresses: {
                        bitnomial: '098asdf0a0FG9H87GF6D5SA6789AS987SD',
                        delivery: '56D7F8ITJNB5G4T367W89EDFOGIJT54IE9DF8IG3'
                    } }, void 0) }), void 0),
            _jsx(ModalWindow, __assign({ status: modalContactUsStatus, setStatus: function (status) { return setModalContactUsStatus(status); }, isNoScrollable: true }, { children: _jsx(ChangeAddress, { setStatus: function (status) { return setModalContactUsStatus(status); } }, void 0) }), void 0)] }), void 0);
}
