var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Button from '../../../buttons/button';
import { ClipLoader } from 'react-spinners';
var AreYouSureDialog = function (props) {
    var isSubmitting = useState(false)[0];
    var content;
    if (isSubmitting) {
        content = _jsx("div", __assign({ className: 'flex-row' }, { children: _jsx(ClipLoader, { color: '#DD7168' }, void 0) }), void 0);
    }
    else {
        content = _jsxs("div", __assign({ className: 'flex-row' }, { children: [_jsx(Button, __assign({ onClick: function () {
                        props.yesClick();
                    }, type: 'button', form: 'rounded', color: 'red' }, { children: "Yes" }), void 0),
                _jsx("div", { className: 'distance-8' }, void 0),
                _jsx(Button, __assign({ onClick: function () {
                        props.noClick();
                    }, type: 'button', form: 'rounded', color: 'white' }, { children: "No" }), void 0)] }), void 0);
    }
    var text = '';
    if (props.amountOfSelectedItems && props.amountOfSelectedItems > 1) {
        if (props.type === 'derivatives') {
            text = "Are you sure you wish to disable (" + props.amountOfSelectedItems + ") connections? Doing so will cancel all orders associated with the connections. ";
        }
        else {
            text = "Are you sure you wish to cancel orders on (" + props.amountOfSelectedItems + ") connections? ";
        }
    }
    else {
        if (props.type === 'derivatives') {
            text = 'Are you sure you wish to disable this connection? Doing so will cancel all orders associated with the connection. ';
        }
        else {
            text = 'Are you sure you wish to cancel orders on this connection? ';
        }
    }
    return _jsxs("div", __assign({ className: 'save-changes' }, { children: [_jsx("span", { children: "Are you sure?" }, void 0),
            _jsx("br", {}, void 0),
            _jsxs("p", { children: [text, _jsx("span", __assign({ className: 'red' }, { children: "This action cannot be undone." }), void 0)] }, void 0), props.children && props.children, content] }), void 0);
};
export default AreYouSureDialog;
