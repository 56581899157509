var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Button from '../../buttons/button';
import ModalWindow, { ModalWindowStatus } from '../../forms/modal-window';
import ConfirmContactInfo from '../../forms/windows/confirm-contact-info';
import JoinEntity from '../../forms/windows/join-entity';
export var ConnectBtnGroup = function (_a) {
    var _b, _c, _d, _e;
    var props = _a.props, _f = _a.btnForm, btnForm = _f === void 0 ? 'rectangular' : _f;
    var values = {
        email: '',
        firstName: '',
        lastName: '',
        photo: '',
        phoneNumber: ''
    };
    if (props) {
        values = {
            email: (props === null || props === void 0 ? void 0 : props.email) || '',
            firstName: ((_b = props === null || props === void 0 ? void 0 : props.details) === null || _b === void 0 ? void 0 : _b.firstName) || '',
            lastName: ((_c = props === null || props === void 0 ? void 0 : props.details) === null || _c === void 0 ? void 0 : _c.lastName) || '',
            photo: ((_d = props === null || props === void 0 ? void 0 : props.details) === null || _d === void 0 ? void 0 : _d.photoId) || '',
            phoneNumber: ((_e = props === null || props === void 0 ? void 0 : props.details) === null || _e === void 0 ? void 0 : _e.phoneNumber) || ''
            // phoneNumber: '+2133734253'
        };
    }
    var _g = useState(ModalWindowStatus.close), modalStatus = _g[0], setModalStatus = _g[1];
    var _h = useState(_jsx("div", {}, void 0)), modalWindow = _h[0], setModalWindow = _h[1];
    var openConfirmContactInfo = function () {
        setModalStatus(ModalWindowStatus.open);
        setModalWindow(_jsx(ConfirmContactInfo, { values: values }, void 0));
    };
    var openJoinEntity = function () {
        setModalStatus(ModalWindowStatus.open);
        setModalWindow(_jsx(JoinEntity, {}, void 0));
    };
    return (_jsxs("div", __assign({ className: 'flex connect-btn-group' }, { children: [_jsxs("div", __assign({ className: 'flex-row' }, { children: [_jsx(Button, __assign({ onClick: openJoinEntity, type: 'button', form: btnForm, color: 'white' }, { children: "Join" }), void 0),
                    _jsx("div", { className: 'distance-8' }, void 0),
                    _jsx(Button, __assign({ onClick: openConfirmContactInfo, type: 'button', form: btnForm, color: 'white' }, { children: "Create" }), void 0)] }), void 0),
            _jsx(ModalWindow, __assign({ status: modalStatus, setStatus: function (status) { return setModalStatus(status); } }, { children: modalWindow }), void 0)] }), void 0));
};
