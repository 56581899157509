var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { useField } from 'formik';
import useOutsideClick from '../../../../utils/click-outside-hook';
import './select-field.scss';
import './select-field-scroll.scss';
export var DefaultValue = {
    key: 'select',
    value: 'Select',
};
var SelectField = function (_a) {
    var name = _a.name, values = _a.values, setValue = _a.setValue, label = _a.label, _b = _a.color, color = _b === void 0 ? 'regular' : _b, defaultValue = _a.defaultValue, closeOnClickOutside = _a.closeOnClickOutside, _c = _a.withShadow, withShadow = _c === void 0 ? false : _c;
    var _d = useState(false), isVisibleList = _d[0], setListVisibility = _d[1];
    var _e = useState(defaultValue), value = _e[0], setCurrentValue = _e[1];
    var ref = useRef(null);
    var _f = useField({ name: name }), field = _f[0], meta = _f[1], helpers = _f[2];
    var liOnClick = function (value, parentOption, event) {
        event.stopPropagation();
        if (parentOption) {
            value.parentValue = parentOption;
        }
        setCurrentValue(value);
        helpers.setValue(value.value);
        setListVisibility(false);
        if (setValue) {
            setValue(value);
        }
    };
    useOutsideClick(ref, function () {
        if (closeOnClickOutside && isVisibleList) {
            setListVisibility(false);
        }
    });
    var renderList = function (list, parentOption) {
        return (list.map(function (option, index) { return (_jsxs("li", __assign({ 
            // @ts-ignore
            onClick: liOnClick.bind(null, option, parentOption), "data-disabled": !!option.subList || option.disabled }, { children: [option.value, option.subList && (_jsx("ul", __assign({ className: 'sub-list' }, { children: renderList(option.subList, option) }), void 0))] }), index)); }));
    };
    return _jsxs("div", __assign({ className: 'select-field-container' }, { children: [label && _jsx("label", { children: label }, void 0),
            _jsxs("div", __assign({ className: "select-field " + color + " flex align-center " + (withShadow ? 'with-shadow' : ''), onClick: function () {
                    if (!isVisibleList) {
                        setListVisibility(true);
                    }
                } }, { children: [_jsxs("div", __assign({ className: 'flex-row w-full justify-between' }, { children: [_jsxs("div", { children: [_jsx("p", __assign({ className: value.parentValue ? 'main-value' : '' }, { children: value ? value.value : '' }), void 0),
                                    value && value.parentValue && _jsx("p", __assign({ className: 'sub-value' }, { children: value.parentValue.value }), void 0)] }, void 0),
                            _jsx("img", { src: '/images/forms/arrow_down.svg', alt: 'open' }, void 0)] }), void 0),
                    (isVisibleList && values.length > 1) &&
                        _jsx("div", __assign({ className: "select-field-list-container " + color }, { children: _jsx("div", __assign({ className: 'select-field-list', ref: ref }, { children: _jsx("ul", __assign({ className: 'list' }, { children: renderList(values) }), void 0) }), void 0) }), void 0)] }), void 0)] }), void 0);
};
export default SelectField;
