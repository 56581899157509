var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Cell from '../cell';
import './column.scss';
var Column = function (props) {
    return _jsxs("div", __assign({ className: "column " + (props.withoutMargin ? 'without-margin' : '') }, { children: [_jsx(Cell, __assign({}, props.upperCell), void 0),
            props.lowerCell ? _jsx(Cell, __assign({}, props.lowerCell), void 0) : null] }), void 0);
};
export default Column;
