export var addKyc = {
    "tag": "AddKyc",
    "contents": {
        "lastName": "User",
        "phoneNumber": "555-1234",
        "firstName": "Boss",
        "photoId": "photo_id.jpeg"
    }
};
/* 2 User defines their organization */
export var createEntity = {
    "tag": "CreateEntity",
    "contents": {
        "customerType": "Broker",
        "affiliateOrgChart": "affiliate_org_chart.pdf",
        "website": "https://organization.com",
        "orgDocuments": "org_documents.pdf",
        "beneficialOwners": "Boss User, VP User",
        "agreement": "agreement.pdf",
        "clearingMember": "BT",
        "disclosures": "None",
        "officerOrgChart": "officer_org_chart.pdf",
        "lei": "ORG",
        "finances": "corp_finances.pdf"
    }
};
/* 3 User invites another user */
export var inviteUser = {
    "tag": "InviteUser",
    "contents": {
        "invitationEmail": "user@example.com"
    }
};
