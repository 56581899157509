export var emulateRequest = function (context) {
    context.switchLoader(true);
    setTimeout(function () { return context.switchLoader(false); }, 1000);
};
export var camelCaseToDifferentWords = function (item) {
    return item.replace(/[A-Z]/g, function (letter) { return " " + letter; }).slice(1);
};
export var formatNumber = function (number, minLength, zerosAfterPoint) {
    if (minLength === void 0) { minLength = 9; }
    if (zerosAfterPoint === void 0) { zerosAfterPoint = 8; }
    var my_string = '' + number;
    if (number.length < minLength) {
        for (var i = number.length; i < minLength; i++) {
            my_string = (i === minLength - 1 ? '0.' : '0') + my_string;
        }
    }
    else {
        var stringArray = my_string.split('');
        var index = number.length - zerosAfterPoint - 1;
        stringArray[index] = my_string[index] + ".";
        my_string = stringArray.join('');
    }
    return my_string;
};
