var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import './cancel-button.scss';
export var CancelBtn = function (_a) {
    var onClick = _a.onClick, _b = _a.active, active = _b === void 0 ? false : _b, disabled = _a.disabled, className = _a.className;
    useEffect(function () {
        ReactTooltip.rebuild();
    }, []);
    return (_jsx("div", __assign({ onClick: onClick && !disabled ? onClick : function () { }, className: "cancel-icon flex justify-center align-center " + (disabled ? 'disabled' : '') + " " + (className ? className : ''), "data-for": !disabled && "bottom", "data-tip": 'Cancel Orders' }, { children: _jsxs("svg", __assign({ width: "30", height: "30", viewBox: "0 0 30 30", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("circle", { cx: "15", cy: "15", r: "10", stroke: "#D0D0D0", strokeWidth: "2", className: "icon " + (active ? 'active' : '') }, void 0),
                _jsx("path", { d: "M7 22L22.5 7.5", stroke: "#D0D0D0", strokeWidth: "2", className: "icon " + (active ? 'active' : '') }, void 0)] }), void 0) }), void 0));
};
