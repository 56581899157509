var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './info-row.scss';
export var InfoRow = function (_a) {
    var row = _a.row;
    // Mocked data
    // TODO should be replaced by data in row
    var data = {
        deliveryType: '0000000000000000000000000000000000000000000000000000000000000002',
        gateway: '10.248.2.4:9001',
        pricefeed: '10.248.9.4:9001',
        connectionsIds: ['2', '6'],
    };
    return (_jsxs("div", __assign({ className: 'info-row' }, { children: [_jsx("div", __assign({ className: 'expanded-row-wrapper w-full' }, { children: _jsxs("div", __assign({ className: 'expanded-row flex flex-column' }, { children: [_jsxs("div", __assign({ className: 'flex' }, { children: [_jsx("p", __assign({ className: 'font-bold' }, { children: "Delivery Type:" }), void 0),
                                _jsx("p", __assign({ className: 'value' }, { children: data.deliveryType }), void 0)] }), void 0),
                        _jsxs("div", __assign({ className: 'flex' }, { children: [_jsx("p", __assign({ className: 'font-bold' }, { children: "Gateway:" }), void 0),
                                _jsx("p", __assign({ className: 'value' }, { children: data.gateway }), void 0)] }), void 0),
                        _jsxs("div", __assign({ className: 'flex' }, { children: [_jsx("p", __assign({ className: 'font-bold' }, { children: "Dropcopy:" }), void 0),
                                _jsx("p", __assign({ className: 'value' }, { children: data.pricefeed }), void 0)] }), void 0),
                        _jsxs("div", __assign({ className: 'flex' }, { children: [_jsx("p", __assign({ className: 'font-bold' }, { children: "SMP Connections IDs:" }), void 0),
                                _jsx("p", __assign({ className: 'value' }, { children: data.connectionsIds.join(', ') }), void 0)] }), void 0)] }), void 0) }), void 0),
            _jsx("div", __assign({ className: 'expanded-row-bottom-shadow' }, { children: _jsx("div", {}, void 0) }), void 0)] }), void 0));
};
