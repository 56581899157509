var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import BusinessEntityHeader from './busines-entity-header';
import OverviewHeader from './overview-header';
import AccountDetailHeader from './account-detail-header';
import Heading from './heading';
import DiscardChangeDialog from '../forms/windows/discard-change-dialog';
import ModalWindow, { ModalWindowStatus, useModalController } from '../forms/modal-window';
import './header.scss';
var Header = function (props) {
    var _a = useState(ModalWindowStatus.close), modalStatus = _a[0], setModalStatus = _a[1];
    var _b = useState(useModalController()), modalController = _b[0], setModalController = _b[1];
    var isEntity = props.selectedTab === 'entity-registration';
    var isAccountDetail = props.selectedTab === 'account-detail';
    var changeTab = function (event, newSelectedTab) {
        var isEntityRegistrationChanged = JSON.parse(sessionStorage.getItem('isEntityRegistrationChanged') || '""');
        if (isEntity && !isEntityRegistrationChanged) {
            // discard changes check
            setModalStatus(ModalWindowStatus.open);
        }
        else {
            event.preventDefault();
            localStorage.setItem('selectedTab', newSelectedTab);
            location.reload();
        }
    };
    var getHeaderClass = function () {
        if (props.isMobileHeader !== true) {
            if (props.selectedTab === 'entity-registration') {
                return 'entity-header-bg';
            }
            if (props.selectedTab === 'account-detail') {
                return 'account-detail-header-bg';
            }
        }
    };
    return _jsx(_Fragment, { children: _jsxs("div", __assign({ className: "header " + getHeaderClass() }, { children: [_jsx("div", __assign({ className: 'header-overlay' }, { children: _jsxs("div", __assign({ className: 'container h-full flex flex-column justify-between' }, { children: [_jsx(Heading, {}, void 0),
                            props.isMobileHeader ?
                                _jsx("div", { children: _jsx("h1", { children: "Overview" }, void 0) }, void 0) :
                                _jsx("div", { children: isEntity ?
                                        _jsx(BusinessEntityHeader, { changeTab: changeTab }, void 0) :
                                        isAccountDetail ? _jsx(AccountDetailHeader, { changeTab: changeTab, accountId: props.accountId ? props.accountId : '' }, void 0) :
                                            _jsx(OverviewHeader, { selectedTab: props.selectedTab, changeTab: changeTab, accountApprovalsCount: props.accountApprovalsCount || 0, connectionApprovalsCount: props.connectionApprovalsCount || 0 }, void 0) }, void 0)] }), void 0) }), void 0),
                _jsx(ModalWindow, __assign({ status: modalStatus, setStatus: setModalStatus, setModalController: setModalController, isNoScrollable: true }, { children: _jsx(DiscardChangeDialog, { discardAndExitClick: function () {
                            localStorage.setItem('selectedTab', 'settings');
                            location.reload();
                        }, noClick: modalController.startClosing }, void 0) }), void 0)] }), void 0) }, void 0);
};
export default Header;
