import { useFormik } from 'formik';
import { useState } from 'react';
var useFormController = function (props) {
    var _a = useState(false), isSubmitting = _a[0], setSubmitting = _a[1];
    var checkChanges = function () {
        if (props === null || props === void 0 ? void 0 : props.checkChanges) {
            return props.checkChanges();
        }
        return false;
    };
    var startSubmitting = function () {
        setSubmitting(true);
    };
    var finishSubmitting = function () {
        setSubmitting(false);
    };
    var success = function () {
        if (props === null || props === void 0 ? void 0 : props.success) {
            props.success();
        }
    };
    var fail = function () {
        if (props === null || props === void 0 ? void 0 : props.fail) {
            props.fail();
        }
    };
    var defaultFormik = useFormik({
        initialValues: {},
        onSubmit: function () { },
    });
    var formController = {
        isSubmitting: isSubmitting,
        startSubmitting: startSubmitting,
        finishSubmitting: finishSubmitting,
        checkChanges: checkChanges,
        success: success,
        fail: fail,
        formik: (props === null || props === void 0 ? void 0 : props.formik) ? props.formik : defaultFormik,
    };
    return formController;
};
export default useFormController;
