var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import FormHeader, { Images } from '../../form-header';
import { FormikProvider, useFormik } from 'formik';
import EmailField from '../../fields/email-field';
import ContainerFields from '../../container-fields';
import TextField from '../../fields/text-field';
import PhoneField from '../../fields/phone-field';
import BtnPassword from '../../../buttons/btn-password';
import PhotoField from '../../fields/photo-field';
import Button from '../../../buttons/button';
import { ClipLoader } from 'react-spinners';
import useFormController from './../../formController';
import { contactsValidationSchema } from '../../contacts-validation';
import PasswordCheck from '../password-check';
import ChangesSaved from '../changes-saved';
import { pasteFormatter } from '../../fields/phone-field/formatters';
import './edit-contact-information.scss';
export var send = function (formController, setSubmitting) {
    if (setSubmitting) {
        setSubmitting(true);
    }
    setTimeout(function () {
        if (setSubmitting) {
            setSubmitting(false);
        }
        formController.success();
    }, 1000);
};
var EditContactInformation = function (props) {
    var _a = useState(props.message), message = _a[0], setMessage = _a[1];
    var formik = useFormik({
        initialValues: props.values,
        validationSchema: contactsValidationSchema,
        onSubmit: function (values) {
            send(formController, formik.setSubmitting);
        },
    });
    var formController = useFormController({
        success: function () {
            props.setSaveChangeDialog(true);
            props.changeWindow(_jsx(ChangesSaved, { closeWindow: props.closeWindow }, void 0));
            setMessage('');
        },
        checkChanges: function () {
            {
                return props.values.email == formik.values.email &&
                    props.values.firstName == formik.values.firstName &&
                    props.values.lastName == formik.values.lastName &&
                    props.values.photo == formik.values.photo &&
                    pasteFormatter(props.values.phoneNumber) == formik.values.phoneNumber;
            }
        },
        formik: formik,
    });
    useEffect(function () {
        props.setFormController(formController);
    });
    return _jsx(_Fragment, { children: _jsxs("div", __assign({ className: 'edit-contact-information adaptive-modal-window' }, { children: [_jsx(FormHeader, { title: 'Contact information', image: Images.profile }, void 0),
                _jsx("div", __assign({ className: 'form-body' }, { children: _jsx(FormikProvider, __assign({ value: formik }, { children: _jsxs("form", __assign({ noValidate: true, onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: [_jsx(EmailField, { name: 'email', onChange: formik.handleChange }, void 0),
                                _jsx(ContainerFields, { firstField: _jsx(TextField, { name: 'firstName', label: 'First name', onChange: formik.handleChange }, void 0), secondField: _jsx(TextField, { name: 'lastName', label: 'Last name', onChange: formik.handleChange }, void 0) }, void 0),
                                _jsx(ContainerFields, { firstField: _jsx(PhoneField, { name: 'phoneNumber' }, void 0), secondField: _jsx(BtnPassword, { lastPasswordChange: props.values.lastPasswordChange, onClick: function () { props.changeWindow(_jsx(PasswordCheck, { changeWindow: props.changeWindow }, void 0)); } }, void 0) }, void 0),
                                _jsx(PhotoField, { name: 'photo', text: 'Photo of US or state government-issued ID', onChange: formik.handleChange }, void 0),
                                _jsxs("div", __assign({ className: 'flex-row flex-row-left' }, { children: [_jsx(Button, __assign({ type: 'submit', form: 'rounded', color: 'red', disabled: formController.checkChanges() }, { children: "Save and Exit" }), void 0),
                                        _jsx("div", { className: 'distance-16' }, void 0),
                                        formik.isSubmitting ? _jsx(ClipLoader, { color: '#DD7168' }, void 0) : _jsx("span", __assign({ className: 'edit-contact-information-msg' }, { children: message }), void 0)] }), void 0)] }), void 0) }), void 0) }), void 0)] }), void 0) }, void 0);
};
export default EditContactInformation;
