var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useField } from 'formik';
import '../form-field.scss';
var TextField = function (props) {
    var _a = useField({
        name: props.name,
        id: props.name,
        onChange: props.onChange,
    }), field = _a[0], meta = _a[1];
    var fieldClass = meta.error && meta.touched ? 'errorField' : null;
    var background_color = props.background_color == 'white' ? 'bg-white' : '';
    var placeholder = props.placeholder ? props.placeholder : '';
    return _jsx(_Fragment, { children: _jsxs("div", __assign({ className: 'form-field' }, { children: [_jsx("label", __assign({ htmlFor: props.name }, { children: props.label }), void 0),
                _jsx("input", __assign({ type: 'text', className: fieldClass + ' ' + background_color }, field, { placeholder: placeholder }), void 0),
                _jsx("div", __assign({ className: 'error' }, { children: meta.error && meta.touched ? meta.error : null }), void 0)] }), void 0) }, void 0);
};
export default TextField;
