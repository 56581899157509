var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import './changes-saved.scss';
var ChangesSaved = function (props) {
    setTimeout(function () {
        props.closeWindow();
    }, 1000);
    return _jsx(_Fragment, { children: _jsx("span", __assign({ className: 'changes-saved' }, { children: "Changes Saved" }), void 0) }, void 0);
};
export default ChangesSaved;
