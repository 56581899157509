var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { AnimationDirection, ModalDialog, ModalWindowStatus } from '../modal-window';
import useFormController from '../formController';
import EditContactInformation from '../windows/edit-contact-information';
import '../modal-window/modal-window.scss';
import './save-changes.scss';
import './edit-contact-information.scss';
var ContactsModalWindow = function (props) {
    var _a = useState(AnimationDirection.top), animation = _a[0], setAnimation = _a[1];
    var _b = useState(false), isSaveChangeDialog = _b[0], setSaveChangeDialog = _b[1];
    var _c = useState(useFormController()), formController = _c[0], setFormController = _c[1];
    var _d = useState(baseContent('')), content = _d[0], setContent = _d[1];
    var startClosing = function () {
        if (animation == AnimationDirection.top && props.status == ModalWindowStatus.open) {
            if (formController.checkChanges()) {
                setAnimation(AnimationDirection.bottom);
            }
            else {
                setAnimation(AnimationDirection.bottom);
            }
        }
    };
    var close = function () {
        if (animation == AnimationDirection.bottom) {
            setAnimation(AnimationDirection.top);
            props.setStatus(ModalWindowStatus.close);
            setSaveChangeDialog(false);
            changeWindow(null);
        }
    };
    function baseContent(message) {
        var newBaseContent = (_jsx(_Fragment, { children: _jsx(EditContactInformation, { values: props.values, setFormController: setFormController, changeWindow: changeWindow, closeWindow: function () {
                    setAnimation(AnimationDirection.bottom);
                }, message: message, setSaveChangeDialog: setSaveChangeDialog }, void 0) }, void 0));
        return newBaseContent;
    }
    function changeWindow(newContent, message) {
        if (newContent) {
            setContent(newContent);
        }
        else {
            if (message) {
                setContent(baseContent(message));
            }
            else {
                setContent(baseContent(''));
            }
        }
    }
    if (props.status == ModalWindowStatus.open) {
        return _jsx("div", __assign({ className: 'modal-window' }, { children: _jsxs("div", __assign({ className: (isSaveChangeDialog ? ModalDialog.small : ModalDialog.medium) + ' ' + animation, onAnimationEnd: close, onClick: function (e) { return e.stopPropagation(); } }, { children: [!isSaveChangeDialog ? _jsx("img", { className: 'close', src: '/images/icons/close.svg', alt: 'close', onClick: startClosing }, void 0) : null,
                    _jsx("div", __assign({ className: 'modal-body' }, { children: _jsx("div", __assign({ className: 'modal-content' }, { children: content }), void 0) }), void 0)] }), void 0) }), void 0);
    }
    else {
        return null;
    }
};
export default ContactsModalWindow;
